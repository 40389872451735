// This module behaves as a singleton for twirp generated API Services
import { LogLine } from '@h2oai/ui-kit';
import { User as OidcUser } from 'oidc-client-ts';

import {
  AdminAliasServiceImpl,
  AdminAppServiceImpl,
  AdminFederationServiceImpl,
  AdminPreconditionServiceImpl,
  AdminSecretsServiceImpl,
  AdminTagServiceImpl,
  AppServiceImpl,
  AuthServiceImpl,
  EnvServiceImpl,
  AppService as IAppService,
  ImportAppRequest,
  ImportAppResponse,
  SecretsServiceImpl,
  TagServiceImpl,
} from '../ai.h2o.cloud.appstore';
import { AuthzServiceImpl } from '../authz/api';
import { OrchestratorServiceImpl } from '../orchestrator/api';
import { SecureStoreServiceImpl } from '../secure-store/api';
import { TelemetryServiceImpl } from '../telemetry/api';
import { createTwirpRequest, throwTwirpError } from '../twirp';
import { CANCEL_WEBSOCKET_DOWNLOAD } from '../utils/utils';

export interface InterruptibleCall<T> {
  cancel: () => void;
  promise: Promise<T>;
}

export enum ServiceResponse {
  abort = 'abort',
  inProgress = 'inProgress',
}

export interface UploadAppResponse {
  filename: string;
}

// For protoc generation using protoc-gen-grpc-gateway-es:
type RequestConfig = {
  basePath?: string;
  bearerToken?: string | (() => string);
};

interface RPC<RequestMessage, ResponseMessage> {
  readonly method: string;
  readonly path: string;
  readonly bodyKey?: string;
  createRequest: (config: RequestConfig, params: RequestMessage) => Request;
  responseTypeId: (response: any) => ResponseMessage;
}

export const fetchWrapRPC = <RequestMessage, ResponseMessage>(
  rpc: RPC<RequestMessage, ResponseMessage>,
  requestConfig: RequestConfig
) => {
  return async (variables: RequestMessage, { signal }: { signal?: AbortSignal } = {}) => {
    const request = rpc.createRequest(requestConfig, variables);
    const response = await fetch(request, { signal });
    if (response.ok) {
      return (await response.json()) as Promise<ResponseMessage>;
    }
    const error = await response.json();
    return Promise.reject(error);
  };
};

class UploadServiceImpl {
  upload(
    file: File,
    accessToken: string | undefined,
    onProgress?: (e: ProgressEvent<EventTarget>) => void
  ): InterruptibleCall<UploadAppResponse> {
    const formData = new FormData(),
      xhr = new XMLHttpRequest();
    let state = ServiceResponse.inProgress;

    formData.append('file', file);
    const makeRequest = new Promise<UploadAppResponse>((resolve, reject) => {
      xhr.open('POST', '/v1/upload');
      if (accessToken) {
        xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
      }
      xhr.upload.onprogress = onProgress || null;
      xhr.send(formData);
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 0 && state === ServiceResponse.abort) {
          reject(state);
        } else if (xhr.status < 200 || xhr.status >= 300) {
          reject(xhr);
        }
      };
      xhr.onload = () => resolve(JSON.parse(xhr.response));
    });

    return {
      cancel: () => {
        state = ServiceResponse.abort;
        xhr.abort();
      },
      promise: makeRequest,
    };
  }
}

class DownloadServiceImpl {
  private async download(urlPrefix: string, id: string, fileName: string, accessToken: string | undefined) {
    return fetch(`${urlPrefix}${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (resp) => {
      if (resp.ok) {
        const blob = await resp.blob();
        const newBlob = new Blob([blob], { type: 'application/octet-stream' });
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent('click'));
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 60);
      }
      return resp;
    });
  }

  async downloadAdminApp(id: string, fileName: string, accessToken: string | undefined) {
    return this.download('/v1/admindownload/', id, fileName, accessToken);
  }

  async downloadApp(id: string, fileName: string, accessToken: string | undefined) {
    return this.download('/v1/download/', id, fileName, accessToken);
  }
}

interface IDecoratedAppService extends IAppService {
  interruptibleImport: (data: ImportAppRequest, headers?: object) => InterruptibleCall<ImportAppResponse>;
}

class DecoratedAppService extends AppServiceImpl implements IDecoratedAppService {
  public interruptibleImport(params: ImportAppRequest, headers: object = {}): InterruptibleCall<ImportAppResponse> {
    let state = ServiceResponse.inProgress;
    const controller = new AbortController(),
      { signal } = controller,
      promise = this.fetch(this.url('ImportApp'), {
        ...createTwirpRequest(params, headers),
        signal,
      })
        .then((res) => (!res.ok ? throwTwirpError(res) : res.json()))
        .catch((error) => {
          throw state === ServiceResponse.abort ? state : error;
        });
    return {
      cancel: () => {
        state = ServiceResponse.abort;
        controller.abort();
      },
      promise,
    };
  }
}

export const OIDC_KEY_KEY = 'oidcKey';
export const VALID_HOSTS = 'validHosts';

export const getAccessToken = (): string => {
  const oidcKey = window.localStorage.getItem(OIDC_KEY_KEY);
  if (oidcKey) {
    const user = window.sessionStorage.getItem(oidcKey);
    if (user) {
      const userObj = JSON.parse(user) as unknown as OidcUser;
      return userObj?.access_token;
    }
  }
  return '';
};

export interface WebSocketParams {
  follow?: boolean;
  tailLines?: number;
  previous?: boolean; // currently unsupported
}

export interface IGetWebSocketParamsWithQuery extends IGetWebSocketParams {
  queryParameters?: WebSocketParams;
}

export interface IGetWebSocketParams {
  url: URL;
}

class WebSocketServiceImpl {
  public getSocket(params: IGetWebSocketParamsWithQuery): WebSocket {
    const { url, queryParameters } = params;
    const loc = window.location;
    const hostnameString = url.hostname;
    // for mocking during development
    const portString = process.env.REACT_APP_USE_MOCK_SERVER ? ':4000' : url.port ? `:${url.port}` : '';
    let wsURL: string;
    // just in case
    if (loc.protocol === 'https:') {
      wsURL = 'wss:';
    } else {
      wsURL = 'ws:';
    }
    wsURL += `//${hostnameString}${portString}${url.pathname}`;

    if (queryParameters) {
      wsURL += '?';
      wsURL += Object.keys(queryParameters)
        .map((key) => `${key}=${queryParameters[key].toString()}`)
        .join('&');
    }
    const accessToken = getAccessToken();
    return accessToken ? new WebSocket(wsURL, ['access_token', accessToken]) : new WebSocket(wsURL);
  }
}

const saveStringAsZip = (fileName: string, content: string): Promise<void> => {
  return Promise.all([import('jszip'), import('file-saver')]).then(([{ default: JSZip }, { default: FileSaver }]) => {
    const zip = new JSZip();
    zip.file(`${fileName}.txt`, content);
    return zip.generateAsync({ type: 'blob' }).then((blob) => {
      FileSaver.saveAs(blob, `${fileName}.zip`);
    });
  });
};

export const instanceLogDownload = (
  websocketParams: IGetWebSocketParams,
  fileName: string,
  previous: boolean,
  successCallback?: () => any,
  failureCallBack?: (err: any) => any,
  tailLines?: number
): WebSocket | undefined => {
  if (!websocketParams || !fileName) {
    return;
  }
  let queryParameters: Record<string, any> = { follow: false, previous };
  if (tailLines) {
    queryParameters = { ...queryParameters, tailLines };
  }
  const ws = WebSocketService.getSocket({
    ...websocketParams,
    queryParameters,
  });
  const _log: LogLine[] = [];
  let isSocketError = false;
  ws.addEventListener('message', (logLine) => {
    _log.push(logLine);
  });
  ws.addEventListener('close', (reason) => {
    if (reason.reason === CANCEL_WEBSOCKET_DOWNLOAD || isSocketError) {
      return;
    }
    saveStringAsZip(fileName, _log.map((line) => line.data).join('')).then(successCallback, failureCallBack);
  });
  ws.addEventListener('error', (err) => {
    if (failureCallBack) {
      isSocketError = true;
      failureCallBack(err);
    }
    ws.close();
  });
  return ws;
};

const getHost = (req: Request): string => {
  let host = '';
  try {
    const url = new URL(req.url);
    if (url) {
      host = url.host;
    }
  } catch {
    host = window.location.host;
  }
  return host;
};

const getValidDomains = (): string[] => {
  let hosts = [window.location.host];
  const otherHosts = localStorage.getItem(VALID_HOSTS)?.split(',');
  if (otherHosts) {
    hosts = hosts.concat(otherHosts);
  }
  return hosts;
};

const { fetch: originalFetch } = window;
window.fetch = async (...args) => {
  const request = new Request(...args);
  if (!request.headers.get(`Authorization`) && getValidDomains().includes(getHost(request))) {
    const accessToken = getAccessToken();
    if (accessToken) {
      request.headers.set('Authorization', `Bearer ${accessToken}`);
    }
  }
  return await originalFetch(request);
};

const boundFetch = window.fetch.bind(window);

const envURL = process.env.REACT_APP_API_PATH || '';

export const AdminAliasService = new AdminAliasServiceImpl(envURL, boundFetch),
  AdminAppService = new AdminAppServiceImpl(envURL, boundFetch),
  AdminFederatedAppService = new AdminFederationServiceImpl(envURL, boundFetch),
  AdminPreconditionService = new AdminPreconditionServiceImpl(envURL, boundFetch),
  AdminSecretsService = new AdminSecretsServiceImpl(envURL, boundFetch),
  AdminTagService = new AdminTagServiceImpl(envURL, boundFetch),
  AppService = new DecoratedAppService(envURL, boundFetch),
  AuthService = new AuthServiceImpl(envURL, boundFetch),
  DownloadService = new DownloadServiceImpl(),
  EnvService = new EnvServiceImpl(envURL, boundFetch),
  SecretsService = new SecretsServiceImpl(envURL, boundFetch),
  TagService = new TagServiceImpl(envURL, boundFetch),
  TelemetryService = new TelemetryServiceImpl(envURL),
  OrchestratorService = new OrchestratorServiceImpl(envURL),
  AuthzService = new AuthzServiceImpl(envURL),
  SecureStore = new SecureStoreServiceImpl(envURL),
  UploadService = new UploadServiceImpl(),
  WebSocketService = new WebSocketServiceImpl();
