// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/securestore/v1/secret.proto (package ai.h2o.securestore.v1, syntax proto3)
/* eslint-disable */

/**
 * A Secret is a logical secret whose versions can be accessed.
 * A Secret is made up of zero or more SecretVersions that represent the secret data.
 *
 * @generated from message ai.h2o.securestore.v1.Secret
 */
export type Secret = {
/**
 * Output only. The resource name of the Secret.
 * Format: `workspaces/*\/secrets/*`
 *
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * Output only. The current state of the Secret.
 *
 * @generated from field: ai.h2o.securestore.v1.Secret.State state = 2;
 */
state?: Secret_State;
/**
 * Output only. Name of an entity that created the Secret.
 *
 * @generated from field: string creator = 3;
 */
creator?: string;
/**
 * Optional. Output only. Name of an entity that deleted the Secret.
 *
 * @generated from field: string deleter = 4;
 */
deleter?: string;
/**
 * Output only. Time when the Secret was created.
 *
 * @generated from field: google.protobuf.Timestamp create_time = 5;
 */
createTime?: string;
/**
 * Output only. Optional. Time when the Secret was deleted.
 *
 * @generated from field: optional google.protobuf.Timestamp delete_time = 6;
 */
deleteTime?: string;
/**
 * Output only. Optional. Time when the Secret will be purged and permanently deleted, including the child SecretVersions.
 *
 * @generated from field: optional google.protobuf.Timestamp purge_time = 7;
 */
purgeTime?: string;
}
;
/**
 * The state of a Secret, indicating if child SecretVersions can be accessed
 * or if new SecretVersions can be created.
 *
 * @generated from enum ai.h2o.securestore.v1.Secret.State
 */
export enum Secret_State {
/**
 * Not specified. This value is unused and invalid.
 *
 * @generated from enum value: STATE_UNSPECIFIED = 0;
 */
UNSPECIFIED = "STATE_UNSPECIFIED",

/**
 * The Secret is active and the child SecretVersions can be accessed and new SecretVersions can be created.
 *
 * @generated from enum value: STATE_ACTIVE = 1;
 */
ACTIVE = "STATE_ACTIVE",

/**
 * The Secret is deleted and the child SecretVersions cannot be accessed and new SecretVersions cannot be created.
 * The Secret can be restored using the undelete method until purge_time.
 *
 * @generated from enum value: STATE_DELETED = 2;
 */
DELETED = "STATE_DELETED",
}
