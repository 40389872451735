import { PanelType, Stack } from '@fluentui/react';
import { Panel, TextField } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import { stylesPanel } from '../../../components/AIEnginesPage/components/AIEMPanel/AIEMPanel';
import { EditablePanelFooter } from '../../../components/EditablePanelFooter/EditablePanelFooter';

type SecretEditPanelProps = {
  onDismiss: () => void;
  onSave: (secretValue: string) => void;
  isOpen: boolean;
};

export const SecretEditPanel = (props: SecretEditPanelProps) => {
  const [secretVersionValue, setSecretVersionValue] = useState<string>('');

  useEffect(() => {
    return () => {
      setSecretVersionValue('');
    };
  }, [props.isOpen]);

  return (
    <Panel
      isLightDismiss
      customWidth="500px"
      headerText="New Secret"
      isFooterAtBottom
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      type={PanelType.custom}
      styles={stylesPanel}
    >
      <Stack horizontal>
        <TextField
          styles={{ root: { width: '100%' } }}
          label="Secret value"
          required
          value={secretVersionValue}
          onChange={(_ev, newValue) => setSecretVersionValue(newValue || '')}
        />
      </Stack>
      <EditablePanelFooter
        onCancel={props.onDismiss!}
        onSave={() => props.onSave(secretVersionValue)}
        closeButtonText="Cancel"
        saveButtonText="Save"
        saveButtonDisabled={!secretVersionValue.length}
      />
    </Panel>
  );
};
