import { ListOAuthClientsResponse } from '../../gen/ai/h2o/securestore/v1/oauth_client_service_pb';
import { Secret_State } from '../../gen/ai/h2o/securestore/v1/secret_pb';
import { ListSecretsResponse } from '../../gen/ai/h2o/securestore/v1/secret_service_pb';
import { ListSecretVersionsResponse } from '../../gen/ai/h2o/securestore/v1/secret_version_service_pb';
import { BytesString } from '../../gen/runtime';

export const oauthClientsResponseMock: ListOAuthClientsResponse = {
  oauthClients: [...Array(20)].map((_item: any, index) => ({
    name: `oauth-client-name-${index + 1}`,
    displayName: `OAuth Client Display Name ${index + 1}`,
    issuer: '',
    clientId: '',
    clientSecret: '',
    clientSecretSet: false,
    authorizationEndpoint: '',
    tokenEndpoint: '',
    extraScopes: [],
    refreshDisabled: false,
    loginPrincipalClaim: '',
    callbackUri: '',
    creator: '',
    updater: '',
    createTime: new Date(`2022-12-${index + 1}T00:14:02Z`).toUTCString(),
    updateTime: new Date(`2022-12-${index + 1}T01:55:37Z`).toUTCString(),
  })),
};

export const secretServiceResponseMock: ListSecretsResponse = {
  secrets: [...Array(20)].map((_item: any, index) => ({
    name: `secret-name-${index + 1}`,
    state: Secret_State.ACTIVE,
    creator: `secret-creator-${index + 1}`,
    createTime: new Date(`2022-12-${index + 1}T00:14:02Z`).toUTCString(),
  })),
};

export const secretVersionServiceResponseMock: ListSecretVersionsResponse = {
  secretVersions: [
    {
      name: `secret-version-name`,
      value: '' as BytesString,
      creator: `secret-creator`,
      createTime: new Date(`2022-12-1T00:14:02Z`).toUTCString(),
    },
  ],
};
