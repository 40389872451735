// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/notebook/v1/kernel_image_service.proto (package ai.h2o.notebook.v1, syntax proto3)
/* eslint-disable */

import type { KernelImage } from "./kernel_image_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for `CreateKernelImage`.
 *
 * @generated from message ai.h2o.notebook.v1.CreateKernelImageRequest
 */
export type CreateKernelImageRequest = {
/**
 * Required. The resource name of the workspace to associate with the KernelImage.
 * Format is `workspaces/*`.
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Required. The KernelImage resource to create.
 *
 * @generated from field: ai.h2o.notebook.v1.KernelImage kernel_image = 2;
 */
kernelImage: KernelImage;
/**
 * Optional. Specify the KernelImage ID, which will become a part of the KernelImage
 * resource name. If not specified, the server will generate one.
 *
 * It must:
 * - contain 1-63 characters
 * - contain only lowercase alphanumeric characters or hyphen ('-')
 * - start with an alphabetic character
 * - end with an alphanumeric character
 *
 * This matches a regular expression: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
 *
 * @generated from field: string kernel_image_id = 3;
 */
kernelImageId?: string;
}
;
/**
 * Response message for `CreateKernelImage`.
 *
 * @generated from message ai.h2o.notebook.v1.CreateKernelImageResponse
 */
export type CreateKernelImageResponse = {
/**
 * The created KernelImage.
 *
 * @generated from field: ai.h2o.notebook.v1.KernelImage kernel_image = 1;
 */
kernelImage?: KernelImage;
}
;
/**
 * Request message for `GetKernelImage`.
 *
 * @generated from message ai.h2o.notebook.v1.GetKernelImageRequest
 */
export type GetKernelImageRequest = {
/**
 * Required. The name of the KernelImage to retrieve.
 * Format is `workspaces/*\/kernelImages/*`
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for `GetKernelImage`.
 *
 * @generated from message ai.h2o.notebook.v1.GetKernelImageResponse
 */
export type GetKernelImageResponse = {
/**
 * The requested KernelImage.
 *
 * @generated from field: ai.h2o.notebook.v1.KernelImage kernel_image = 1;
 */
kernelImage?: KernelImage;
}
;
/**
 * Request message for listing KernelImages.
 *
 * @generated from message ai.h2o.notebook.v1.ListKernelImagesRequest
 */
export type ListKernelImagesRequest = {
/**
 * Required. The resource name of the workspace to associate with the KernelImage.
 * Format is `workspaces/*`.
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Optional. Maximum number of KernelImages to return in a response.
 * If unspecified (or set to 0), at most 50 KernelImages will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Optional. Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListKernelImagesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for ListKernelImages.
 *
 * @generated from message ai.h2o.notebook.v1.ListKernelImagesResponse
 */
export type ListKernelImagesResponse = {
/**
 * Collection of KernelImages.
 *
 * @generated from field: repeated ai.h2o.notebook.v1.KernelImage kernel_images = 1;
 */
kernelImages?: KernelImage[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for updating an existing KernelImage.
 *
 * @generated from message ai.h2o.notebook.v1.UpdateKernelImageRequest
 */
export type UpdateKernelImageRequest = {
/**
 * Required. KernelImage resource.
 *
 * @generated from field: ai.h2o.notebook.v1.KernelImage kernel_image = 1;
 */
kernelImage: KernelImage;
/**
 * Required. The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are: {"display_name", "type", "image", "disabled", "image_pull_policy", "image_pull_secrets"}.
 * Paths are case sensitive (must match exactly).
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 10;
 */
updateMask: string;
}
;
/**
 * Response message for UpdateKernelImage.
 *
 * @generated from message ai.h2o.notebook.v1.UpdateKernelImageResponse
 */
export type UpdateKernelImageResponse = {
/**
 * KernelImage resource.
 *
 * @generated from field: ai.h2o.notebook.v1.KernelImage kernel_image = 1;
 */
kernelImage?: KernelImage;
}
;
/**
 * Request message for deleting an existing KernelImage.
 *
 * @generated from message ai.h2o.notebook.v1.DeleteKernelImageRequest
 */
export type DeleteKernelImageRequest = {
/**
 * Required. KernelImage resource name.
 * Format is `workspaces/*\/kernelImages/*`.
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DeleteKernelImage.
 *
 * @generated from message ai.h2o.notebook.v1.DeleteKernelImageResponse
 */
export type DeleteKernelImageResponse = {
}
;
/**
 * Creates a new KernelImage.
 *
 * @generated from rpc ai.h2o.notebook.v1.KernelImageService.CreateKernelImage
 */
export const KernelImageService_CreateKernelImage = new RPC<CreateKernelImageRequest,CreateKernelImageResponse>("POST", "/v1/{parent=workspaces/*}/kernelImages", "kernelImage");
/**
 * Returns a specific KernelImage.
 *
 * @generated from rpc ai.h2o.notebook.v1.KernelImageService.GetKernelImage
 */
export const KernelImageService_GetKernelImage = new RPC<GetKernelImageRequest,GetKernelImageResponse>("GET", "/v1/{name=workspaces/*/kernelImages/*}");
/**
 * Returns a collection of KernelImages.
 *
 * @generated from rpc ai.h2o.notebook.v1.KernelImageService.ListKernelImages
 */
export const KernelImageService_ListKernelImages = new RPC<ListKernelImagesRequest,ListKernelImagesResponse>("GET", "/v1/{parent=workspaces/*}/kernelImages");
/**
 * Updates a KernelImage.
 *
 * @generated from rpc ai.h2o.notebook.v1.KernelImageService.UpdateKernelImage
 */
export const KernelImageService_UpdateKernelImage = new RPC<UpdateKernelImageRequest,UpdateKernelImageResponse>("PATCH", "/v1/{kernelImage.name=workspaces/*/kernelImages/*}", "kernelImage");
/**
 * Deletes a KernelImage.
 *
 * @generated from rpc ai.h2o.notebook.v1.KernelImageService.DeleteKernelImage
 */
export const KernelImageService_DeleteKernelImage = new RPC<DeleteKernelImageRequest,DeleteKernelImageResponse>("DELETE", "/v1/{name=workspaces/*/kernelImages/*}");
