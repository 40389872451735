// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/orchestrator/v1/workflow_trigger_service.proto (package ai.h2o.orchestrator.v1, syntax proto3)
/* eslint-disable */

import type { WorkflowTrigger } from "./workflow_trigger_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for CreateWorkflowTrigger
 *
 * @generated from message ai.h2o.orchestrator.v1.CreateWorkflowTriggerRequest
 */
export type CreateWorkflowTriggerRequest = {
/**
 * Required. The resource name of the workflow to create trigger in.
 * Format: `workspaces/{workspace}/workflows/{workflow}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * The ID to use for the trigger, which will become the final component of
 * the trigger's resource name.
 *
 * @generated from field: string workflow_trigger_id = 2;
 */
workflowTriggerId?: string;
/**
 * Required. The WorkflowTrigger resource to create.
 *
 * @generated from field: ai.h2o.orchestrator.v1.WorkflowTrigger workflow_trigger = 3;
 */
workflowTrigger: WorkflowTrigger;
}
;
/**
 * Response message for CreateWorkflowTrigger
 *
 * @generated from message ai.h2o.orchestrator.v1.CreateWorkflowTriggerResponse
 */
export type CreateWorkflowTriggerResponse = {
/**
 * The created WorkflowTrigger.
 *
 * @generated from field: ai.h2o.orchestrator.v1.WorkflowTrigger workflow_trigger = 1;
 */
workflowTrigger?: WorkflowTrigger;
}
;
/**
 * Request message for UpdateWorkflowTrigger
 *
 * @generated from message ai.h2o.orchestrator.v1.UpdateWorkflowTriggerRequest
 */
export type UpdateWorkflowTriggerRequest = {
/**
 * Required. WorkflowTrigger resource to update
 *
 * @generated from field: ai.h2o.orchestrator.v1.WorkflowTrigger workflow_trigger = 1;
 */
workflowTrigger: WorkflowTrigger;
/**
 * Required. The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are: {display_name, spec, parameters}.
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask: string;
}
;
/**
 * Response message for UpdateWorkflowTrigger
 *
 * @generated from message ai.h2o.orchestrator.v1.UpdateWorkflowTriggerResponse
 */
export type UpdateWorkflowTriggerResponse = {
/**
 * Updated WorkflowTrigger resource.
 *
 * @generated from field: ai.h2o.orchestrator.v1.WorkflowTrigger workflow_trigger = 1;
 */
workflowTrigger?: WorkflowTrigger;
}
;
/**
 * Request message for for DeleteWorkflowTrigger
 *
 * @generated from message ai.h2o.orchestrator.v1.DeleteWorkflowTriggerRequest
 */
export type DeleteWorkflowTriggerRequest = {
/**
 * Required. WorkflowTrigger resource name.
 * Format: workspaces/{workspace}/workflows/{workflow}/triggers/{trigger}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DeleteWorkflowTrigger
 *
 * @generated from message ai.h2o.orchestrator.v1.DeleteWorkflowTriggerResponse
 */
export type DeleteWorkflowTriggerResponse = {
}
;
/**
 * Request message for ListWorkflowTriggers
 *
 * @generated from message ai.h2o.orchestrator.v1.ListWorkflowTriggersRequest
 */
export type ListWorkflowTriggersRequest = {
/**
 * Required. The resource name of the workflow to list triggers from.
 * Format: `workspaces/{workspace}/workflows/{workflow}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 * Server can decide to return less items then specified.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Use value returned in next_page_token to retrieve next page of results.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
/**
 * When used, server will only return workflow triggers that match the filter.
 * Attempts to generally implement AIP-160 (https://aip.dev/160)
 * specification.
 *
 * Supported fields:
 *   * display_name
 *   * create_time
 *   * state
 *
 * Examples:
 * display_name = 'Some display' OR create_time <= 2023-12-30T23:59:43Z
 * create_time >= 2023-12-30T23:59:43Z AND create_time <= 2024-03-20T08:00:00Z
 * NOT(display_name = 'Some display' OR create_time <= 2023-12-30T23:59:43Z)
 *
 * @generated from field: string filter = 4;
 */
filter?: string;
/**
 * When used, server will return workflow triggers ordered by the specified fields.
 * Attempts to implement AIP-132 (https://aip.dev/132#ordering).
 * Supported fields are the ones supported by filter.
 *
 * @generated from field: string order_by = 5;
 */
orderBy?: string;
}
;
/**
 * Response message for ListWorkflowTriggers
 *
 * @generated from message ai.h2o.orchestrator.v1.ListWorkflowTriggersResponse
 */
export type ListWorkflowTriggersResponse = {
/**
 * Collection of workflows triggers.
 *
 * @generated from field: repeated ai.h2o.orchestrator.v1.WorkflowTrigger workflow_triggers = 1;
 */
workflowTriggers?: WorkflowTrigger[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for GetWorkflowTrigger
 *
 * @generated from message ai.h2o.orchestrator.v1.GetWorkflowTriggerRequest
 */
export type GetWorkflowTriggerRequest = {
/**
 * Required. WorkflowTrigger resource name.
 * Format: workspaces/{workspace}/workflows/{workflow}/triggers/{trigger}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for GetWorkflowTrigger
 *
 * @generated from message ai.h2o.orchestrator.v1.GetWorkflowTriggerResponse
 */
export type GetWorkflowTriggerResponse = {
/**
 * The requested WorkflowTrigger
 *
 * @generated from field: ai.h2o.orchestrator.v1.WorkflowTrigger workflow_trigger = 1;
 */
workflowTrigger?: WorkflowTrigger;
}
;
/**
 * Request message for PauseWorkflowTrigger
 *
 * @generated from message ai.h2o.orchestrator.v1.PauseWorkflowTriggerRequest
 */
export type PauseWorkflowTriggerRequest = {
/**
 * Required. WorkflowTrigger resource name.
 * Format: workspaces/{workspace}/workflows/{workflow}/triggers/{trigger}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for PauseWorkflowTrigger
 *
 * @generated from message ai.h2o.orchestrator.v1.PauseWorkflowTriggerResponse
 */
export type PauseWorkflowTriggerResponse = {
}
;
/**
 * Request message for ResumeWorkflowTrigger
 *
 * @generated from message ai.h2o.orchestrator.v1.ResumeWorkflowTriggerRequest
 */
export type ResumeWorkflowTriggerRequest = {
/**
 * Required. WorkflowTrigger resource name.
 * Format: workspaces/{workspace}/workflows/{workflow}/triggers/{trigger}
 *
 * @generated from field: string name = 2;
 */
name: string;
}
;
/**
 * Response message for ResumeWorkflowTrigger
 *
 * @generated from message ai.h2o.orchestrator.v1.ResumeWorkflowTriggerResponse
 */
export type ResumeWorkflowTriggerResponse = {
}
;
/**
 * Creates a new WorkflowTrigger within a specified workflow.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowTriggerService.CreateWorkflowTrigger
 */
export const WorkflowTriggerService_CreateWorkflowTrigger = new RPC<CreateWorkflowTriggerRequest,CreateWorkflowTriggerResponse>("POST", "/v1/{parent=workspaces/*/workflows/*}/triggers", "workflowTrigger");
/**
 * Updates a workflow trigger.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowTriggerService.UpdateWorkflowTrigger
 */
export const WorkflowTriggerService_UpdateWorkflowTrigger = new RPC<UpdateWorkflowTriggerRequest,UpdateWorkflowTriggerResponse>("PATCH", "/v1/{workflowTrigger.name=workspaces/*/workflows/*/triggers/*}", "workflowTrigger");
/**
 * Deletes a WorkflowTrigger.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowTriggerService.DeleteWorkflowTrigger
 */
export const WorkflowTriggerService_DeleteWorkflowTrigger = new RPC<DeleteWorkflowTriggerRequest,DeleteWorkflowTriggerResponse>("DELETE", "/v1/{name=workspaces/*/workflows/*/triggers/*}");
/**
 * Returns a specific WorkflowTrigger.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowTriggerService.GetWorkflowTrigger
 */
export const WorkflowTriggerService_GetWorkflowTrigger = new RPC<GetWorkflowTriggerRequest,GetWorkflowTriggerResponse>("GET", "/v1/{name=workspaces/*/workflows/*/triggers/*}");
/**
 * Returns a collection of WorkflowTrigger within a workflow.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowTriggerService.ListWorkflowTriggers
 */
export const WorkflowTriggerService_ListWorkflowTriggers = new RPC<ListWorkflowTriggersRequest,ListWorkflowTriggersResponse>("GET", "/v1/{parent=workspaces/*/workflows/*}/triggers");
/**
 * Pause the workflow trigger.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowTriggerService.PauseWorkflowTrigger
 */
export const WorkflowTriggerService_PauseWorkflowTrigger = new RPC<PauseWorkflowTriggerRequest,PauseWorkflowTriggerResponse>("POST", "/v1/{name=workspaces/*/workflows/*/triggers/*}:pause");
/**
 * Resumes the workflow trigger.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.WorkflowTriggerService.ResumeWorkflowTrigger
 */
export const WorkflowTriggerService_ResumeWorkflowTrigger = new RPC<ResumeWorkflowTriggerRequest,ResumeWorkflowTriggerResponse>("POST", "/v1/{name=workspaces/*/workflows/*/triggers/*}:resume");
