import { Deployment } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_pb';
import { ListProjectDeploymentsRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_service_pb';
import {
  DeploymentState,
  DeploymentStatus,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_status_pb';
import { ListDeploymentStatusesRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_status_service_pb';
import { ConfigurableEndpoint } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/endpoint_pb';
import {
  CreateEndpointRequest,
  ListEndpointsRequest,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/endpoint_service_pb';
import { FilterRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/listing_pb';
import { DeploymentService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_service_connect';
import { DeploymentStatusService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_status_service_connect';
import { EndpointService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/endpoint_service_connect';
import { DeploymentEnvironment } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/deployment_environment_pb';
import { ListDeploymentEnvironmentsRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/deployment_environment_service_pb';
import { Operator } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/query_pb';
import { DeploymentEnvironmentService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/deployment_environment_service_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { IDropdownOption, IStyle, MessageBarType, Stack } from '@fluentui/react';
import {
  BaseDialog,
  Button,
  Dropdown,
  IH2OTheme,
  Info,
  TextField,
  TextWithCopy,
  buttonStylesPrimary,
  dropdownStylesInlineLeft,
  textWithCopyStylesBorder,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import Header from '../../components/Header/Header';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import { RowHeaderTitle } from '../../components/RowHeaderTitle/RowHeaderTitle';
import WidgetList from '../../components/WidgetList/WidgetList';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
import { ContextMenuIconButton } from '../Orchestrator/Workflows';
import { Tag } from '../Orchestrator/WorkflowTabExecutions';
import { ENDPOINTS, ROUTES } from './constants';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

interface IDeploymentsStyles {
  title: IStyle;
  dialogInput: IStyle;
}

type DeploymentItem = Deployment & {
  createdTimeLocal: string;
  createdByName: string;
  status: DeploymentStatus;
  onCreateEndpoint?: () => void;
  endpointOptions: IDropdownOption[];
  onChangeEndpointOption?: (key: string) => void;
  selectedOptionText?: string;
};

const deploymentsStyles = (theme: IH2OTheme): IDeploymentsStyles => {
    return {
      title: {
        marginTop: 0,
        // TODO: Remove once theme is used somewhere else.
        color: theme.semanticColors?.textPrimary,
      },
      dialogInput: {
        width: '30%',
        minWidth: 360,
        maxWidth: 460,
        marginRight: 12,
      },
    };
  },
  // TODO: Find out how to theme this.
  getStateProps = (state?: DeploymentState) => {
    switch (state) {
      case DeploymentState.LAUNCHING:
        return { name: 'Launching', color: 'var(--h2o-yellow500)' };
      case DeploymentState.FAILED:
        return { name: 'Failed', color: 'var(--h2o-red500)' };
      case DeploymentState.HEALTHY:
        return { name: 'Healthy', color: 'var(--h2o-green500)' };
      case DeploymentState.UNHEALTY:
        return { name: 'Unhealthy', color: 'var(--h2o-red500)' };
      case DeploymentState.TERMINATING:
        return { name: 'Terminating', color: 'var(--h2o-yellow500)' };
      case DeploymentState.PENDING:
        return { name: 'Pending', color: 'var(--h2o-yellow500)' };
      default:
        return { name: 'Unknown', color: 'var(--h2o-gray900)' };
    }
  },
  getDeploymentFilter = (filter: string) =>
    ({
      query: {
        clause: [
          {
            propertyConstraint: [
              {
                property: {
                  propertyType: {
                    value: 'display_name',
                    case: 'field',
                  },
                },
                operator: Operator.CONTAINS,
                value: {
                  value: {
                    case: 'stringValue',
                    value: filter,
                  },
                },
              },
            ],
          },
        ],
      },
    } as FilterRequest),
  columns = [
    {
      key: 'title',
      name: 'Title',
      fieldName: 'description',
      minWidth: 200,
      maxWidth: 400,
      data: {
        headerFieldName: 'displayName',
        listCellProps: {
          onRenderHeader: ({ displayName }: Deployment) => RowHeaderTitle({ title: displayName }),
          iconProps: {
            iconName: 'FolderHorizontal',
          },
        },
      },
    },
    {
      key: 'endpoints',
      name: 'Endpoints',
      minWidth: 300,
      maxWidth: 800,
      data: {
        listCellProps: {
          emptyMessage: 'No Description',
          onRenderText: ({ endpointOptions, onChangeEndpointOption, selectedOptionText }: DeploymentItem) =>
            endpointOptions.length ? (
              <TextWithCopy
                hasBorder
                header={
                  <Dropdown
                    options={endpointOptions}
                    styles={dropdownStylesInlineLeft}
                    onChange={(_, option) => onChangeEndpointOption && onChangeEndpointOption(option?.key as string)}
                    defaultSelectedKey={selectedOptionText}
                  />
                }
                styles={[
                  textWithCopyStylesBorder,
                  {
                    header: {
                      border: 0,
                      borderRadius: 'none',
                      padding: 0,
                    },
                  },
                ]}
                // TODO: Add info icon with sample request.
                text={selectedOptionText || 'No endpoint'}
              />
            ) : (
              'No endpoints available'
            ),
        },
      },
    },
    {
      key: 'user',
      name: 'Created by',
      fieldName: 'createdByName',
      minWidth: 150,
      maxWidth: 220,
    },
    {
      key: 'createdAt',
      name: 'Created at',
      fieldName: 'createdTimeLocal',
      minWidth: 150,
      maxWidth: 250,
    },
    {
      key: 'status',
      name: '',
      fieldName: 'status',
      minWidth: 170,
      maxWidth: 180,
      data: {
        listCellProps: {
          styles: {
            root: {
              justifyContent: 'center',
            },
          },
          onRenderText: ({ status }: DeploymentItem) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Tag title={getStateProps(status?.state).name} color={getStateProps(status?.state).color} />
              {status?.message && <Info isTooltip>{status?.message}</Info>}
            </div>
          ),
        },
      },
    },
    {
      key: 'buttons',
      name: '',
      minWidth: 130,
      data: {
        listCellProps: {
          emptyMessage: 'No Description',
          onRenderText: ({ onCreateEndpoint }: DeploymentItem) => (
            // TODO: Use theme prop for colors.
            <ContextMenuIconButton
              items={[
                {
                  key: 'createEndpoint',
                  text: 'Create endpoint URL',
                  onClick: onCreateEndpoint,
                  iconProps: {
                    iconName: 'AddLink',
                  },
                },
              ]}
            />
          ),
          styles: {
            root: {
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'end',
            },
          },
        },
      },
    },
  ];

const Deployments = () => {
  const theme = useTheme(),
    classNames = useClassNames<IDeploymentsStyles, ClassNamesFromIStyles<IDeploymentsStyles>>(
      'deployments',
      deploymentsStyles(theme)
    ),
    history = useHistory(),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID } = useProjects(),
    loadStateRef = React.useRef({
      fetchDeployments: false,
      fetchDeploymentStatuses: false,
      creatingEndpoint: false,
      fetchDeploymentEnvironments: false,
      fetchEndpoints: false,
    }),
    [loading, setLoading] = React.useState(true),
    [isLoadingMore, setIsLoadingMore] = React.useState(false),
    [isLoadingSearch, setIsLoadingSearch] = React.useState(false),
    [nextPageToken, setNextPageToken] = React.useState<string>(),
    [lastRefreshed, setLastRefreshed] = React.useState<Date>(),
    [deploymentItems, setDeploymentItems] = React.useState<DeploymentItem[]>(),
    [deploymentStatusItems, setDeploymentStatusItems] = React.useState<DeploymentStatus[]>(),
    [deploymentEnvironments, setDeploymentEnvironments] = React.useState<DeploymentEnvironment[]>(),
    [endpoints, setEndpoints] = React.useState<ConfigurableEndpoint[]>(),
    [dialogVisible, setDialogVisible] = React.useState(false),
    [customEndpointPath, setCustomEndpointPath] = React.useState(''),
    [endpointName, setEndpointName] = React.useState(''),
    [endpointDescription, setEndpointDescription] = React.useState(''),
    [endpointPrefix, setEndpointPrefix] = React.useState(''),
    [endpointSuffix, setEndpointSuffix] = React.useState(''),
    [deploymentTarget, setDeploymentTarget] = React.useState(''),
    [showValidation, setShowValidation] = React.useState(false),
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    deploymentTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.deployment}/`,
    }),
    deploymentClient = createPromiseClient(DeploymentService, deploymentTransport),
    deploymentStatusClient = createPromiseClient(DeploymentStatusService, deploymentTransport),
    endpointClient = createPromiseClient(EndpointService, deploymentTransport),
    deploymentEnvironmentClient = createPromiseClient(DeploymentEnvironmentService, storageTransport),
    evaluateLoading = () => {
      if (
        !loadStateRef.current.fetchDeployments &&
        !loadStateRef.current.fetchDeploymentStatuses &&
        !loadStateRef.current.creatingEndpoint &&
        !loadStateRef.current.fetchDeploymentEnvironments &&
        !loadStateRef.current.fetchEndpoints
      ) {
        setLoading(false);
        setLastRefreshed(new Date());
      }
    },
    onAction = () => history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}/create-new`),
    getEndpoints = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID) return;
      loadStateRef.current.fetchEndpoints = true;
      setLoading(true);
      try {
        const parentEnv = `projects/${ACTIVE_PROJECT_ID}/environments/${
            deploymentEnvironments?.[0].id || '244f6087-5cb2-4fe6-94bd-37916474e404'
          }`,
          listEndpointsBody = new ListEndpointsRequest({
            parent: parentEnv,
          }),
          response = await endpointClient.listEndpoints(listEndpointsBody),
          endpoints: ConfigurableEndpoint[] | undefined = response?.endpoints;
        setEndpoints(endpoints);
        if (response && !endpoints) console.error('No endpoints found in the response.');
      } catch (err) {
        const message = `Failed to fetch endpoints: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setEndpoints(undefined);
      } finally {
        loadStateRef.current.fetchEndpoints = false;
        evaluateLoading();
      }
    }, [ACTIVE_PROJECT_ID, addToast, deploymentEnvironments]),
    // TODO: Move to global scope.
    getDeploymentEnvironments = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID) return;
      loadStateRef.current.fetchDeploymentEnvironments = true;
      setLoading(true);
      try {
        const listDeploymentEnvironmentsRequest = new ListDeploymentEnvironmentsRequest({
            projectId: ACTIVE_PROJECT_ID,
          }),
          response = await deploymentEnvironmentClient.listDeploymentEnvironments(listDeploymentEnvironmentsRequest),
          deploymentEnvironments: DeploymentEnvironment[] | undefined = response?.deploymentEnvironment;
        setDeploymentEnvironments(deploymentEnvironments);
        if (response && !deploymentEnvironments) console.error('No deployment environments found in the response.');
      } catch (err) {
        const message = `Failed to fetch deployment environments: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setDeploymentEnvironments(undefined);
      } finally {
        loadStateRef.current.fetchDeploymentEnvironments = false;
        evaluateLoading();
      }
    }, [ACTIVE_PROJECT_ID, addToast]),
    createCustomEndpoint = React.useCallback(
      async (deploymentId: string, path: string, displayName: string, description?: string) => {
        if (!ACTIVE_PROJECT_ID) return;
        loadStateRef.current.creatingEndpoint = true;
        try {
          const parentEnv = `projects/${ACTIVE_PROJECT_ID}/environments/${
            deploymentEnvironments?.[0].id || '244f6087-5cb2-4fe6-94bd-37916474e404'
          }`;
          const createEndpointBody = new CreateEndpointRequest({
            parent: parentEnv,
            endpoint: new ConfigurableEndpoint({
              displayName,
              description,
              path,
              target: `${parentEnv}/deployments/${deploymentId}`,
            }),
          });
          await endpointClient.createEndpoint(createEndpointBody);
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Endpoint created successfully.',
          });
        } catch (err) {
          const message = `Failed to create endpoint: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.creatingEndpoint = false;
          evaluateLoading();
          refreshItems();
        }
      },
      [addToast, ACTIVE_PROJECT_ID, deploymentEnvironments]
    ),
    onCreateEndpointAction = React.useCallback(
      (deploymentId: string) => {
        setDeploymentTarget(deploymentId);
        setDialogVisible(true);
      },
      [createCustomEndpoint]
    ),
    onChangeEndpointOption = React.useCallback((key: string) => {
      setDeploymentItems((deploymentItems) =>
        deploymentItems?.map(
          (item) =>
            ({
              ...item,
              selectedOptionText: key,
            } as DeploymentItem)
        )
      );
    }, []),
    fetchDeployments = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        if (!ACTIVE_PROJECT_ID) return;
        loadStateRef.current.fetchDeployments = true;
        if (pageToken) setIsLoadingMore(true);
        else if (filter || filter === `""`) setIsLoadingSearch(true);
        else setLoading(true);
        try {
          const listDeploymentsBody = new ListProjectDeploymentsRequest({
            projectId: ACTIVE_PROJECT_ID,
            filter: filter ? getDeploymentFilter(filter) : undefined,
            paging: {
              pageSize: 20,
              pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
            },
          });
          const response = await deploymentClient.listProjectDeployments(listDeploymentsBody);
          const deploymentItems: Deployment[] | undefined = response?.deployment;
          if (response && !deploymentItems) console.error('No deployments found in the response.');
          setNextPageToken(
            response?.paging?.nextPageToken ? new TextDecoder().decode(response.paging.nextPageToken) : undefined
          );
          // The hardcoded fallback value is for local mocks.
          const parentEnv = `projects/${ACTIVE_PROJECT_ID}/environments/${
            deploymentEnvironments?.[0].id || '244f6087-5cb2-4fe6-94bd-37916474e404'
          }`;
          const newItems: DeploymentItem[] | undefined = deploymentItems?.map((item) => {
            const deploymentStatusItem =
                deploymentStatusItems?.find((status) => status.deploymentId === item.id) || ({} as DeploymentStatus),
              defaultKey = deploymentStatusItem?.scorer?.score?.url || '',
              [urlPrefix, urlSuffix] = defaultKey.includes(item.id) ? defaultKey.split(item.id) : ['', ''];
            if (urlPrefix && !endpointPrefix) setEndpointPrefix(urlPrefix);
            if (urlSuffix && !endpointSuffix) setEndpointSuffix(urlSuffix);
            const endpointOptions: IDropdownOption[] = [];
            if (defaultKey) {
              endpointOptions.push({
                key: defaultKey,
                text: 'Scorer (default)',
              });
            }
            if (endpoints?.length) {
              endpointOptions.push(
                ...endpoints
                  .filter((endpoint) => endpoint.target.includes(`${parentEnv}/deployments/${item.id}`))
                  .map((endpoint) => ({
                    key: `${urlPrefix}${endpoint.path}${urlSuffix}`,
                    text: endpoint.displayName,
                  }))
              );
            }
            return {
              ...item,
              createdTimeLocal:
                item.createdTime?.seconds !== undefined
                  ? new Date(Number(item.createdTime.seconds) * 1000).toLocaleString()
                  : '',
              createdByName: item.userInfo?.ownerName || item.userInfo?.ownerId || '',
              status: deploymentStatusItem,
              onCreateEndpoint: () => onCreateEndpointAction(item.id),
              onChangeEndpointOption,
              selectedOptionText: defaultKey,
              endpointOptions,
            } as DeploymentItem;
          });
          setDeploymentItems((items) => (pageToken ? [...(items || []), ...(newItems || [])] : newItems));
        } catch (err) {
          const message = `Failed to fetch deployments: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setDeploymentItems(undefined);
        } finally {
          loadStateRef.current.fetchDeployments = false;
          evaluateLoading();
          setIsLoadingMore(false);
          setIsLoadingSearch(false);
        }
      },
      [
        addToast,
        ACTIVE_PROJECT_ID,
        onCreateEndpointAction,
        deploymentEnvironments,
        endpoints,
        deploymentStatusItems,
        onChangeEndpointOption,
      ]
    ),
    fetchDeploymentStatuses = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID) return;
      loadStateRef.current.fetchDeploymentStatuses = true;
      try {
        const listDeploymentStatusesBody = new ListDeploymentStatusesRequest({
          projectId: ACTIVE_PROJECT_ID,
        });
        const response = await deploymentStatusClient.listDeploymentStatuses(listDeploymentStatusesBody);
        const deploymentStatusItems: DeploymentStatus[] | undefined = response?.deploymentStatus;
        if (response && !deploymentStatusItems) console.error('No deployment statuses found in the response.');
        setDeploymentStatusItems(deploymentStatusItems);
      } catch (err) {
        const message = `Failed to fetch deployment statuses: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setDeploymentStatusItems(undefined);
      } finally {
        loadStateRef.current.fetchDeploymentStatuses = false;
        evaluateLoading();
      }
    }, [addToast, ACTIVE_PROJECT_ID]),
    refreshItems = () => {
      void fetchDeployments();
      void fetchDeploymentStatuses();
      void getDeploymentEnvironments();
      void getEndpoints();
    },
    onDialogDismiss = () => {
      setDialogVisible(false);
      setDeploymentTarget('');
      setCustomEndpointPath('');
      setEndpointName('');
      setEndpointDescription('');
      setShowValidation(false);
    },
    onEndpointCreate = () => {
      if (!customEndpointPath || !endpointName) {
        setShowValidation(true);
        return;
      }
      setDialogVisible(false);
      createCustomEndpoint(deploymentTarget, customEndpointPath, endpointName, endpointDescription);
    },
    widgetListProps = {
      columns,
      items: deploymentItems,
      loading: !!loading,
      delayLoader: false,
      isLoadingMore: isLoadingMore,
      onLoadMore: nextPageToken ? () => void fetchDeployments(nextPageToken) : undefined,
      isLoadingSearch,
      lastRefreshed: lastRefreshed?.toLocaleString(),
      onRefresh: refreshItems,
      searchProps: {
        placeholder: 'Search deployments',
        // TODO: Check if "value" is correct.
        onSearchChange: (value: string) => void fetchDeployments(undefined, value),
      },
      NoItemsContent: NoItemView({
        title: 'No deployments',
        description: 'There are no deployments available in this project. Deploy your models now.',
        actionTitle: 'Create deployment',
        onActionClick: onAction,
        actionIcon: 'Add',
      }),
      ErrorContent: FailedToLoadView({
        title: 'Failed to load deployments',
        description: 'Please try again later. If the problem persists, contact our support.',
        actionTitle: 'Retry',
        onActionClick: fetchDeployments,
        actionIcon: 'Refresh',
      }),
    };

  React.useEffect(() => void fetchDeploymentStatuses(), [fetchDeploymentStatuses]);

  React.useEffect(() => void fetchDeployments(), [fetchDeployments]);

  React.useEffect(() => void getDeploymentEnvironments(), [getDeploymentEnvironments]);

  React.useEffect(() => void getEndpoints(), [getEndpoints]);

  React.useEffect(() => {
    if (deploymentItems && deploymentStatusItems) {
      const newItems: DeploymentItem[] = deploymentItems.map(
        (item) =>
          ({
            ...item,
            status: deploymentStatusItems.find((status) => status.deploymentId === item.id) || ({} as DeploymentStatus),
          } as DeploymentItem)
      );
      setDeploymentItems(newItems);
    }
  }, [deploymentStatusItems]);

  return (
    <PageWrapper>
      {deploymentItems?.length ? <Header action="Create deployment" onActionClick={onAction} actionIcon="Add" /> : null}
      <WidgetList {...widgetListProps} />
      <BaseDialog
        hidden={!dialogVisible}
        title={'Create new endpoint'}
        content={
          <>
            <p>If the default scorer endpoint is not suitable, you can create the endpoint with a custom path.</p>
            <TextField
              required
              label="Name"
              value={endpointName}
              className={classNames.dialogInput}
              onChange={(_ev, newValue) => setEndpointName(newValue || '')}
              errorMessage={showValidation && !endpointName ? 'This field cannot be empty.' : ''}
            />
            <TextField
              label="Description"
              value={endpointDescription}
              className={classNames.dialogInput}
              onChange={(_ev, newValue) => setEndpointDescription(newValue || '')}
            />
            <TextField
              required
              label="Path"
              value={customEndpointPath}
              className={classNames.dialogInput}
              onChange={(_ev, newValue) => setCustomEndpointPath(newValue || '')}
              errorMessage={showValidation && !customEndpointPath ? 'This field cannot be empty.' : ''}
            />
            {customEndpointPath ? (
              <Info>
                <b>Endpoint URL preview: </b>
                {`${endpointPrefix}${customEndpointPath}${endpointSuffix}`}
              </Info>
            ) : null}
          </>
        }
        onDismiss={onDialogDismiss}
        footer={
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
            <Button onClick={onDialogDismiss} text="Cancel" />
            <Button styles={buttonStylesPrimary} onClick={onEndpointCreate} iconName="Add" text="Create endpoint" />
          </Stack>
        }
      />
    </PageWrapper>
  );
};

export default Deployments;
