import { DropdownMenuItemType, IDropdownOption } from '@fluentui/react';

export const containsObjectByKey = (array1: any[], array2: any[], key1: string, key2: string): boolean => {
  // Checks whether one array of objects contains an object with specific "key1" and "key2" values from another array.
  return array1.some((item1) => array2.some((item2) => item1[key1] === item2[key1] && item1[key2] === item2[key2]));
};

export const groupRoles = (roles: IDropdownOption[]): IDropdownOption[] => {
  const groups: { [key: string]: { title: string; items: IDropdownOption[] } } = {
    orchestrator: { title: 'Orchestrator', items: [] },
    drive: { title: 'Drive', items: [] },
    others: { title: 'Others', items: [] },
  };

  roles.forEach((role) => {
    if (String(role.key).includes('roles/orchestrator')) {
      groups.orchestrator.items.push(role);
    } else if (String(role.key).includes('roles/drive')) {
      groups.drive.items.push(role);
    } else {
      groups.others.items.push(role);
    }
  });

  return [
    ...(groups.orchestrator.items.length === 0
      ? []
      : [
          { key: 'orchestrator', text: 'Orchestrator', itemType: DropdownMenuItemType.Header },
          ...groups.orchestrator.items,
        ]),
    ...(groups.drive.items.length === 0
      ? []
      : [{ key: 'drive', text: 'Drive', itemType: DropdownMenuItemType.Header }, ...groups.drive.items]),
    ...(groups.others.items.length === 0
      ? []
      : [{ key: 'others', text: 'Others', itemType: DropdownMenuItemType.Header }, ...groups.others.items]),
  ].filter(Boolean);
};
