import { useCallback } from 'react';

import { AppInstance_Visibility, AppPreconditionStatus, App_Visibility } from '../ai.h2o.cloud.appstore';
import AppListPage from '../components/AppListPage/AppListPage';
import { EnvService } from '../services/api';
import { useApp, useInstance } from '../utils/hooks';

function MyAppsPage() {
  const { getApps } = useApp();
  const { getInstancesList } = useInstance();

  const fetchApps = useCallback(
    async () =>
      getApps({
        limit: 1000,
        offset: 0,
        visibility: App_Visibility.VISIBILITY_UNSPECIFIED,
        allUsers: false,
        name: '',
        latestVersions: false,
        withPreference: false,
        tags: [],
        conditionsStatus: AppPreconditionStatus.STATUS_UNSPECIFIED,
        visibilities: [],
      }),
    []
  );

  const fetchInstances = useCallback(
    async () =>
      getInstancesList({
        appId: '',
        includeAppDetails: false,
        visibility: AppInstance_Visibility.VISIBILITY_UNSPECIFIED,
        allUsers: false,
        allWorkspaces: false,
        parent: '',
      }),
    []
  );

  const fetchProfiles = useCallback(
    async () => (await EnvService.listLaunchProfiles({})).launchProfiles || undefined,
    []
  );

  return (
    <AppListPage
      title="My Apps"
      fetchApps={fetchApps}
      fetchInstances={fetchInstances}
      fetchProfiles={fetchProfiles}
      isAdmin={false}
    />
  );
}

export default MyAppsPage;
