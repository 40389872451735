// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/authorization/v1/role_api.proto (package ai.h2o.authorization.v1, syntax proto3)
/* eslint-disable */

import type { Role } from "./role_pb";
import type { ActionStatement } from "./policy_pb";
import { RPC } from "../../../../runtime";

/**
 * @generated from message ai.h2o.authorization.v1.GetRoleRequest
 */
export type GetRoleRequest = {
/**
 * Role name, e.g. "roles/<UUID>".
 *
 * @generated from field: string name = 1;
 */
name?: string;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.GetRoleResponse
 */
export type GetRoleResponse = {
/**
 * @generated from field: ai.h2o.authorization.v1.Role role = 1;
 */
role?: Role;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.CreateRoleRequest
 */
export type CreateRoleRequest = {
/**
 * @generated from field: ai.h2o.authorization.v1.Role role = 1;
 */
role?: Role;
/**
 * The ID to use for the Role, which will become the final component of
 * the roles's resource name.
 *
 * This value should be 4-63 characters, can contain only lowercase
 * alphanumeric characters and hyphen, cannot start with number or hyphen
 * and cannot end with hyphen.
 *
 * @generated from field: string role_id = 2;
 */
roleId?: string;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.CreateRoleResponse
 */
export type CreateRoleResponse = {
/**
 * @generated from field: ai.h2o.authorization.v1.Role role = 1;
 */
role?: Role;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.UpdateRoleRequest
 */
export type UpdateRoleRequest = {
/**
 * @generated from field: ai.h2o.authorization.v1.Role role = 1;
 */
role?: Role;
/**
 * Only the fields specified in update_mask will be updated.
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask?: string;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.UpdateRoleResponse
 */
export type UpdateRoleResponse = {
/**
 * @generated from field: ai.h2o.authorization.v1.Role role = 1;
 */
role?: Role;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.DeleteRoleRequest
 */
export type DeleteRoleRequest = {
/**
 * @generated from field: string name = 1;
 */
name?: string;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.DeleteRoleResponse
 */
export type DeleteRoleResponse = {
}
;
/**
 * @generated from message ai.h2o.authorization.v1.ListRolesRequest
 */
export type ListRolesRequest = {
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 * Server can decide to return more or less items then specified.
 *
 * @generated from field: int32 page_size = 1;
 */
pageSize?: number;
/**
 * Use value returned in next_page_token to retrieve next page of results.
 *
 * @generated from field: string page_token = 2;
 */
pageToken?: string;
/**
 * When used, server will only return roles that match the filter.
 * Attempts to generally implement AIP-160 (https://aip.dev/160)
 * specification.
 * See https://github.com/h2oai/authz-server#filtering for specific supported
 * features.
 *
 * Supported fields:
 *   * display_name
 *   * description
 *   * create_time
 *   * update_time
 *   * creator
 *   * updater
 *
 * Fields that are used for literal string matching:
 *  * display_name
 *  * description
 *
 * @generated from field: string filter = 4;
 */
filter?: string;
/**
 * When used, server will return roles ordered by the specified fields.
 * Attempts to implement AIP-132 (https://aip.dev/132#ordering).
 * Supported fields are the ones supported by filter.
 *
 * @generated from field: string order_by = 5;
 */
orderBy?: string;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.ListRolesResponse
 */
export type ListRolesResponse = {
/**
 * @generated from field: repeated ai.h2o.authorization.v1.Role roles = 1;
 */
roles?: Role[];
/**
 * When not empty server has more results then returned. Use this value as
 * the page_token in the ListRolesRequest to obtain next page of results.
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.AddActionStatementsRequest
 */
export type AddActionStatementsRequest = {
/**
 * Role name, e.g. "roles/<UUID>".
 *
 * @generated from field: string role = 1;
 */
role?: string;
/**
 * Action statements to add to the role.
 *
 * @generated from field: repeated ai.h2o.authorization.v1.ActionStatement action_statements = 2;
 */
actionStatements?: ActionStatement[];
}
;
/**
 * @generated from message ai.h2o.authorization.v1.AddActionStatementsResponse
 */
export type AddActionStatementsResponse = {
/**
 * @generated from field: ai.h2o.authorization.v1.Role role = 1;
 */
role?: Role;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.RemoveActionStatementsRequest
 */
export type RemoveActionStatementsRequest = {
/**
 * Role name, e.g. "roles/<UUID>".
 *
 * @generated from field: string role = 1;
 */
role?: string;
/**
 * Action statements to remove from the role.
 *
 * @generated from field: repeated ai.h2o.authorization.v1.ActionStatement action_statements = 2;
 */
actionStatements?: ActionStatement[];
}
;
/**
 * @generated from message ai.h2o.authorization.v1.RemoveActionStatementsResponse
 */
export type RemoveActionStatementsResponse = {
/**
 * @generated from field: ai.h2o.authorization.v1.Role role = 1;
 */
role?: Role;
}
;
/**
 * @generated from rpc ai.h2o.authorization.v1.RoleService.GetRole
 */
export const RoleService_GetRole = new RPC<GetRoleRequest,GetRoleResponse>("GET", "/v1/{name=roles/*}");
/**
 * @generated from rpc ai.h2o.authorization.v1.RoleService.CreateRole
 */
export const RoleService_CreateRole = new RPC<CreateRoleRequest,CreateRoleResponse>("POST", "/v1/roles", "role");
/**
 * @generated from rpc ai.h2o.authorization.v1.RoleService.UpdateRole
 */
export const RoleService_UpdateRole = new RPC<UpdateRoleRequest,UpdateRoleResponse>("PATCH", "/v1/{role.name=roles/*}", "role");
/**
 * @generated from rpc ai.h2o.authorization.v1.RoleService.DeleteRole
 */
export const RoleService_DeleteRole = new RPC<DeleteRoleRequest,DeleteRoleResponse>("DELETE", "/v1/{name=roles/*}");
/**
 * @generated from rpc ai.h2o.authorization.v1.RoleService.ListRoles
 */
export const RoleService_ListRoles = new RPC<ListRolesRequest,ListRolesResponse>("GET", "/v1/roles");
/**
 * @generated from rpc ai.h2o.authorization.v1.RoleService.AddActionStatements
 */
export const RoleService_AddActionStatements = new RPC<AddActionStatementsRequest,AddActionStatementsResponse>("POST", "/v1/{role=roles/*}:addActionStatements");
/**
 * @generated from rpc ai.h2o.authorization.v1.RoleService.RemoveActionStatements
 */
export const RoleService_RemoveActionStatements = new RPC<RemoveActionStatementsRequest,RemoveActionStatementsResponse>("POST", "/v1/{role=roles/*}:removeActionStatements");
