import { fetchWrapRPC } from '../services/api';
import {
  CreateSecretRequest,
  DeleteSecretRequest,
  GetSecretRequest,
  ListSecretsRequest,
  SecretService_CreateSecret,
  SecretService_DeleteSecret,
  SecretService_GetSecret,
  SecretService_ListSecrets,
  SecretService_UndeleteSecret,
  UndeleteSecretRequest,
} from './gen/ai/h2o/securestore/v1/secret_service_pb';
import {
  CreateSecretVersionRequest,
  GetSecretVersionRequest,
  ListSecretVersionsRequest,
  RevealSecretVersionValueRequest,
  SecretVersionService_CreateSecretVersion,
  SecretVersionService_GetSecretVersion,
  SecretVersionService_ListSecretVersions,
  SecretVersionService_RevealSecretVersionValue,
} from './gen/ai/h2o/securestore/v1/secret_version_service_pb';

export class SecureStoreServiceImpl {
  private basePath: string;
  constructor(basePath?: string) {
    this.basePath = basePath || '';
  }

  public setBasePath = (path: string) => {
    this.basePath = path;
  };

  public async getSecret(req: GetSecretRequest) {
    return await fetchWrapRPC(SecretService_GetSecret, { basePath: this.basePath })({ ...req });
  }

  public async listSecrets(req: ListSecretsRequest) {
    return await fetchWrapRPC(SecretService_ListSecrets, { basePath: this.basePath })({ ...req });
  }

  public async createSecret(req: CreateSecretRequest) {
    return await fetchWrapRPC(SecretService_CreateSecret, { basePath: this.basePath })({ ...req });
  }

  public async deleteSecret(req: DeleteSecretRequest) {
    return await fetchWrapRPC(SecretService_DeleteSecret, { basePath: this.basePath })({ ...req });
  }

  public async unDeleteSecret(req: UndeleteSecretRequest) {
    return await fetchWrapRPC(SecretService_UndeleteSecret, { basePath: this.basePath })({ ...req });
  }

  // Secret Version

  public async getSecretVersion(req: GetSecretVersionRequest) {
    return await fetchWrapRPC(SecretVersionService_GetSecretVersion, { basePath: this.basePath })({ ...req });
  }

  public async listSecretVersions(req: ListSecretVersionsRequest) {
    return await fetchWrapRPC(SecretVersionService_ListSecretVersions, { basePath: this.basePath })({ ...req });
  }

  public async createSecretVersion(req: CreateSecretVersionRequest) {
    return await fetchWrapRPC(SecretVersionService_CreateSecretVersion, { basePath: this.basePath })({ ...req });
  }

  public async revealSecretVersion(req: RevealSecretVersionValueRequest) {
    return await fetchWrapRPC(SecretVersionService_RevealSecretVersionValue, { basePath: this.basePath })({ ...req });
  }
}
