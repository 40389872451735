// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/dai_version_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { DAIVersion } from "./dai_version_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for listing DAIVersions.
 * (-- api-linter: core::0132::request-parent-required=disabled
 *     aip.dev/not-precedent: DAIVersion is a top-level resource, hence, parent
 *     field is not included. --)
 *
 * @generated from message ai.h2o.engine.v1.ListDAIVersionsRequest
 */
export type ListDAIVersionsRequest = {
/**
 * Maximum number of DAIVersions to return in a response.
 * If unspecified (or set to 0), at most 50 DAIVersions will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 1;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListDAIVersionsResponse.
 *
 * @generated from field: string page_token = 2;
 */
pageToken?: string;
/**
 * Used to specify the sorting order.
 *
 * When unset, DAIVersions are ordered by their version name in
 * descending order. This is equivalent to "version desc".
 *
 * When specified, the value must be a comma separated list of supported
 * fields. The supported fields are:
 * - version
 *
 * The default sorting order is ascending. For example: "version" and
 * "version asc" are equivalent values.
 *
 * To specify descending order for a field, append a " desc" suffix. For
 * example: "version desc".
 *
 * Redundant space characters are insignificant.
 *
 * @generated from field: string order_by = 3;
 */
orderBy?: string;
/**
 * Used to filter DAIVersions. When unset, no filtering is applied.
 *
 * Filtering implements specification https://google.aip.dev/160.
 *
 * Supported filter fields:
 * - version (supporting version semantic comparison and version aliases)
 * - deprecated
 *
 * Examples:
 * - `version > "latest" AND version >= "1.10.3"`
 * - `deprecated = false`
 *
 * @generated from field: string filter = 4;
 */
filter?: string;
}
;
/**
 * Response message for DAIVersionService.ListDAIVersions.
 *
 * @generated from message ai.h2o.engine.v1.ListDAIVersionsResponse
 */
export type ListDAIVersionsResponse = {
/**
 * Collection of DAIVersions.
 *
 * @generated from field: repeated ai.h2o.engine.v1.DAIVersion dai_versions = 1;
 */
daiVersions?: DAIVersion[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
/**
 * Total number of DAIVersions matched by the List request.
 *
 * @generated from field: int32 total_size = 3;
 */
totalSize?: number;
}
;
/**
 * Returns a collection of DAIEngines within a workspace.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIVersionService.ListDAIVersions
 */
export const DAIVersionService_ListDAIVersions = new RPC<ListDAIVersionsRequest,ListDAIVersionsResponse>("GET", "/v1/daiVersions");
