import {
  DirectionalHint,
  IButtonProps as FluentIButtonProps,
  IDropdownOption,
  MessageBarType,
  Stack,
  Text,
} from '@fluentui/react';
import {
  BasicList,
  Button,
  FontSizes,
  IconName,
  Item,
  Loader,
  Pivot,
  IButtonProps as UiKitIButtonProps,
  buttonStylesDefaultWidth,
  buttonStylesLink,
  buttonStylesLinkBlack,
  buttonStylesPrimary,
  buttonStylesSplit,
  buttonStylesSplitPrimary,
  itemStylesTag,
  loaderStylesSpinnerButtonPrimary,
  loaderStylesSpinnerXLarge,
  sort,
  useHaicPageTitle,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  App,
  AppInstance,
  AppInstanceLifecycle,
  AppInstance_Status,
  AppInstance_Visibility,
  AppPreconditionStatus,
  App_Visibility,
  GetAppLocationResponse,
  LaunchProfiles,
} from '../../ai.h2o.cloud.appstore';
import { AppAttributeBadge } from '../../components/AppAttributeBadge/AppAttributeBadge';
import { AppDetailHeader } from '../../components/AppDetailHeader/AppDetailHeader';
import { AppPivot, AppPivotRightCard } from '../../components/AppPivot/AppPivot';
import { AppSocialInfo } from '../../components/AppSocialInfo/AppSocialInfo';
import { InstanceList } from '../../components/InstanceList/InstanceList';
import { InstanceSearchAndFilter } from '../../components/InstanceListPage/InstanceSearchAndFilter';
import { Markdown } from '../../components/Markdown/Markdown';
import { AdminAppService, AppService, EnvService } from '../../services/api';
import { useApp, useEnv, useInstance, useUser } from '../../utils/hooks';
import { InstanceListType } from '../../utils/models';
import {
  InstancePauseResumeMap,
  InstancePauseResumeOpEnum,
  getToastErrorMessage,
  handleErrMsg,
} from '../../utils/utils';
import ErrorPage from '../ErrorPage';
import { RoutePaths } from '../Routes';
import { appDetailPageStyles } from './appDetailPage.styles';
import { AdditionalInfo } from './components/AdditionalInfo/AdditionalInfo';
import { RunWithProfilePanel } from './components/RunWithProfilePanel/RunWithProfilePanel';
import { Share } from './components/Share/Share';

type AppDetailPageProps = {
  id: string;
};

const toastGroupId = 'AppDetailPage';

export const windowOpenErrorMessage = `An error occurred when trying to automatically open a new tab to view the app.\
 This may be due to your browser settings, or a browser extension.\
 You may still view the new instance by clicking the "Visit" button.`;

enum TabKey {
  details = 'details',
  instances = 'instances',
}

interface Poller {
  run: () => Promise<void>;
  suspend: () => Promise<void>;
}

function hasInstances(app: App) {
  return app.instanceLifecycle !== AppInstanceLifecycle.APP_INSTANCE_LIFECYCLE_LINK;
}

function AppDetailPage({ id }: AppDetailPageProps) {
  const theme = useTheme(),
    [app, setApp] = useState<App>({} as App),
    [fatalError, setFatalError] = useState<any>(),
    [versions, setVersions] = useState<IDropdownOption[]>([]),
    [instances, setInstances] = useState<AppInstance[]>(),
    [launchProfiles, setLaunchProfiles] = useState<LaunchProfiles[] | undefined>(undefined),
    [refinedInstances, setRefinedInstances] = useState<AppInstance[]>(),
    [myInstances, setMyInstances] = useState<AppInstance[]>(),
    [refinedMyInstances, setRefinedMyInstances] = useState<AppInstance[]>(),
    [mainInstance, setMainInstance] = useState<AppInstance>(),
    [loadingMessage, setLoadingMessage] = useState(''),
    [isInstancesLoading, setIsInstancesLoading] = useState(true),
    [selectedTabKey, setSelectedTabKey] = useState<TabKey>(TabKey.details),
    [loading, setLoading] = useState<[string, boolean]>(),
    [activePollers, setActivePollers] = useState<string[]>([]),
    [pollersList, setPollersList] = useState<Poller[]>([]),
    [hiddenAdvancedRunPanel, setHiddenAdvancedRunPanel] = useState(true),
    { addToast } = useToast(),
    { getAdminApps, getApps, updateLikes, updatePin } = useApp(),
    { getInstance, getInstancesList, setInstanceSuspension, terminateInstance } = useInstance(),
    env = useEnv(),
    user = useUser(),
    isAdmin = env?.menu?.user?.isAdmin || false,
    hasFullAccess = user?.hasFullAccess || false,
    publicMode = env?.publicModeEnabled && !user.hasFullAccess,
    UserAppServices = isAdmin ? AdminAppService : AppService,
    userGetApps = isAdmin ? getAdminApps : getApps,
    loadInstancesList = useCallback(
      async (app: App) => {
        // Optimization to avoid the slow ListAppInstances RPC when there will be none
        if (!hasInstances(app)) {
          setIsInstancesLoading(false);
          return;
        }
        setIsInstancesLoading(true);
        try {
          // For apps that are not runnable, consider any instance you can see as the main instance.
          // This is key for apps with MANAGED or EXTERNAL instance lifecycle.
          const [allInstances, myInstances] = await Promise.all([
            getInstancesList({
              appId: id,
              includeAppDetails: true,
              allUsers: true,
              visibility: AppInstance_Visibility.VISIBILITY_UNSPECIFIED,
              allWorkspaces: false,
              parent: '',
            }),
            getInstancesList({
              appId: id,
              includeAppDetails: true,
              allUsers: false,
              visibility: AppInstance_Visibility.VISIBILITY_UNSPECIFIED,
              allWorkspaces: false,
              parent: '',
            }),
          ]);
          setInstances(allInstances);
          processPendingInstances(allInstances);
          // For apps that are not runnable, consider any instance you can see as the main instance.
          // This is key for apps with MANAGED or EXTERNAL instance lifecycle.
          if (app.runnable && myInstances.length) {
            setMainInstance(
              myInstances.find((i) =>
                [AppInstance_Status.DEPLOYED, AppInstance_Status.SUSPENDED, AppInstance_Status.PENDING].includes(
                  i.status
                )
              )
            );
          } else {
            setMainInstance(allInstances.find((i) => i.status === AppInstance_Status.DEPLOYED));
          }
          setMyInstances(myInstances);
        } catch ({ message }) {
          addToast(
            getToastErrorMessage(
              `An error occurred while loading app instances: ${handleErrMsg(message as string)}`,
              toastGroupId
            )
          );
        } finally {
          setIsInstancesLoading(false);
        }
      },
      [id, addToast, getInstancesList]
    ),
    runPoller = useCallback(
      async (instance: AppInstance) => {
        const { id } = instance,
          isPending = (status?: AppInstance_Status) => {
            return status === AppInstance_Status.PENDING || false;
          },
          poller = (() => {
            let timer: NodeJS.Timeout | undefined;
            let isSuspended = false;

            const run = async () => {
              try {
                const { status } = await getInstance(id);

                if (isPending(status) && !isSuspended) {
                  timer = setTimeout(run, 3000);
                } else {
                  setActivePollers(activePollers.filter((i) => i !== id));
                  loadInstancesList(app);
                  isSuspended = true;
                  timer ?? clearTimeout(timer);
                }
              } catch (error) {
                timer ?? clearTimeout(timer);
              }
            };

            const suspend = async () => {
              isSuspended = true;
              timer ?? clearTimeout(timer);
            };

            run();

            return {
              run,
              suspend,
            };
          })();
        setPollersList([...pollersList, poller]);
      },
      [getInstance]
    ),
    processPendingInstances = useCallback(
      async (allInstances?: AppInstance[]) => {
        if (!allInstances?.length) return;
        const instances = allInstances.filter(
          (i) => i.status === AppInstance_Status.PENDING && !activePollers.includes(i.id)
        );
        if (!instances.length) return;

        const pollers = [];
        for (const instance of instances) {
          runPoller(instance);
          pollers.push(instance.id);
        }
        setActivePollers([...activePollers, ...pollers]);
      },
      [activePollers, runPoller]
    ),
    loadProfiles = useCallback(async () => {
      const result = !hasFullAccess ? undefined : await EnvService.listLaunchProfiles({});
      setLaunchProfiles(result?.launchProfiles?.length ? result.launchProfiles : undefined);
    }, [hasFullAccess, EnvService.listLaunchProfiles]),
    loadApp = useCallback(async (): Promise<App | undefined> => {
      try {
        const { app: application } = await UserAppServices.getApp({ id });
        setApp(application);
        const apps = await userGetApps({
          limit: 1000,
          offset: 0,
          visibility: App_Visibility.VISIBILITY_UNSPECIFIED,
          allUsers: true,
          name: application.name,
          latestVersions: false,
          withPreference: false,
          tags: [],
          conditionsStatus: AppPreconditionStatus.STATUS_UNSPECIFIED,
          visibilities: [],
        });
        setVersions(
          apps
            .map(({ id, version, visibility }) => ({
              key: id,
              text: visibility === App_Visibility.PRIVATE ? `${version}  (private)` : version,
              data: { icon: IconName.Accounts },
            }))
            .sort(sort<IDropdownOption>(['text'], 'semver', true))
        );
        return application;
      } catch (error: any) {
        setFatalError(error || {});
      }
      return undefined;
    }, [id, userGetApps]),
    loadData = useCallback(
      async (id?: string) => {
        if (!id) setLoadingMessage('Loading App');
        let app;
        try {
          [app] = await Promise.all([loadApp(), loadProfiles()]);
        } finally {
          if (!id) setLoadingMessage('');
        }
        if (app?.id && !publicMode) {
          loadInstancesList(app);
        }
      },
      [loadApp, loadInstancesList]
    ),
    history = useHistory(),
    terminate = useCallback(
      (instance: AppInstance) => async () => {
        const { id } = instance;
        addToast({ message: `Terminating app instance with id ${id}`, messageBarType: MessageBarType.success });
        setLoading([id, true]);
        try {
          await terminateInstance(instance);
          await loadInstancesList(app);
        } catch ({ message }) {
          addToast(
            getToastErrorMessage(`Could not terminate app instance: ${handleErrMsg(message as string)}`, toastGroupId)
          );
        } finally {
          setLoading([id, false]);
        }
      },
      [app, loadInstancesList, addToast, terminateInstance]
    ),
    setSuspension = useCallback(
      (appInstance: AppInstance, opEnum: InstancePauseResumeOpEnum) => async () => {
        const { id } = appInstance;
        const op = InstancePauseResumeMap.get(opEnum);
        if (!op) return;
        const { upperDescription } = op;
        addToast({ message: `${upperDescription} app instance with id ${id}`, messageBarType: MessageBarType.success });
        setLoading([id, true]);
        try {
          await setInstanceSuspension(appInstance, op);
          await loadData(id);
        } finally {
          setLoading([id, false]);
        }
      },
      [loadData, setInstanceSuspension]
    ),
    runApp = useCallback(
      async (visibility: AppInstance_Visibility = AppInstance_Visibility.ALL_USERS, profile = '', parent = '') => {
        addToast({ message: `Running app ${app.title}`, messageBarType: MessageBarType.success });
        setLoading([id, true]);
        try {
          const { instance } = await UserAppServices.runApp({ id: app.id, visibility: visibility, profile, parent });
          if (instance) {
            try {
              await loadInstancesList(app);
            } catch ({ message }) {
              addToast(
                getToastErrorMessage(
                  `An error occurred while trying to update the instance list: : ${handleErrMsg(message as string)}`,
                  toastGroupId
                )
              );
            }
            try {
              window.open(instance.location, '_blank');
            } catch ({ message }) {
              addToast(getToastErrorMessage(windowOpenErrorMessage, toastGroupId));
            }
          }
        } catch ({ message }) {
          addToast(getToastErrorMessage(`Could not run app: ${handleErrMsg(message as string)}`, toastGroupId));
        } finally {
          setLoading([id, false]);
        }
      },
      [app, loadInstancesList, addToast]
    ),
    visitApp = () => {
      const location =
        app.instanceLifecycle === AppInstanceLifecycle.APP_INSTANCE_LIFECYCLE_LINK
          ? app?.link?.location
          : mainInstance?.location;
      window.open(location, '_blank');
      // mainInstance will be null for LINK apps
    },
    loaderProps = {
      label: 'Loading...',
      styles: loaderStylesSpinnerButtonPrimary,
    },
    getActionButton = (loadingState?: [string, boolean]) => {
      let loading = loadingState?.[1] || false;
      const runButton = {
          'data-test': 'public-run-action-button',
          key: 'run-app',
          text: 'Run',
          iconProps: { iconName: IconName.MSNVideos },
          onClick: () => {
            runApp();
          },
          disabled: false,
          loading,
          loaderProps,
        },
        runPrivateButton = {
          'data-test': 'private-run-action-button',
          key: 'run-app-private',
          text: 'Run In Private',
          iconProps: { iconName: IconName.Shield },
          onClick: () => {
            runApp(AppInstance_Visibility.PRIVATE);
          },
          disabled: false,
          loading,
          loaderProps,
        },
        visitButton = {
          'data-test': 'visit-action-button',
          key: 'go-to-instance',
          text: 'Visit',
          iconProps: { iconName: IconName.ArrowUpRight },
          onClick: () => {
            visitApp();
          },
          disabled: false,
          loading,
          loaderProps,
        },
        visitPublicButton = {
          'data-test': 'visit-action-button',
          key: 'go-to-instance',
          text: 'Visit',
          iconProps: { iconName: IconName.ArrowUpRight },
          onClick: () => {
            // slightly hacky in that this is the app.id and not the app instance id, but this works to trigger a re-render to update the loading state
            setLoading([app.id, true]);
            loading = true;
            AppService.getAppLocation({ id: app.id }).then(({ location }: GetAppLocationResponse) => {
              setLoading([app.id, false]);
              loading = false;
              window.open(location, '_blank');
            });
          },
          disabled: false,
          loading,
          loaderProps,
        },
        resumeButton = {
          'data-test': 'resume-action-button',
          key: 'resume-instance',
          text: 'Resume',
          iconProps: { iconName: IconName.MSNVideos },
          onClick: setSuspension(mainInstance || ({} as AppInstance), InstancePauseResumeOpEnum.Resume),
          disabled: false,
          loading,
          loaderProps,
        },
        loadingButton = {
          'data-test': 'loading-action-button',
          key: 'loading-instance',
          text: 'Loading...',
          iconProps: { iconName: IconName.MSNVideos },
          onClick: () => {},
          disabled: true,
          loading: true,
          loaderProps,
        },
        notAvailableButton = {
          'data-test': 'cannot-run-app-action-button',
          key: 'cannot-run-app',
          text: 'Not available',
          iconProps: { iconName: IconName.MSNVideos },
          onClick: () => {},
          disabled: true,
          loading: false,
          loaderProps,
        },
        runWithProfileButton = {
          'data-test': 'run-profile-action-button',
          key: 'run-profile',
          text: 'Run with Profile',
          iconProps: { iconName: IconName.MSNVideos },
          onClick: () => {
            setHiddenAdvancedRunPanel(false);
          },
          loaderProps,
        },
        privateAndAdvancedButtons = [runPrivateButton, ...(launchProfiles ? [runWithProfileButton] : [])],
        menuItems = app.runnable
          ? mainInstance
            ? [runButton, ...privateAndAdvancedButtons]
            : privateAndAdvancedButtons
          : [], // Apps that are not runnable do not have alternative actions
        visitable =
          (mainInstance && mainInstance?.status === AppInstance_Status.DEPLOYED) ||
          app.instanceLifecycle === AppInstanceLifecycle.APP_INSTANCE_LIFECYCLE_LINK,
        getCurrentButton = () => {
          if (publicMode) {
            return visitPublicButton;
          }
          if (visitable) {
            return visitButton;
          }

          if (app.runnable) {
            if (mainInstance?.status === AppInstance_Status.PENDING) {
              return loadingButton;
            }

            if (mainInstance) {
              return resumeButton;
            }

            return runButton;
          }

          return notAvailableButton; // Apps that are not runnable and there is no main instance do not have any actions available
        },
        buttonItem = getCurrentButton(),
        sharedButtonProps: UiKitIButtonProps = {
          text: buttonItem.text,
          iconName: buttonItem.iconProps.iconName,
          disabled: publicMode ? buttonItem.disabled : isInstancesLoading || buttonItem.disabled || loading,
          loading: publicMode ? buttonItem.loading : isInstancesLoading || loading,
          loaderProps: buttonItem.loaderProps,
          onClick: buttonItem.onClick,
        },
        buttonProps: UiKitIButtonProps =
          menuItems?.length > 0 && !publicMode
            ? {
                styles: [buttonStylesSplit, buttonStylesSplitPrimary],
                split: true,
                menuIconName: IconName.ChevronDown,
                menuItems: menuItems.map((item) => ({ ...item, iconProps: undefined })),
                menuDirectionalHint: DirectionalHint.bottomRightEdge,
                splitButtonMenuProps: {
                  'data-test': 'more-actions-button',
                } as FluentIButtonProps,
              }
            : {
                styles: [buttonStylesPrimary, buttonStylesDefaultWidth],
              };
      return (
        <Button
          {...sharedButtonProps}
          {...buttonProps}
          data-test={buttonItem['data-test']}
          className="app-details-action-button"
        />
      );
    },
    getDetailsContent = () => (
      <AppPivot
        mainContent={
          app.longDescription ? (
            <Markdown source={app.longDescription} />
          ) : (
            <p style={{ fontStyle: `italic` }}>This application has no description</p>
          )
        }
        images={app.screenshotLocations}
        rightContent={
          <>
            <Share app={app} />
            <AppPivotRightCard>
              <AdditionalInfo app={app} />
            </AppPivotRightCard>
          </>
        }
      />
    ),
    getTabs = () => {
      const detailsTab = {
          key: TabKey.details,
          headerText: 'App Details',
          content: !loadingMessage && getDetailsContent(),
        },
        myInstancesTab = {
          headerText: `My Instances ${myInstances?.length || '0'}`,
          loading: isInstancesLoading,
          content: (
            <Stack styles={{ root: { width: '100%', paddingBottom: 15 } }}>
              {!isInstancesLoading && myInstances && (
                <Stack style={{ marginTop: 20 }}>
                  <InstanceSearchAndFilter instances={myInstances} onRefine={(data) => setRefinedMyInstances(data)} />
                  <InstanceList
                    instances={refinedMyInstances}
                    type={InstanceListType.app}
                    loadingMsg={loadingMessage}
                    loadingInstance={loading}
                    hideInstanceLogButton
                    terminateInstance={terminate}
                    setInstanceSuspension={setSuspension}
                  />
                </Stack>
              )}
            </Stack>
          ),
        },
        instancesTab = {
          key: TabKey.instances,
          headerText: `All Instances ${instances?.length || '0'}`,
          loading: isInstancesLoading,
          content: (
            <Stack styles={{ root: { width: '100%', paddingBottom: 15 } }}>
              {!isInstancesLoading && instances && (
                <Stack style={{ marginTop: 20 }}>
                  <InstanceSearchAndFilter instances={instances} onRefine={(data) => setRefinedInstances(data)} />
                  <InstanceList
                    instances={refinedInstances}
                    type={InstanceListType.app}
                    loadingMsg={loadingMessage}
                    loadingInstance={loading}
                    hideInstanceLogButton
                    terminateInstance={terminate}
                    setInstanceSuspension={setSuspension}
                  />
                </Stack>
              )}
            </Stack>
          ),
        };
      return hasInstances(app) ? [detailsTab, myInstancesTab, instancesTab] : [detailsTab];
    },
    onLike = useCallback(async () => {
      try {
        const resApp = await updateLikes(app);
        if (resApp) setApp(resApp);
      } finally {
        setLoadingMessage('');
      }
    }, [app, updateLikes]),
    onPin = useCallback(async () => {
      try {
        const resApp = await updatePin(app);
        if (resApp) setApp(resApp);
      } finally {
        setLoadingMessage('');
      }
    }, [app, updatePin]),
    getFooterForHeader = () => {
      return (
        <>
          <Text
            styles={{
              root: {
                color: theme.palette?.gray500,
                fontSize: FontSizes.textPrimary,
                paddingTop: 8,
              },
            }}
          >
            {app?.owner}
          </Text>
          <AppSocialInfo {...app.preference} onLike={onLike} onPin={onPin} publicMode={publicMode} />
          <AppAttributeBadge app={app} />
          <BasicList
            id="app-tags"
            horizontal
            data={app.tags?.map((tag) => ({
              ...tag,
              style: { borderLeft: `4px solid ${tag.color}` },
            }))}
            filterFn={(tag) => !tag.isCategory && !tag.hidden}
            itemRenderer={(d) => (
              <Item
                styles={itemStylesTag}
                labelField="title"
                titleField="description"
                hasTooltip
                styleField="style"
                emptyTooltip="No description available."
                data={d}
              />
            )}
          />
        </>
      );
    };

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    return () => {
      for (const poller of pollersList) {
        poller.suspend();
      }
    };
  }, [pollersList]);
  useHaicPageTitle(app.title, env?.cloudInstanceName);

  return fatalError ? (
    <Stack styles={{ root: { margin: 16 } }}>
      <ErrorPage {...fatalError} />
    </Stack>
  ) : loadingMessage ? (
    <Loader styles={loaderStylesSpinnerXLarge} label={loadingMessage} />
  ) : (
    <Stack styles={appDetailPageStyles()}>
      <Button
        styles={[buttonStylesLink, buttonStylesLinkBlack]}
        text="Back"
        iconName={IconName.Back}
        onClick={() => (window.history.state ? history.goBack() : history.push(RoutePaths.APPSTORE))}
      />
      <Stack tokens={{ childrenGap: `1.5rem` }}>
        <AppDetailHeader
          actionButton={getActionButton(loading)}
          app={app}
          footer={getFooterForHeader()}
          description={app.description}
          iconLocation={app.iconLocation}
          tags={app.tags}
          title={app.title}
          versions={versions}
        />
        {publicMode ? (
          getDetailsContent()
        ) : (
          <div className="app-detail-content">
            <Pivot
              selectedKey={selectedTabKey}
              items={getTabs()}
              onLinkClick={(item) => {
                if (item) {
                  setSelectedTabKey(item.props.itemKey as TabKey);
                }
              }}
            />
          </div>
        )}
      </Stack>
      {!hiddenAdvancedRunPanel && (
        <RunWithProfilePanel
          app={app}
          profiles={launchProfiles || []}
          onDismiss={() => setHiddenAdvancedRunPanel(true)}
          onRun={async ({ visibility, profile }) => {
            runApp(visibility, profile);
            setHiddenAdvancedRunPanel(true);
          }}
        />
      )}
    </Stack>
  );
}

export default AppDetailPage;
