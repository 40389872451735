// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/h2o_engine.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { BigIntString } from "../../../../runtime";

/**
 * Message representing H2O AI engine.
 * (-- api-linter: core::0164::declarative-friendly-required=disabled
 *     aip.dev/not-precedent: Not adding Undelete method because it requires
 *     changes on generic Engine and H2OEngine as well. Undelete method should
 *     be added later. --)
 *
 * @generated from message ai.h2o.engine.v1.H2OEngine
 */
export type H2OEngine = {
/**
 * H2OEngine resource name.
 *
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * Globally unique identifier of the resource.
 *
 * @generated from field: string uid = 2;
 */
uid?: string;
/**
 * Name of na entity that created the H2OEngine.
 *
 * @generated from field: string creator = 3;
 */
creator?: string;
/**
 * Human-readable name of creator.
 *
 * @generated from field: string creator_display_name = 4;
 */
creatorDisplayName?: string;
/**
 * Optional. The resource name of the H2OEngineProfile that is used by this H2OEngine.
 * Format is `h2oEngineProfiles/*`.
 *
 * @generated from field: string profile = 5;
 */
profile?: string;
/**
 * The current state of the H2OEngine.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngine.State state = 10;
 */
state?: H2OEngine_State;
/**
 * Indicates whether changes to the resource are in progress.
 * If true, current state of the H2OEngine does not match the intended state.
 *
 * @generated from field: bool reconciling = 11;
 */
reconciling?: boolean;
/**
 * Time when the H2OEngine creation was requested.
 *
 * @generated from field: google.protobuf.Timestamp create_time = 21;
 */
createTime?: string;
/**
 * Time when the H2OEngine was deleted.
 *
 * @generated from field: optional google.protobuf.Timestamp delete_time = 22;
 */
deleteTime?: string;
/**
 * Time when the H2OEngine was last updated.
 *
 * @generated from field: optional google.protobuf.Timestamp update_time = 23;
 */
updateTime?: string;
/**
 * The URL address to access the engine API.
 *
 * @generated from field: string api_url = 25;
 */
apiUrl?: string;
/**
 * The URL address to initiate login flow.
 *
 * @generated from field: string login_url = 26;
 */
loginUrl?: string;
/**
 * Additional arbitrary metadata associated with the H2OEngine.
 *
 * Annotations are key/value pairs.
 * The key must:
 * - be 63 characters or less
 * - begin and end with an alphanumeric character ([a-z0-9A-Z])
 * - with dashes (-), underscores (_), dots (.), and alphanumerics between
 * - regex used for validation is: ^[A-Za-z0-9]([-A-Za-z0-9_.]{0,61}[A-Za-z0-9])?$
 *
 * @generated from field: map<string, string> annotations = 100;
 */
annotations?: { [key: string]: string };
/**
 * Human-readable name of the H2OEngine. Must contain at most 63 characters. Does not have to be unique.
 *
 * @generated from field: string display_name = 101;
 */
displayName?: string;
/**
 * H2OEngine version identifier. Version must be specified during engine creation but cannot be changed later.
 * For example: "3.38.0.1" or "latest".
 *
 * @generated from field: string version = 102;
 */
version: string;
/**
 * The amount of CPU units per node requested by this H2OEngine.
 *
 * @generated from field: optional int32 cpu = 103;
 */
cpu?: number;
/**
 * The amount of GPU units per node requested by this H2OEngine.
 *
 * @generated from field: optional int32 gpu = 104;
 */
gpu?: number;
/**
 * The amount of memory in bytes per node requested by this H2OEngine.
 *
 * @generated from field: optional int64 memory_bytes = 105;
 */
memoryBytes?: BigIntString;
/**
 * Maximum time an H2OEngine can be idle. When exceeded, the H2OEngine will terminate.
 *
 * @generated from field: optional google.protobuf.Duration max_idle_duration = 106;
 */
maxIdleDuration?: string | null;
/**
 * Maximum time an H2OEngine can be running. When exceeded, the H2OEngine will terminate.
 *
 * @generated from field: optional google.protobuf.Duration max_running_duration = 107;
 */
maxRunningDuration?: string | null;
/**
 * Current time the H2OEngine is idle.
 *
 * @generated from field: optional google.protobuf.Duration current_idle_duration = 108;
 */
currentIdleDuration?: string;
/**
 * Current time the H2OEngine is running.
 *
 * @generated from field: optional google.protobuf.Duration current_running_duration = 109;
 */
currentRunningDuration?: string;
/**
 * The number of nodes requested by this H2OEngine.
 *
 * @generated from field: optional int32 node_count = 110;
 */
nodeCount?: number;
}
;
/**
 * All possible H2OEngine states.
 *
 * @generated from enum ai.h2o.engine.v1.H2OEngine.State
 */
export enum H2OEngine_State {
/**
 * H2OEngine state is unspecified or unknown.
 *
 * @generated from enum value: STATE_UNSPECIFIED = 0;
 */
UNSPECIFIED = "STATE_UNSPECIFIED",

/**
 * H2OEngine is starting.
 *
 * @generated from enum value: STATE_STARTING = 1;
 */
STARTING = "STATE_STARTING",

/**
 * H2OEngine is running and can be used.
 *
 * @generated from enum value: STATE_RUNNING = 2;
 */
RUNNING = "STATE_RUNNING",

/**
 * H2OEngine is pausing. Deprecated, use STATE_TERMINATING.
 *
 * @generated from enum value: STATE_PAUSING = 3;
 */
PAUSING = "STATE_PAUSING",

/**
 * H2OEngine is paused and CANNOT be resumed again. Deprecated, use STATE_TERMINATED.
 *
 * @generated from enum value: STATE_PAUSED = 4;
 */
PAUSED = "STATE_PAUSED",

/**
 * H2OEngine has failed.
 *
 * @generated from enum value: STATE_FAILED = 5;
 */
FAILED = "STATE_FAILED",

/**
 * H2OEngine is being deleted.
 *
 * @generated from enum value: STATE_DELETING = 6;
 */
DELETING = "STATE_DELETING",

/**
 * H2OEngine is establishing a connection with the AI Engine Manager.
 *
 * @generated from enum value: STATE_CONNECTING = 7;
 */
CONNECTING = "STATE_CONNECTING",

/**
 * H2OEngine is terminating.
 *
 * @generated from enum value: STATE_TERMINATING = 8;
 */
TERMINATING = "STATE_TERMINATING",

/**
 * H2OEngine is terminated. This is a final state.
 *
 * @generated from enum value: STATE_TERMINATED = 9;
 */
TERMINATED = "STATE_TERMINATED",
}
