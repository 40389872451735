// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/internal_dai_version_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { InternalDAIVersion } from "./internal_dai_version_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for creating a new InternalDAIVersion.
 * (-- api-linter: core::0133::request-parent-required=disabled
 *     aip.dev/not-precedent: InternalDAIVersion has no parent (it's a top-level resource) --)
 *
 * @generated from message ai.h2o.engine.v1.CreateInternalDAIVersionRequest
 */
export type CreateInternalDAIVersionRequest = {
/**
 * The InternalDAIVersion resource to create.
 *
 * @generated from field: ai.h2o.engine.v1.InternalDAIVersion internal_dai_version = 2;
 */
internalDaiVersion: InternalDAIVersion;
/**
 * Specify the InternalDAIVersion resource ID, which will become a part of the InternalDAIVersion
 * resource name.
 *
 * It must:
 * - be in semver format (but allowing more segments than only the standard three segments Major.Minor.Patch)
 * - contain max 63 characters
 *
 * Examples: "1.10.3", "1.10.3-alpha", "1.10.3.2", "1.10.3.2-alpha"
 *
 * @generated from field: string internal_dai_version_id = 3;
 */
internalDaiVersionId: string;
}
;
/**
 * Response message for InternalDAIVersionService.CreateInternalDAIVersion.
 *
 * @generated from message ai.h2o.engine.v1.CreateInternalDAIVersionResponse
 */
export type CreateInternalDAIVersionResponse = {
/**
 * InternalDAIVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.InternalDAIVersion internal_dai_version = 1;
 */
internalDaiVersion?: InternalDAIVersion;
}
;
/**
 * Request message for getting a single InternalDAIVersion.
 *
 * @generated from message ai.h2o.engine.v1.GetInternalDAIVersionRequest
 */
export type GetInternalDAIVersionRequest = {
/**
 * InternalDAIVersion resource name.
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for InternalDAIVersionService.GetInternalDAIVersion.
 *
 * @generated from message ai.h2o.engine.v1.GetInternalDAIVersionResponse
 */
export type GetInternalDAIVersionResponse = {
/**
 * InternalDAIVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.InternalDAIVersion internal_dai_version = 1;
 */
internalDaiVersion?: InternalDAIVersion;
}
;
/**
 * Request message for listing InternalDAIVersions.
 * (-- api-linter: core::0133::request-parent-required=disabled
 *     aip.dev/not-precedent: InternalDAIVersion has no parent (it's a top-level resource) --)
 * (-- api-linter: core::0132::request-parent-required=disabled
 *     aip.dev/not-precedent: InternalDAIVersion has no parent (it's a top-level resource) --)
 *
 * @generated from message ai.h2o.engine.v1.ListInternalDAIVersionsRequest
 */
export type ListInternalDAIVersionsRequest = {
/**
 * Maximum number of InternalDAIVersions to return in a response.
 * If unspecified (or set to 0), at most 50 InternalDAIVersions will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListInternalDAIVersionsResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for InternalDAIVersionService.ListInternalDAIVersions.
 *
 * @generated from message ai.h2o.engine.v1.ListInternalDAIVersionsResponse
 */
export type ListInternalDAIVersionsResponse = {
/**
 * Collection of InternalDAIVersions.
 *
 * @generated from field: repeated ai.h2o.engine.v1.InternalDAIVersion internal_dai_versions = 1;
 */
internalDaiVersions?: InternalDAIVersion[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
/**
 * Total number of InternalDAIVersions matched by the List request.
 *
 * @generated from field: int32 total_size = 3;
 */
totalSize?: number;
}
;
/**
 * Request message for updating an existing InternalDAIVersion.
 *
 * @generated from message ai.h2o.engine.v1.UpdateInternalDAIVersionRequest
 */
export type UpdateInternalDAIVersionRequest = {
/**
 * InternalDAIVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.InternalDAIVersion internal_dai_version = 1;
 */
internalDaiVersion: InternalDAIVersion;
/**
 * The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are: {deprecated, image, image_pull_policy, image_pull_secrets,
 * gpu_resource_name, data_directory_storage_class, annotations}.
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 10;
 */
updateMask: string;
}
;
/**
 * Response for InternalDAIVersionService.UpdateInternalDAIVersion.
 *
 * @generated from message ai.h2o.engine.v1.UpdateInternalDAIVersionResponse
 */
export type UpdateInternalDAIVersionResponse = {
/**
 * InternalDAIVersion resource.
 *
 * @generated from field: ai.h2o.engine.v1.InternalDAIVersion internal_dai_version = 1;
 */
internalDaiVersion?: InternalDAIVersion;
}
;
/**
 * Request message for deleting an existing InternalDAIVersion.
 *
 * @generated from message ai.h2o.engine.v1.DeleteInternalDAIVersionRequest
 */
export type DeleteInternalDAIVersionRequest = {
/**
 * InternalDAIVersion resource name.
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for InternalDAIVersionService.DeleteInternalDAIVersion.
 *
 * @generated from message ai.h2o.engine.v1.DeleteInternalDAIVersionResponse
 */
export type DeleteInternalDAIVersionResponse = {
}
;
/**
 * Request message for assigning aliases to InternalDAIVersion.
 *
 * @generated from message ai.h2o.engine.v1.AssignInternalDAIVersionAliasesRequest
 */
export type AssignInternalDAIVersionAliasesRequest = {
/**
 * InternalDAIVersion resource name to which assign the new aliases.
 *
 * @generated from field: string internal_dai_version = 1;
 */
internalDaiVersion: string;
/**
 * New aliases of the InternalH2OVersion.
 *
 * @generated from field: repeated string aliases = 2;
 */
aliases: string[];
}
;
/**
 * Response message for assigning aliases to InternalDAIVersion.
 *
 * @generated from message ai.h2o.engine.v1.AssignInternalDAIVersionAliasesResponse
 */
export type AssignInternalDAIVersionAliasesResponse = {
/**
 * All InternalDAIVersions with updated aliases.
 *
 * @generated from field: repeated ai.h2o.engine.v1.InternalDAIVersion internal_dai_versions = 1;
 */
internalDaiVersions?: InternalDAIVersion[];
}
;
/**
 * Creates a new InternalDAIVersion.
 * (-- api-linter: core::0133::http-uri-parent=disabled
 *     aip.dev/not-precedent: InternalDAIVersion has no parent (it's a top-level resource) --)
 * (-- api-linter: core::0133::method-signature=disabled
 *     aip.dev/not-precedent: InternalDAIVersion has no parent (it's a top-level resource) --)
 *
 * @generated from rpc ai.h2o.engine.v1.InternalDAIVersionService.CreateInternalDAIVersion
 */
export const InternalDAIVersionService_CreateInternalDAIVersion = new RPC<CreateInternalDAIVersionRequest,CreateInternalDAIVersionResponse>("POST", "/v1/internalDAIVersions", "internalDaiVersion");
/**
 * Returns a specific InternalDAIVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.InternalDAIVersionService.GetInternalDAIVersion
 */
export const InternalDAIVersionService_GetInternalDAIVersion = new RPC<GetInternalDAIVersionRequest,GetInternalDAIVersionResponse>("GET", "/v1/{name=internalDAIVersions/*}");
/**
 * Returns a collection of InternalDAIVersions.
 *
 * @generated from rpc ai.h2o.engine.v1.InternalDAIVersionService.ListInternalDAIVersions
 */
export const InternalDAIVersionService_ListInternalDAIVersions = new RPC<ListInternalDAIVersionsRequest,ListInternalDAIVersionsResponse>("GET", "/v1/internalDAIVersions");
/**
 * Updates a InternalDAIVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.InternalDAIVersionService.UpdateInternalDAIVersion
 */
export const InternalDAIVersionService_UpdateInternalDAIVersion = new RPC<UpdateInternalDAIVersionRequest,UpdateInternalDAIVersionResponse>("PATCH", "/v1/{internalDaiVersion.name=internalDAIVersions/*}", "internalDaiVersion");
/**
 * Deletes a InternalDAIVersion.
 *
 * @generated from rpc ai.h2o.engine.v1.InternalDAIVersionService.DeleteInternalDAIVersion
 */
export const InternalDAIVersionService_DeleteInternalDAIVersion = new RPC<DeleteInternalDAIVersionRequest,DeleteInternalDAIVersionResponse>("DELETE", "/v1/{name=internalDAIVersions/*}");
/**
 * Assign new set of aliases to InternalDAIVersion.
 * This will replace existing InternalDAIVersion aliases with the new aliases.
 * If there are other InternalDAIVersions with the same alias that we try to assign,
 * they will be deleted from the other InternalDAIVersions.
 *
 * Example 1 - assign *only* alias latest to another InternalDAIVersion:
 * - InternalDAIVersions: {daiv1.aliases=["latest", "foo"], daiv2.aliases=["bar", "baz"], daiv3.aliases=["goo"]}
 * - AssignAliases(daiv3, aliases=["latest"])
 * - InternalDAIVersions: {daiv1.aliases=["foo"], daiv2.aliases=["bar", "baz"], daiv3.aliases=["latest"]}
 * Note that alias "latest" was deleted from daiv1 and alias "goo" was deleted from daiv3.
 *
 * Example 2 - add alias latest to another InternalDAIVersion:
 * - InternalDAIVersions: {daiv1.aliases=["latest", "foo"], daiv2.aliases=["bar", "baz"], daiv3.aliases=["goo"]}
 * - AssignAliases(daiv3, aliases=["goo", "latest"])
 * - InternalDAIVersions: {daiv1.aliases=["foo"], daiv2.aliases=["bar", "baz"], daiv3.aliases=["goo", "latest"]}
 * Note that alias "latest" was deleted from daiv1 and alias "goo" remained in the daiv3.
 *
 * Example 3 - assign multiple aliases which affects aliases of multiple InternalDAIVersions:
 * - InternalDAIVersions: {daiv1.aliases=["latest", "foo"], daiv2.aliases=["bar", "baz"], daiv3.aliases=["goo"]}
 * - AssignAliases(daiv3, aliases=["latest", "bar"])
 * - InternalDAIVersions: {daiv1.aliases=["foo"], daiv2.aliases=["baz"], daiv3.aliases=["latest", "bar"]}
 * Note that
 * - alias "latest" was deleted from daiv1
 * - alias "latest" was added to daiv3
 * - alias "bar" was deleted from daiv2
 * - alias "goo" was deleted from daiv3
 *
 * (-- api-linter: core::0136::http-uri-suffix=disabled
 *     aip.dev/not-precedent: Google linter incorrectly requires URI suffix "assignInternalDaiVersionAliases" --)
 *
 * @generated from rpc ai.h2o.engine.v1.InternalDAIVersionService.AssignInternalDAIVersionAliases
 */
export const InternalDAIVersionService_AssignInternalDAIVersionAliases = new RPC<AssignInternalDAIVersionAliasesRequest,AssignInternalDAIVersionAliasesResponse>("POST", "/v1/internalDAIVersions:assignInternalDAIVersionAliases");
