// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/engine.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { BigIntString } from "../../../../runtime";

/**
 * @generated from message ai.h2o.engine.v1.Engine
 */
export type Engine = {
/**
 * Engine resource name.
 *
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * Globally unique identifier of the resource.
 *
 * @generated from field: string uid = 2;
 */
uid?: string;
/**
 * Name of an entity that created the Engine.
 *
 * @generated from field: string creator = 3;
 */
creator?: string;
/**
 * Human-readable name of creator.
 *
 * @generated from field: string creator_display_name = 5;
 */
creatorDisplayName?: string;
/**
 * Output only.
 *
 * @generated from field: ai.h2o.engine.v1.Engine.Type type = 4;
 */
type?: Engine_Type;
/**
 * The resource name of the DAIEngineProfile or H2OEngineProfile that is used by this Engine.
 * Format is `daiEngineProfiles/*` or `h2oEngineProfiles/*`.
 *
 * @generated from field: string profile = 6;
 */
profile?: string;
/**
 * The current state of the Engine.
 *
 * @generated from field: ai.h2o.engine.v1.Engine.State state = 10;
 */
state?: Engine_State;
/**
 * Indicates whether changes to the resource are in progress.
 * If true, current state of the Engine does not match the intended state.
 *
 * @generated from field: bool reconciling = 11;
 */
reconciling?: boolean;
/**
 * Indicates whether the Engine disk is currently resizing.
 * It make take hours to complete the resizing process, especially
 * if the disk has been resized recently.
 *
 * @generated from field: bool storage_resizing = 12;
 */
storageResizing?: boolean;
/**
 * Total disk size in bytes as reported by the Engine.
 *
 * @generated from field: optional int64 total_disk_size_bytes = 13;
 */
totalDiskSizeBytes?: BigIntString;
/**
 * Free disk size in bytes as reported by the Engine.
 *
 * @generated from field: optional int64 free_disk_size_bytes = 14;
 */
freeDiskSizeBytes?: BigIntString;
/**
 * Time when the Engine creation was requested.
 *
 * @generated from field: google.protobuf.Timestamp create_time = 21;
 */
createTime?: string;
/**
 * Time when the Engine was deleted.
 *
 * @generated from field: optional google.protobuf.Timestamp delete_time = 22;
 */
deleteTime?: string;
/**
 * Time when the Engine was last updated.
 *
 * @generated from field: optional google.protobuf.Timestamp update_time = 23;
 */
updateTime?: string;
/**
 * Time when the Engine was last resumed (or started for the first time).
 *
 * @generated from field: optional google.protobuf.Timestamp resume_time = 24;
 */
resumeTime?: string;
/**
 * The URL address to initiate login flow.
 *
 * @generated from field: string login_url = 25;
 */
loginUrl?: string;
/**
 * Additional arbitrary metadata associated with the Engine. Annotations are key/value pairs.
 *
 * @generated from field: map<string, string> annotations = 100;
 */
annotations?: { [key: string]: string };
/**
 * Human-readable name of the Engine. Contains at most 63 characters and is not unique.
 *
 * @generated from field: string display_name = 101;
 */
displayName?: string;
/**
 * Engine version identifier.
 * For example: "1.10.1" or "latest".
 *
 * @generated from field: string version = 102;
 */
version?: string;
/**
 * Indicates, whether the used version is deprecated.
 *
 * @generated from field: bool deprecated_version = 112;
 */
deprecatedVersion?: boolean;
/**
 * Indicates, whether the used version is deleted.
 *
 * @generated from field: bool deleted_version = 113;
 */
deletedVersion?: boolean;
/**
 * The amount of CPU units in total requested by this Engine.
 *
 * @generated from field: int32 cpu = 103;
 */
cpu?: number;
/**
 * The amount of GPU units in total requested by this Engine.
 *
 * @generated from field: int32 gpu = 104;
 */
gpu?: number;
/**
 * The amount of memory in bytes requested by this Engine.
 *
 * @generated from field: int64 memory_bytes = 105;
 */
memoryBytes?: BigIntString;
/**
 * The amount of storage in bytes requested by this Engine.
 *
 * @generated from field: int64 storage_bytes = 106;
 */
storageBytes?: BigIntString;
/**
 * Indicates, whether the used version can be upgraded to a later one.
 *
 * @generated from field: bool upgrade_available = 107;
 */
upgradeAvailable?: boolean;
/**
 * Maximum time the engine can be idle.
 *
 * @generated from field: optional google.protobuf.Duration max_idle_duration = 108;
 */
maxIdleDuration?: string;
/**
 * Maximum time the engine can be running.
 *
 * @generated from field: optional google.protobuf.Duration max_running_duration = 109;
 */
maxRunningDuration?: string;
/**
 * Current time the engine is idle.
 *
 * @generated from field: optional google.protobuf.Duration current_idle_duration = 110;
 */
currentIdleDuration?: string;
/**
 * Current time the engine is running.
 *
 * @generated from field: optional google.protobuf.Duration current_running_duration = 111;
 */
currentRunningDuration?: string;
}
;
/**
 * All possible Engine types.
 *
 * @generated from enum ai.h2o.engine.v1.Engine.Type
 */
export enum Engine_Type {
/**
 * Engine type is unspecified or unknown.
 *
 * @generated from enum value: TYPE_UNSPECIFIED = 0;
 */
UNSPECIFIED = "TYPE_UNSPECIFIED",

/**
 * Driverless AI Engine.
 *
 * @generated from enum value: TYPE_DRIVERLESS_AI = 1;
 */
DRIVERLESS_AI = "TYPE_DRIVERLESS_AI",

/**
 * H2O Engine.
 *
 * @generated from enum value: TYPE_H2O = 2;
 */
H2O = "TYPE_H2O",
}
/**
 * All possible Engine states.
 *
 * @generated from enum ai.h2o.engine.v1.Engine.State
 */
export enum Engine_State {
/**
 * Engine state is unspecified or unknown
 *
 * @generated from enum value: STATE_UNSPECIFIED = 0;
 */
UNSPECIFIED = "STATE_UNSPECIFIED",

/**
 * Engine is starting.
 *
 * @generated from enum value: STATE_STARTING = 1;
 */
STARTING = "STATE_STARTING",

/**
 * Engine is running and can be used.
 *
 * @generated from enum value: STATE_RUNNING = 2;
 */
RUNNING = "STATE_RUNNING",

/**
 * Engine is pausing.
 *
 * @generated from enum value: STATE_PAUSING = 3;
 */
PAUSING = "STATE_PAUSING",

/**
 * Engine is paused and can be resumed again.
 *
 * @generated from enum value: STATE_PAUSED = 4;
 */
PAUSED = "STATE_PAUSED",

/**
 * Engine has failed and can be resumed again.
 *
 * @generated from enum value: STATE_FAILED = 5;
 */
FAILED = "STATE_FAILED",

/**
 * Engine is being deleted.
 *
 * @generated from enum value: STATE_DELETING = 6;
 */
DELETING = "STATE_DELETING",

/**
 * Engine is establishing a connection with the AI Engine Manager.
 *
 * @generated from enum value: STATE_CONNECTING = 7;
 */
CONNECTING = "STATE_CONNECTING",

/**
 * Engine is terminating.
 *
 * @generated from enum value: STATE_TERMINATING = 8;
 */
TERMINATING = "STATE_TERMINATING",

/**
 * Engine is terminated. This is a final state.
 *
 * @generated from enum value: STATE_TERMINATED = 9;
 */
TERMINATED = "STATE_TERMINATED",
}
