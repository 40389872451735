// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/securestore/v1/oauth_client_service.proto (package ai.h2o.securestore.v1, syntax proto3)
/* eslint-disable */

import type { OAuthClient } from "./oauth_client_pb";
import { RPC } from "../../../../runtime";

/**
 * (-- api-linter: core::0133::request-id-field=disabled
 *     aip.dev/not-precedent: oauth not o_auth --)
 * (-- api-linter: core::0133::request-unknown-fields=disabled
 *     aip.dev/not-precedent: oauth not o_auth --)
 * (-- api-linter: core::0133::request-parent-required=disabled
 *     aip.dev/not-precedent: oauth not o_auth --)
 * (-- api-linter: core::0133::request-resource-field=disabled
 *     aip.dev/not-precedent: oauth not o_auth --)
 * Request message for `CreateOAuthClient`.
 *
 * @generated from message ai.h2o.securestore.v1.CreateOAuthClientRequest
 */
export type CreateOAuthClientRequest = {
/**
 * Required. The OAuthClient resource to create.
 *
 * @generated from field: ai.h2o.securestore.v1.OAuthClient oauth_client = 1;
 */
oauthClient: OAuthClient;
/**
 * Optional. Specify the OAuthClient ID, which will become a part of the OAuthClient
 * resource name. If not specified, the server will generate one.
 *
 * It must:
 * - contain 1-63 characters
 * - contain only lowercase alphanumeric characters or hyphen ('-')
 * - start with an alphabetic character
 * - end with an alphanumeric character
 *
 * This matches a regular expression: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
 *
 * @generated from field: string oauth_client_id = 2;
 */
oauthClientId?: string;
}
;
/**
 * Response message for `CreateOAuthClient`.
 *
 * @generated from message ai.h2o.securestore.v1.CreateOAuthClientResponse
 */
export type CreateOAuthClientResponse = {
/**
 * The created OAuthClient.
 *
 * @generated from field: ai.h2o.securestore.v1.OAuthClient oauth_client = 1;
 */
oauthClient?: OAuthClient;
}
;
/**
 * Request message for `GetOAuthClient`.
 *
 * @generated from message ai.h2o.securestore.v1.GetOAuthClientRequest
 */
export type GetOAuthClientRequest = {
/**
 * Required. The name of the OAuthClient to retrieve.
 * Format: `oauthClients/{oauthClient}`
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for `GetOAuthClient`.
 *
 * @generated from message ai.h2o.securestore.v1.GetOAuthClientResponse
 */
export type GetOAuthClientResponse = {
/**
 * The requested OAuthClient.
 *
 * @generated from field: ai.h2o.securestore.v1.OAuthClient oauth_client = 1;
 */
oauthClient?: OAuthClient;
}
;
/**
 * (-- api-linter: core::0132::request-parent-required=disabled
 *     aip.dev/not-precedent: oauth not o_auth --)
 * Request message for listing OAuthClients.
 *
 * @generated from message ai.h2o.securestore.v1.ListOAuthClientsRequest
 */
export type ListOAuthClientsRequest = {
/**
 * Optional. Maximum number of OAuthClients to return in a response.
 * If unspecified (or set to 0), at most 50 OAuthClients will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 1;
 */
pageSize?: number;
/**
 * Optional. Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListOAuthClientsResponse.
 *
 * @generated from field: string page_token = 2;
 */
pageToken?: string;
}
;
/**
 * (-- api-linter: core::0158::response-plural-first-field=disabled
 *     aip.dev/not-precedent: It IS plural --)
 * (-- api-linter: core::0132::response-unknown-fields=disabled
 *     aip.dev/not-precedent: oauth not o_auth --)
 * Response message for ListOAuthClients.
 *
 * @generated from message ai.h2o.securestore.v1.ListOAuthClientsResponse
 */
export type ListOAuthClientsResponse = {
/**
 * Collection of OAuthClients.
 *
 * @generated from field: repeated ai.h2o.securestore.v1.OAuthClient oauth_clients = 1;
 */
oauthClients?: OAuthClient[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * (-- api-linter: core::0134::request-resource-field=disabled
 *     aip.dev/not-precedent: oauth not o_auth --)
 * (-- api-linter: core::0134::request-unknown-fields=disabled
 *     aip.dev/not-precedent: oauth not o_auth --)
 * Request message for updating an existing OAuthClient.
 *
 * @generated from message ai.h2o.securestore.v1.UpdateOAuthClientRequest
 */
export type UpdateOAuthClientRequest = {
/**
 * OAuthClient resource.
 *
 * @generated from field: ai.h2o.securestore.v1.OAuthClient oauth_client = 1;
 */
oauthClient: OAuthClient;
/**
 * The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are: {"display_name", "extra_scopes", "refresh_disabled", "login_principal_claim"}.
 * Paths are case sensitive (must match exactly).
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask: string;
}
;
/**
 * Response for UpdateOAuthClient.
 *
 * @generated from message ai.h2o.securestore.v1.UpdateOAuthClientResponse
 */
export type UpdateOAuthClientResponse = {
/**
 * OAuthClient resource.
 *
 * @generated from field: ai.h2o.securestore.v1.OAuthClient oauth_client = 1;
 */
oauthClient?: OAuthClient;
}
;
/**
 * Request message for deleting an existing OAuthClient.
 *
 * @generated from message ai.h2o.securestore.v1.DeleteOAuthClientRequest
 */
export type DeleteOAuthClientRequest = {
/**
 * Required. OAuthClient resource name.
 * Format: oauthClients/{oauthClient}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DeleteOAuthClient.
 *
 * @generated from message ai.h2o.securestore.v1.DeleteOAuthClientResponse
 */
export type DeleteOAuthClientResponse = {
}
;
/**
 * Creates a new OAuthClient.
 *
 * @generated from rpc ai.h2o.securestore.v1.OAuthClientService.CreateOAuthClient
 */
export const OAuthClientService_CreateOAuthClient = new RPC<CreateOAuthClientRequest,CreateOAuthClientResponse>("POST", "/v1/oauthClients", "oauthClient");
/**
 * Returns a specific OAuthClient.
 *
 * @generated from rpc ai.h2o.securestore.v1.OAuthClientService.GetOAuthClient
 */
export const OAuthClientService_GetOAuthClient = new RPC<GetOAuthClientRequest,GetOAuthClientResponse>("GET", "/v1/{name=oauthClients/*}");
/**
 * Returns a collection of OAuthClients.
 *
 * @generated from rpc ai.h2o.securestore.v1.OAuthClientService.ListOAuthClients
 */
export const OAuthClientService_ListOAuthClients = new RPC<ListOAuthClientsRequest,ListOAuthClientsResponse>("GET", "/v1/oauthClients");
/**
 * (-- api-linter: core::0134::method-signature=disabled
 *     aip.dev/not-precedent: oauth not o_auth --)
 * (-- api-linter: core::0134::http-uri-name=disabled
 *     aip.dev/not-precedent: oauth not o_auth --)
 * (-- api-linter: core::0134::http-body=disabled
 *     aip.dev/not-precedent: oauth not o_auth  --)
 * Updates an OAuthClient.
 *
 * @generated from rpc ai.h2o.securestore.v1.OAuthClientService.UpdateOAuthClient
 */
export const OAuthClientService_UpdateOAuthClient = new RPC<UpdateOAuthClientRequest,UpdateOAuthClientResponse>("PATCH", "/v1/{oauthClient.name=oauthClients/*}", "oauthClient");
/**
 * Deletes an OAuthClient and all child resources like Tokens and TokenSources.
 *
 * @generated from rpc ai.h2o.securestore.v1.OAuthClientService.DeleteOAuthClient
 */
export const OAuthClientService_DeleteOAuthClient = new RPC<DeleteOAuthClientRequest,DeleteOAuthClientResponse>("DELETE", "/v1/{name=oauthClients/*}");
