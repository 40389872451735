import { mergeStyleSets } from '@fluentui/react';
import { FontSizes, FontWeights, IH2OTheme, buttonStylesStealth, buttonStylesTextWithIconRight } from '@h2oai/ui-kit';

export const signInButtonStyles = (theme: IH2OTheme) =>
  mergeStyleSets(buttonStylesStealth, buttonStylesTextWithIconRight, {
    root: {
      border: 'none',
      fontSize: FontSizes.small,
      color: theme.semanticColors?.textPrimary,
    },
    rootHovered: {
      color: theme.semanticColors?.textPrimary,
    },
    label: {
      fontWeight: FontWeights.fw400,
    },
    icon: {
      color: theme.semanticColors?.textPrimary,
      fontWeight: FontWeights.fw800,
      fontSize: '0.7em',
    },
  });

export const manageWorkspacesWrapperStyles = (theme: IH2OTheme) => ({
  root: {
    borderTop: `1px solid ${theme.palette?.gray300}`,
    margin: '0 8px -16px',
    padding: '8px 0',
    a: {
      display: 'block',
      padding: '8px 16px',
      margin: '0 -8px',
      textDecoration: 'none',
      color: theme.semanticColors?.textPrimary,
      ':hover': { backgroundColor: theme.palette?.gray200 },
    },
  },
});

export const membersDropdownWrapperStyles = (theme: IH2OTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 332,
    height: 32,
    '.ms-TooltipHost': {
      fontSize: FontSizes.medium,
      lineHeight: 0,
      margin: '0 8px',
      cursor: 'default',
      color: theme.palette?.gray500,
      i: { display: 'block' },
    },
  },
});
