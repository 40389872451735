// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/authorization/v1/role_binding_api.proto (package ai.h2o.authorization.v1, syntax proto3)
/* eslint-disable */

import type { RoleBinding } from "./role_binding_pb";
import { RPC } from "../../../../runtime";

/**
 * @generated from message ai.h2o.authorization.v1.GetRoleBindingRequest
 */
export type GetRoleBindingRequest = {
/**
 * RoleBinding name, e.g. "roleBindings/<UUID>".
 *
 * @generated from field: string name = 1;
 */
name?: string;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.GetRoleBindingResponse
 */
export type GetRoleBindingResponse = {
/**
 * @generated from field: ai.h2o.authorization.v1.RoleBinding role_binding = 1;
 */
roleBinding?: RoleBinding;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.CreateRoleBindingRequest
 */
export type CreateRoleBindingRequest = {
/**
 * @generated from field: ai.h2o.authorization.v1.RoleBinding role_binding = 1;
 */
roleBinding?: RoleBinding;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.CreateRoleBindingResponse
 */
export type CreateRoleBindingResponse = {
/**
 * @generated from field: ai.h2o.authorization.v1.RoleBinding role_binding = 1;
 */
roleBinding?: RoleBinding;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.UpdateRoleBindingRequest
 */
export type UpdateRoleBindingRequest = {
/**
 * @generated from field: ai.h2o.authorization.v1.RoleBinding role_binding = 1;
 */
roleBinding?: RoleBinding;
/**
 * Only the fields specified in update_mask will be updated.
 *
 * Supported fields:
 *   - role
 *   - subject
 *
 * Only supported fields are updated when the mask is empty or "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask?: string;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.UpdateRoleBindingResponse
 */
export type UpdateRoleBindingResponse = {
/**
 * @generated from field: ai.h2o.authorization.v1.RoleBinding role_binding = 1;
 */
roleBinding?: RoleBinding;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.DeleteRoleBindingRequest
 */
export type DeleteRoleBindingRequest = {
/**
 * @generated from field: string name = 1;
 */
name?: string;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.DeleteRoleBindingResponse
 */
export type DeleteRoleBindingResponse = {
}
;
/**
 * @generated from message ai.h2o.authorization.v1.ListRoleBindingsRequest
 */
export type ListRoleBindingsRequest = {
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 * Server can decide to return more or less items then specified.
 *
 * @generated from field: int32 page_size = 1;
 */
pageSize?: number;
/**
 * Use value returned in next_page_token to retrieve next page of results.
 *
 * @generated from field: string page_token = 2;
 */
pageToken?: string;
/**
 * If specified, only role bindings matching the filter will be returned.
 * Two options are supported:
 *   - 'resource = "//test-service/resources/<resource-id>"'
 *   - 'subject = "users/<user-id>"'
 *
 * @generated from field: string filter = 3;
 */
filter?: string;
}
;
/**
 * @generated from message ai.h2o.authorization.v1.ListRoleBindingsResponse
 */
export type ListRoleBindingsResponse = {
/**
 * @generated from field: repeated ai.h2o.authorization.v1.RoleBinding role_bindings = 1;
 */
roleBindings?: RoleBinding[];
/**
 * When not empty server has more results then returned. Use this value as
 * the page_token in the ListRoleBindingsRequest to obtain next page of
 * results.
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * @generated from rpc ai.h2o.authorization.v1.RoleBindingService.GetRoleBinding
 */
export const RoleBindingService_GetRoleBinding = new RPC<GetRoleBindingRequest,GetRoleBindingResponse>("GET", "/v1/{name=roleBindings/*}");
/**
 * @generated from rpc ai.h2o.authorization.v1.RoleBindingService.CreateRoleBinding
 */
export const RoleBindingService_CreateRoleBinding = new RPC<CreateRoleBindingRequest,CreateRoleBindingResponse>("POST", "/v1/roleBindings", "roleBinding");
/**
 * @generated from rpc ai.h2o.authorization.v1.RoleBindingService.UpdateRoleBinding
 */
export const RoleBindingService_UpdateRoleBinding = new RPC<UpdateRoleBindingRequest,UpdateRoleBindingResponse>("PATCH", "/v1/{roleBinding.name=roleBindings/*}", "roleBinding");
/**
 * @generated from rpc ai.h2o.authorization.v1.RoleBindingService.DeleteRoleBinding
 */
export const RoleBindingService_DeleteRoleBinding = new RPC<DeleteRoleBindingRequest,DeleteRoleBindingResponse>("DELETE", "/v1/{name=roleBindings/*}");
/**
 * @generated from rpc ai.h2o.authorization.v1.RoleBindingService.ListRoleBindings
 */
export const RoleBindingService_ListRoleBindings = new RPC<ListRoleBindingsRequest,ListRoleBindingsResponse>("GET", "/v1/roleBindings");
