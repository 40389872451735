// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/orchestrator/v1/dashboard_service.proto (package ai.h2o.orchestrator.v1, syntax proto3)
/* eslint-disable */

import type { DashboardStats } from "./dashboard_stats_pb";
import type { DashboardWorkflowView } from "./dashboard_workflow_view_pb";
import type { DashboardExecutorPoolView } from "./dashboard_executor_pool_view_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for ComputeDashboardStats
 *
 * @generated from message ai.h2o.orchestrator.v1.ComputeDashboardStatsRequest
 */
export type ComputeDashboardStatsRequest = {
/**
 * Required. The resource name of the workspace to calculate statistics for.
 * Format: `workspaces/{workspace}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * When used, server will only calculate statistics for executions that match the filter.
 * Attempts to generally implement AIP-160 (https://aip.dev/160)
 * specification.
 *
 * Supported fields:
 *   * start_time
 *   * end_time
 * Example:
 * start_time >= 2023-12-30T23:59:43Z AND end_time <= 2024-03-20T08:00:00Z
 *
 * @generated from field: string filter = 2;
 */
filter?: string;
}
;
/**
 * Response message for ComputeDashboardStats
 *
 * @generated from message ai.h2o.orchestrator.v1.ComputeDashboardStatsResponse
 */
export type ComputeDashboardStatsResponse = {
/**
 * Calculated stats for executions of workflow.
 *
 * @generated from field: ai.h2o.orchestrator.v1.DashboardStats dashboard_stats = 1;
 */
dashboardStats?: DashboardStats;
}
;
/**
 * Request message for ComputeDashboardWorkflowsView
 *
 * @generated from message ai.h2o.orchestrator.v1.ComputeDashboardWorkflowsViewRequest
 */
export type ComputeDashboardWorkflowsViewRequest = {
/**
 * Required. The resource name of the workspace to compute dashboard for.
 * Format: `workspaces/{workspace}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 * Server can decide to return less items then specified.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Use value returned in next_page_token to retrieve next page of results.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
/**
 * When used, server will only return dashboard workflow executions that match the filter.
 * Attempts to generally implement AIP-160 (https://aip.dev/160)
 * specification.
 *
 * Supported fields:
 *   * state
 *   * start_time
 *   * end_time
 *
 * Examples:
 * state = STATE_FINISHED OR start_time <= 2023-12-30T23:59:43Z
 * start_time >= '2023-12-30T23:59:43Z' AND end_time <= 2024-03-20T08:00:00Z
 * NOT(state = 'STATE_FINISHED' OR start_time <= '2023-12-30T23:59:43Z') AND (state = 'STATE_RUNNING')
 * state = 'STATE_FINISHED' OR start_time <= 2023-12-30T23:59:43Z
 * start_time >= 2023-12-30T23:59:43Z AND end_time <= 2024-03-20T08:00:00Z
 * NOT(state = 'STATE_FINISHED' OR start_time <= 2023-12-30T23:59:43Z) AND (state = 'STATE_RUNNING')
 *
 * @generated from field: string filter = 4;
 */
filter?: string;
}
;
/**
 * (-- api-linter: core::0158::response-plural-first-field=disabled
 *     aip.dev/not-precedent: We want to use appropriate name for views. --)
 * Response message for ComputeDashboardWorkflowsView
 *
 * @generated from message ai.h2o.orchestrator.v1.ComputeDashboardWorkflowsViewResponse
 */
export type ComputeDashboardWorkflowsViewResponse = {
/**
 * Collection of dashboard workflows views.
 *
 * @generated from field: repeated ai.h2o.orchestrator.v1.DashboardWorkflowView dashboard_workflow_views = 1;
 */
dashboardWorkflowViews?: DashboardWorkflowView[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for ComputeDashboardExecutorPoolView
 *
 * @generated from message ai.h2o.orchestrator.v1.ComputeDashboardExecutorPoolViewRequest
 */
export type ComputeDashboardExecutorPoolViewRequest = {
/**
 * Required. The resource name of the workspace to compute dashboard for.
 * Format: `workspaces/{workspace}`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 * Server can decide to return less items then specified.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Use value returned in next_page_token to retrieve next page of results.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * (-- api-linter: core::0158::response-plural-first-field=disabled
 *     aip.dev/not-precedent: We want to use appropriate name for views. --)
 * Response message for ComputeDashboardExecutorPoolView
 *
 * @generated from message ai.h2o.orchestrator.v1.ComputeDashboardExecutorPoolViewResponse
 */
export type ComputeDashboardExecutorPoolViewResponse = {
/**
 * Collection of dashboard executor pool views.
 *
 * @generated from field: repeated ai.h2o.orchestrator.v1.DashboardExecutorPoolView dashboard_executor_pool_views = 1;
 */
dashboardExecutorPoolViews?: DashboardExecutorPoolView[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Returns a stats of workflows
 *
 * @generated from rpc ai.h2o.orchestrator.v1.DashboardService.ComputeDashboardStats
 */
export const DashboardService_ComputeDashboardStats = new RPC<ComputeDashboardStatsRequest,ComputeDashboardStatsResponse>("GET", "/v1/{parent=workspaces/*}/workflows:computeStats");
/**
 * Returns a collection of WorkflowDashboard within a workspace.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.DashboardService.ComputeDashboardWorkflowsView
 */
export const DashboardService_ComputeDashboardWorkflowsView = new RPC<ComputeDashboardWorkflowsViewRequest,ComputeDashboardWorkflowsViewResponse>("GET", "/v1/{parent=workspaces/*}/workflows:compute");
/**
 * Returns a collection of DashboardExecutorPoolView within a workspace.
 *
 * @generated from rpc ai.h2o.orchestrator.v1.DashboardService.ComputeDashboardExecutorPoolView
 */
export const DashboardService_ComputeDashboardExecutorPoolView = new RPC<ComputeDashboardExecutorPoolViewRequest,ComputeDashboardExecutorPoolViewResponse>("GET", "/v1/{parent=workspaces/*}/executorPools:compute");
