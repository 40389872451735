// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/workspace/v1/workspace_api.proto (package ai.h2o.workspace.v1, syntax proto3)
/* eslint-disable */

import type { Workspace } from "./workspace_pb";
import type { BigIntString } from "../../../../runtime";
import { RPC } from "../../../../runtime";

/**
 * @generated from message ai.h2o.workspace.v1.GetWorkspaceRequest
 */
export type GetWorkspaceRequest = {
/**
 * Workspace name, e.g. "workspaces/<UUID>"
 *
 * @generated from field: string name = 1;
 */
name?: string;
}
;
/**
 * @generated from message ai.h2o.workspace.v1.GetWorkspaceResponse
 */
export type GetWorkspaceResponse = {
/**
 * @generated from field: ai.h2o.workspace.v1.Workspace workspace = 1;
 */
workspace?: Workspace;
}
;
/**
 * @generated from message ai.h2o.workspace.v1.CreateWorkspaceRequest
 */
export type CreateWorkspaceRequest = {
/**
 * @generated from field: ai.h2o.workspace.v1.Workspace workspace = 1;
 */
workspace?: Workspace;
/**
 * The ID to use for the Workspace, which will become the final component of
 * the workspaces's resource name.
 *
 * This should be valid text representation of UUID.
 *
 * If no ID is specified, a random ID will be generated.
 *
 * @generated from field: string workspace_id = 2;
 */
workspaceId?: string;
}
;
/**
 * @generated from message ai.h2o.workspace.v1.CreateWorkspaceResponse
 */
export type CreateWorkspaceResponse = {
/**
 * @generated from field: ai.h2o.workspace.v1.Workspace workspace = 1;
 */
workspace?: Workspace;
}
;
/**
 * @generated from message ai.h2o.workspace.v1.UpdateWorkspaceRequest
 */
export type UpdateWorkspaceRequest = {
/**
 * @generated from field: ai.h2o.workspace.v1.Workspace workspace = 1;
 */
workspace?: Workspace;
/**
 * Only the fields specified in update_mask will be updated.
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask?: string;
}
;
/**
 * @generated from message ai.h2o.workspace.v1.UpdateWorkspaceResponse
 */
export type UpdateWorkspaceResponse = {
/**
 * @generated from field: ai.h2o.workspace.v1.Workspace workspace = 1;
 */
workspace?: Workspace;
}
;
/**
 * @generated from message ai.h2o.workspace.v1.DeleteWorkspaceRequest
 */
export type DeleteWorkspaceRequest = {
/**
 * @generated from field: string name = 1;
 */
name?: string;
}
;
/**
 * @generated from message ai.h2o.workspace.v1.DeleteWorkspaceResponse
 */
export type DeleteWorkspaceResponse = {
/**
 * @generated from field: ai.h2o.workspace.v1.Workspace workspace = 1;
 */
workspace?: Workspace;
}
;
/**
 * @generated from message ai.h2o.workspace.v1.UndeleteWorkspaceRequest
 */
export type UndeleteWorkspaceRequest = {
/**
 * @generated from field: string name = 1;
 */
name?: string;
}
;
/**
 * @generated from message ai.h2o.workspace.v1.UndeleteWorkspaceResponse
 */
export type UndeleteWorkspaceResponse = {
/**
 * @generated from field: ai.h2o.workspace.v1.Workspace workspace = 1;
 */
workspace?: Workspace;
}
;
/**
 * @generated from message ai.h2o.workspace.v1.ListWorkspacesRequest
 */
export type ListWorkspacesRequest = {
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 * Server can decide to return more or less items then specified.
 *
 * @generated from field: int32 page_size = 1;
 */
pageSize?: number;
/**
 * Use value returned in next_page_token to retrieve next page of results.
 *
 * @generated from field: string page_token = 2;
 */
pageToken?: string;
/**
 * When used, server will only return workspaces that match the filter.
 * Attempts to generally implement AIP-160 (https://aip.dev/160)
 * specification.
 * See https://github.com/h2oai/authz-server#filtering for specific supported
 * features.
 *
 * Supported fields:
 *   * display_name
 *   * description
 *   * create_time
 *   * update_time
 *   * creator
 *   * updater
 *   * personal_workspace_subject
 *   * annotations
 *
 * Fields that are use for literal string matching:
 *  * display_name
 *  * description
 *
 * @generated from field: string filter = 4;
 */
filter?: string;
/**
 * When used, server will return workspaces ordered by the specified fields.
 * Attempts to implement AIP-132 (https://aip.dev/132#ordering).
 * Supported fields are the ones supported by filter except for annotations.
 *
 * @generated from field: string order_by = 5;
 */
orderBy?: string;
}
;
/**
 * @generated from message ai.h2o.workspace.v1.ListWorkspacesResponse
 */
export type ListWorkspacesResponse = {
/**
 * @generated from field: repeated ai.h2o.workspace.v1.Workspace workspaces = 1;
 */
workspaces?: Workspace[];
/**
 * When not empty server has more results then returned. Use this value as
 * the page_token in the ListWorkspacesRequest to obtain next page of results.
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * @generated from message ai.h2o.workspace.v1.CountWorkspacesRequest
 */
export type CountWorkspacesRequest = {
/**
 * When used, server will count only workspaces that match the filter.
 *
 * See ListWorkspacesRequest.filter for more details.
 *
 * @generated from field: string filter = 4;
 */
filter?: string;
}
;
/**
 * @generated from message ai.h2o.workspace.v1.CountWorkspacesResponse
 */
export type CountWorkspacesResponse = {
/**
 * @generated from field: int64 total_size = 1;
 */
totalSize?: BigIntString;
}
;
/**
 * @generated from rpc ai.h2o.workspace.v1.WorkspaceService.GetWorkspace
 */
export const WorkspaceService_GetWorkspace = new RPC<GetWorkspaceRequest,GetWorkspaceResponse>("GET", "/v1/{name=workspaces/*}");
/**
 * @generated from rpc ai.h2o.workspace.v1.WorkspaceService.CreateWorkspace
 */
export const WorkspaceService_CreateWorkspace = new RPC<CreateWorkspaceRequest,CreateWorkspaceResponse>("POST", "/v1/workspaces", "workspace");
/**
 * @generated from rpc ai.h2o.workspace.v1.WorkspaceService.UpdateWorkspace
 */
export const WorkspaceService_UpdateWorkspace = new RPC<UpdateWorkspaceRequest,UpdateWorkspaceResponse>("PATCH", "/v1/{workspace.name=workspaces/*}", "workspace");
/**
 * @generated from rpc ai.h2o.workspace.v1.WorkspaceService.DeleteWorkspace
 */
export const WorkspaceService_DeleteWorkspace = new RPC<DeleteWorkspaceRequest,DeleteWorkspaceResponse>("DELETE", "/v1/{name=workspaces/*}");
/**
 * @generated from rpc ai.h2o.workspace.v1.WorkspaceService.ListWorkspaces
 */
export const WorkspaceService_ListWorkspaces = new RPC<ListWorkspacesRequest,ListWorkspacesResponse>("GET", "/v1/workspaces");
/**
 * @generated from rpc ai.h2o.workspace.v1.WorkspaceService.UndeleteWorkspace
 */
export const WorkspaceService_UndeleteWorkspace = new RPC<UndeleteWorkspaceRequest,UndeleteWorkspaceResponse>("POST", "/v1/{name=workspaces/*}:undelete");
/**
 * Returns number of workspaces that match the filter.
 *
 * WARNING: This method call is expensive and should be used sparingly.
 * Client-side caching is recommended to optimize performance.
 *
 * @generated from rpc ai.h2o.workspace.v1.WorkspaceService.CountWorkspaces
 */
export const WorkspaceService_CountWorkspaces = new RPC<CountWorkspacesRequest,CountWorkspacesResponse>("GET", "/v1/workspaces:count");
