import { IDropdownOption, MessageBarType } from '@fluentui/react';
import { IMessageBarMessage, IconName } from '@h2oai/ui-kit';

import {
  App,
  AppInstance,
  AppInstance_Status,
  AppInstance_Visibility,
  App_Visibility,
  TagAssignment,
} from '../ai.h2o.cloud.appstore';
import { Engine } from '../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { AppCategory } from '../pages/AppStorePage/AppStorePage.models';
import { BytesString } from '../secure-store/gen/runtime';
import { OTHER_CATEGORY } from './models';

export enum InstancePauseResumeOpEnum {
  Pause = 'pause',
  Postpone = 'postpone',
  Resume = 'resume',
}

export type InstancePauseResumeOp = {
  suspend: boolean;
  description: string;
  upperDescription: string;
  completedDescription: string;
  openInstance: boolean;
};

export const instanceButtonTestIdSuffixes = {
  pause: '--pause-instance-button',
  resume: '--resume-instance-button',
  visit: '--visit-instance-button',
  viewLogs: '--view-instance-logs-button',
  edit: '--edit-instance-button',
  terminate: '--terminate-instance-button',
  postpone: '--postpone-instance-button',
  details: '--app-details-instance-button',
  unavailable: '--unavailable-instance-button',
  inprogress: '--in-progess-instance-button',
};

export const getInstanceButtonTestId = (instance: AppInstance, suffix: string) =>
  `${instance?.appDetails?.name || 'app-name-unavailable'}${suffix}`;

export const engineButtonTestIdSuffixes = {
  pause: '--pause-engine-button',
  resume: '--resume-engine-button',
  visit: '--visit-engine-button',
};

export const getEngineButtonTestId = (engine: Engine, suffix: string) =>
  `${engine?.displayName || 'engine-name-unavailable'}${suffix}`;

export const InstancePauseResumeMap = new Map<InstancePauseResumeOpEnum, InstancePauseResumeOp>([
  [
    InstancePauseResumeOpEnum.Pause,
    {
      suspend: true,
      description: 'pause',
      upperDescription: 'Pause',
      completedDescription: 'Paused',
      openInstance: false,
    },
  ],
  [
    InstancePauseResumeOpEnum.Resume,
    {
      suspend: false,
      description: 'resume',
      upperDescription: 'Resume',
      completedDescription: 'Resumed',
      openInstance: true,
    },
  ],
  [
    InstancePauseResumeOpEnum.Postpone,
    {
      suspend: false,
      description: 'postpone auto-pause for',
      upperDescription: 'Postpone Auto-Pause for',
      completedDescription: 'Postponed Auto-Pause for',
      openInstance: false,
    },
  ],
]);

export const CANCEL_WEBSOCKET_DOWNLOAD = 'CANCEL_WEBSOCKET_DOWNLOAD';
const UNKNOWN = `unknown`;

const envURL = process.env.REACT_APP_API_PATH || '';

export async function fetchAppStoreVersion() {
  const response = await fetch(`${envURL}/d/version`);
  const parsedResponse = await response.text(); // this endpoint returns a ReadableStream, not JSON
  return parsedResponse;
}

export const handleErrMsg = (msg: string | any) => {
    if (typeof msg !== `string`) return `Unknown error`;
    // Reverse proxy error.
    if (msg === 'Unexpected token < in JSON at position 0') return 'Unavailable';
    return msg;
  },
  formatError = (error: any) => {
    const errorCode = error?.code;
    const errorMessage = error?.message || error?.reason || error;
    return errorCode ? `(${errorCode}) ${errorMessage}` : errorMessage;
  },
  appVisibilityMap: { [key in App_Visibility]: string } = {
    [App_Visibility.ALL_USERS]: 'All Users',
    [App_Visibility.PRIVATE]: 'Private',
    [App_Visibility.PUBLIC]: 'Public',
    [App_Visibility.VISIBILITY_UNSPECIFIED]: 'Unspecified',
  },
  instanceVisibilityMap: { [key in AppInstance_Visibility]: string } = {
    [AppInstance_Visibility.ALL_USERS]: 'All Users',
    [AppInstance_Visibility.PRIVATE]: 'Private',
    [AppInstance_Visibility.VISIBILITY_UNSPECIFIED]: 'Unspecified',
    [AppInstance_Visibility.PUBLIC]: 'Public',
  },
  instanceVisibilityIconMap: { [key in AppInstance_Visibility]: string } = {
    [AppInstance_Visibility.ALL_USERS]: IconName.Group,
    [AppInstance_Visibility.PRIVATE]: IconName.Lock,
    [AppInstance_Visibility.VISIBILITY_UNSPECIFIED]: IconName.Unknown,
    [AppInstance_Visibility.PUBLIC]: IconName.Unknown,
  },
  instanceStatusMap: { [key in AppInstance_Status]: string } = {
    [AppInstance_Status.DEPLOYED]: 'Deployed',
    [AppInstance_Status.FAILED]: 'Failed',
    [AppInstance_Status.PENDING]: 'Pending',
    [AppInstance_Status.STATUS_UNKNOWN]: 'Unknown',
    [AppInstance_Status.STATUS_UNSPECIFIED]: 'Unspecified',
    [AppInstance_Status.SUSPENDED]: 'Paused',
  },
  appVisibilityIconMap: { [key in App_Visibility]: string } = {
    [App_Visibility.ALL_USERS]: IconName.Group,
    [App_Visibility.PRIVATE]: IconName.Lock,
    [App_Visibility.PUBLIC]: IconName.Unknown,
    [App_Visibility.VISIBILITY_UNSPECIFIED]: IconName.Unknown,
  },
  visibilityOptions: IDropdownOption[] = Object.keys(appVisibilityMap).map((key: string) => ({
    key,
    text: appVisibilityMap[key],
  })),
  specifiedVisibilityOptions: IDropdownOption[] = visibilityOptions.filter(
    (option) => option.key !== App_Visibility.VISIBILITY_UNSPECIFIED
  ),
  remapUnspecified = (str: string) => (str === 'Unspecified' ? 'Other' : str),
  isCategoryTag = (tag: Pick<TagAssignment, `isCategory` | `hidden`>) => tag.isCategory && !tag.hidden,
  categoriesFromApp = (app: App) =>
    app.tags
      ?.filter(isCategoryTag)
      .map(({ name }) => name)
      .join(', ') || OTHER_CATEGORY,
  categoryTitleFromName = (name: string, categories: AppCategory[]): string => {
    const cat = categories!.find((cat) => cat?.name === name);
    return cat?.title || name || '';
  },
  getInstanceAppTitleVersion = (appInstance: AppInstance | undefined | null) => {
    const appDetails = appInstance?.appDetails;
    return `${appDetails?.title || UNKNOWN} v${appDetails?.version || UNKNOWN}`;
  },
  redirect = (href: string, sameTab = false) => {
    const link = document.createElement('a');
    link.href = href;
    if (!sameTab) link.target = '_blank';
    link.click();
    link.remove();
  },
  matchesSearchString = (values: string[], searchString: string): Boolean => {
    return values.some((v) => v?.toLowerCase().includes(searchString.toLowerCase()));
  },
  getToastMessage = (
    message = '',
    groupId = '',
    messageBarType: MessageBarType = MessageBarType.error
  ): IMessageBarMessage => {
    return {
      groupId,
      messageBarType,
      message,
    };
  },
  getToastErrorMessage = (message = '', groupId = ''): IMessageBarMessage =>
    getToastMessage(message, groupId, MessageBarType.error),
  getAppIconImagePath = (fileName: string) => (fileName ? `/v1/asset/${fileName}` : '/logo512.png'),
  getUriHost = (uri: string): string => {
    if (!uri.startsWith('http://') && !uri.startsWith('https://')) {
      if (uri.startsWith('//')) {
        return uri.slice(2);
      }
      return uri;
    }
    let url: URL | undefined;
    try {
      url = new URL(uri);
    } catch (error: any) {
      return uri;
    }
    if (url) {
      return url.host;
    } else {
      return uri;
    }
  },
  getUrlFromUri = (uri?: string): string | undefined => {
    if (!uri) {
      return;
    }
    if (uri.startsWith('http://') || uri.startsWith('https://')) {
      return uri;
    }
    if (uri.startsWith('//')) {
      return `${window.location.protocol}${uri}`;
    }
    return `${window.location.protocol}//${uri}`;
  };

export const listIconSize = 64;

export function useFormAttributes() {
  const inputContainerProps = { styles: { root: { width: 450 } } },
    formRowHeight = 137,
    formRowProps = {
      horizontal: true,
      styles: { root: { width: 666, height: formRowHeight } },
      tokens: { childrenGap: 33 },
    },
    sliderFormRowProps = {
      horizontal: true,
      styles: { root: { width: 666, height: 77 } },
      tokens: { childrenGap: 15 },
    },
    inputRowProps = {
      horizontal: true,
      styles: { root: { width: 666, height: 77 } },
      tokens: { childrenGap: 15 },
    },
    singleRowInputRowProps = {
      horizontal: true,
      styles: { root: { width: 666, height: 40 } },
      tokens: { childrenGap: 15 },
    },
    sliderContainerProps = { styles: { root: { width: 450 } } };
  return {
    formRowHeight,
    formRowProps,
    inputContainerProps,
    inputRowProps,
    singleRowInputRowProps,
    sliderContainerProps,
    sliderFormRowProps,
  };
}

export const isNotEmpty = (value: any) => {
  if (typeof value === 'number' && !Number.isNaN(value)) {
    return true;
  } else {
    return !!value;
  }
};

export const getNumericValue = (val: string | number | null | undefined): number | undefined => {
  const num = Number(val);
  if (isNaN(num)) {
    return undefined;
  }
  return num;
};

export const validateId = (id: string | undefined | null): boolean =>
  Boolean(id) && /^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$/.test(id!);

type Item = {
  [key: string]: any;
};

type ResultObject = {
  [key: string]: Item;
};

export const arrayToObject = (arr: Item[], keyField: string): ResultObject => {
  return arr.reduce((acc, item) => {
    const key = item[keyField];
    if (key !== undefined) acc[key] = item;

    return acc;
  }, {} as ResultObject);
};

// "MM/DD/YYYY HH:mm:ss AM/PM"
export function formatDateWithTime(dateString: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const formattedDate = formatter.format(dateString);

  return formattedDate.replace(',', '');
}

export const encodeBytes = (data: string): BytesString => {
  const base64Encoded = btoa(data); // btoa encodes to Base64
  return base64Encoded as BytesString;
};
