import { IStackItemStyles, MessageBarType, PanelType, Separator, Stack } from '@fluentui/react';
import { Button, Panel, TextField, buttonStylesPrimary, useToast } from '@h2oai/ui-kit';
import React, { useCallback, useEffect, useState } from 'react';

import { RoleBinding } from '../../../../authz/gen/ai/h2o/authorization/v1/role_binding_pb';
import { useAuthzService } from '../../../../authz/hooks';
import { useUser } from '../../../../utils/hooks';
import { formatError } from '../../../../utils/utils';
import { useRoles } from '../../../Orchestrator/RoleProvider';
import { useWorkspaces } from '../../../Orchestrator/WorkspaceProvider';
import { containsObjectByKey } from '../../utils';
import { ManageMembers } from '../ManageMembers/ManageMembers';
import { AddEditWorkspacePanelProps } from './types';

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: '8px 0',
    flexGrow: 1,
  },
};

export const AddEditWorkspacePanel = (props: AddEditWorkspacePanelProps) => {
  const { onDismiss, isOpen, workspaceForEdit, onWorkspaceSave } = props;

  const authzService = useAuthzService();
  const { fetchWorkspaces } = useWorkspaces();
  const { roleBindings = [], fetchRoleBindings, createRoleBinding, deleteRoleBinding } = useRoles();
  const user = useUser();
  const { addToast } = useToast();

  const [displayName, setDisplayName] = useState('');
  const [description, setDescription] = useState('');
  const [listedRoles, setListedRoles] = useState<RoleBinding[]>([]);
  const [createRolesBuffer, setCreateRolesBuffer] = useState<RoleBinding[]>([]);
  const [deleteRolesBuffer, setDeleteRolesBuffer] = useState<RoleBinding[]>([]);

  const [showValidation, setShowValidation] = React.useState(false);
  const handleWorkspaceSave = useCallback(async () => {
    if (!displayName) {
      setShowValidation(true);
      return;
    }

    const workspacePayload = { workspace: { displayName, description } };
    let workspaceName = workspaceForEdit?.name;

    try {
      if (!workspaceName) {
        const response = await authzService.createWorkspace(workspacePayload);
        workspaceName = response.workspace?.name;
      } else {
        await authzService.updateWorkspace({
          workspace: { ...workspacePayload.workspace, name: workspaceForEdit?.name },
        });
      }

      if (deleteRolesBuffer) {
        await Promise.all(
          deleteRolesBuffer.map(async (binding) => {
            return deleteRoleBinding(binding);
          })
        );
      }

      if (createRolesBuffer) {
        await Promise.all(
          createRolesBuffer.map(async (binding) => {
            return createRoleBinding(binding.role || '', binding.subject || '', workspaceName);
          })
        );
      }

      fetchWorkspaces();
      onWorkspaceSave();
      addToast({
        messageBarType: MessageBarType.success,
        message: 'Workspace saved successfully.',
      });
    } catch (err) {
      const message = `Failed to save workspace: ${formatError(err)}`;
      console.error(message);
      addToast({ messageBarType: MessageBarType.error, message });
    }
  }, [
    displayName,
    description,
    workspaceForEdit,
    deleteRolesBuffer,
    createRolesBuffer,
    fetchWorkspaces,
    onWorkspaceSave,
    addToast,
    authzService,
    setShowValidation,
  ]);
  const handleMembersChange = useCallback(
    (roleBindingOptions: RoleBinding[], isCreate?: boolean) => {
      const filterOutRoles = (role: RoleBinding) => {
        return !roleBindingOptions.some((item) => item.role === role.role && item.subject === role.subject);
      };

      if (isCreate) {
        const isInDeleteList = containsObjectByKey(deleteRolesBuffer, roleBindingOptions, 'role', 'subject');

        if (!isInDeleteList) {
          // New role binding
          setCreateRolesBuffer((prev) => [...prev, ...roleBindingOptions]);
        } else {
          // Undo remove role binding
          // Use case:
          // - Deleted a role, but didn't save the change
          // - Added the role back
          setDeleteRolesBuffer((prev) => [...prev.filter(filterOutRoles)]);
        }
      } else {
        const isInCreateList = containsObjectByKey(createRolesBuffer, roleBindingOptions, 'role', 'subject');

        if (!isInCreateList) {
          // Delete an existing role binding
          setDeleteRolesBuffer((prev) => [...prev, ...roleBindingOptions]);
          setListedRoles((prev) => [...prev.filter(filterOutRoles)]);
        } else {
          // Remove an added but unsaved role binding
          setCreateRolesBuffer((prev) => [...prev.filter(filterOutRoles)]);
        }
      }
    },
    [createRolesBuffer, deleteRolesBuffer, listedRoles, setCreateRolesBuffer, setDeleteRolesBuffer, setListedRoles]
  );

  useEffect(() => {
    if (roleBindings.length > 0) {
      setListedRoles(
        [...roleBindings].filter((binding) => {
          return !binding.subject?.includes(user.id);
        })
      );
    }
  }, [roleBindings, user]);

  useEffect(() => {
    if (workspaceForEdit) fetchRoleBindings(workspaceForEdit.name || '');
  }, [workspaceForEdit]);

  useEffect(() => {
    if (workspaceForEdit) {
      setDisplayName(workspaceForEdit.displayName || '');
      setDescription(workspaceForEdit.description || '');
    }
  }, [workspaceForEdit]);

  useEffect(() => {
    if (!isOpen) {
      setDisplayName('');
      setDescription('');
      setListedRoles([]);
      setCreateRolesBuffer([]);
      setDeleteRolesBuffer([]);
    }
  }, [isOpen, setDisplayName, setDescription, setListedRoles, setCreateRolesBuffer, setDeleteRolesBuffer]);

  return (
    <Panel
      isLightDismiss
      customWidth="700px"
      headerText="Add New Workspace"
      isFooterAtBottom
      isOpen={isOpen}
      onDismiss={onDismiss}
      type={PanelType.custom}
      onRenderFooterContent={() => (
        <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="space-between">
          <Button text="Save" styles={buttonStylesPrimary} onClick={handleWorkspaceSave} role="submit" />
          <Button text="Close" onClick={onDismiss} />
        </Stack>
      )}
    >
      <Stack>
        <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="stretch">
          <Stack styles={stackItemStyles}>
            <TextField
              label="Display Name"
              value={displayName}
              onChange={(_e, value) => setDisplayName(value || '')}
              required
              errorMessage={showValidation && !displayName ? 'Please enter a workspace name' : undefined}
            />
          </Stack>
          <Stack styles={stackItemStyles}>
            <TextField label="Description" value={description} onChange={(_e, value) => setDescription(value || '')} />
          </Stack>
        </Stack>

        <Separator styles={{ root: { margin: '16px 0' } }}>Members and permissions</Separator>

        <Stack>
          <ManageMembers
            prepopulatedRoleBindings={[...listedRoles, ...createRolesBuffer]}
            onMembersChange={handleMembersChange}
          />
        </Stack>
      </Stack>
    </Panel>
  );
};
