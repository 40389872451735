import { useEffect } from 'react';

import { SecureStore } from '../services/api';
import { useCloudPlatformDiscovery } from '../utils/hooks';

export const useSecureStoreService = () => {
  const cloudPlatformDiscovery = useCloudPlatformDiscovery();
  const secureStoreUrl = cloudPlatformDiscovery?.secureStore || '';

  useEffect(() => {
    SecureStore.setBasePath(secureStoreUrl);
  }, [secureStoreUrl]);

  return SecureStore;
};
