import { IDropdownOption } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ConfirmDialog, Dropdown, FontSizes, Loader, loaderStylesSpinnerXLarge, useToast } from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Alias,
  AppInstance,
  AppInstance_Visibility,
  AssignAliasRequest,
  DeleteAliasRequest,
  PromoteAliasToPrimaryRequest,
} from '../ai.h2o.cloud.appstore';
import { AliasConfigPanel, IAliasConfigPanelProps } from '../components/AliasConfigPanel/AliasConfigPanel';
import FilterPanel, { FilterPanelInput } from '../components/FilterPanel/FilterPanel';
import ListPage from '../components/ListPages/ListPage';
import { ManagedAliasList } from '../components/ManagedAliasList/ManagedAliasList';
import { AdminAliasService, AdminAppService } from '../services/api';
import { useCategory, useRefineData } from '../utils/hooks';
import { getToastErrorMessage } from '../utils/utils';
import { RoutePaths } from './Routes';

enum Assignment {
  All = 'All',
  Assigned = 'Assigned',
  Unassigned = 'Unassigned',
}

const assignmentOptions: IDropdownOption[] = [
  { key: Assignment.All, text: Assignment.All },
  { key: Assignment.Assigned, text: Assignment.Assigned },
  { key: Assignment.Unassigned, text: Assignment.Unassigned },
];

const filter = (items: Alias[] = [], alias = '', assignment: Assignment | string = Assignment.All): Alias[] => {
  alias = alias.trim().toLowerCase();
  return items.slice().filter((d) => {
    const hasAlias = alias ? d.alias.toLowerCase().includes(alias) : true;
    return (
      hasAlias &&
      (assignment === Assignment.All ||
        (assignment === Assignment.Assigned && d.instanceId) ||
        (assignment === Assignment.Unassigned && !d.instanceId))
    );
  });
};

function AdminManageAliasPage() {
  const [aliases, setAliases] = useState<Alias[]>([]),
    [instances, setInstances] = useState<AppInstance[]>([]),
    [loading, setLoading] = useState(true),
    [filterPanelOpen, { setTrue: openFilterPanel, setFalse: dismissFilterPanel }] = useBoolean(false),
    refFilterText = useRef<string>(),
    [hideDeleteConfirmDialog, { toggle: toggleHideDeleteConfirmDialog }] = useBoolean(true),
    [hideUnassignConfirmDialog, { toggle: toggleHideUnassignConfirmDialog }] = useBoolean(true),
    [hidePromoteConfirmDialog, { toggle: toggleHidePromoteConfirmDialog }] = useBoolean(true),
    [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false),
    [aliasConfigPanelProps, setAliasConfigPanelProps] = useState<IAliasConfigPanelProps | undefined>(),
    { addToast } = useToast(),
    toastError = (message: string) => {
      addToast(getToastErrorMessage(message, 'AdminManageAliasPage'));
    },
    refSelectedAlias = useRef<Alias>(),
    { getCategory, setCategory } = useCategory(),
    {
      data: refinedItems,
      searchKey,
      setSearchKey,
      filterMap,
      setFilter,
    } = useRefineData({
      data: aliases,
      onSearch: useCallback((searchNewKey, data: Alias[]) => {
        refFilterText.current = searchNewKey;
        if (searchNewKey === '') return data;
        return filter(data, searchNewKey);
      }, []),
      onFilter: useCallback((filterSet, data: Alias[]) => {
        const filterNewKey = getCategory(filterSet);
        if (filterNewKey === '') return data;
        return filter(data, refFilterText.current, filterNewKey);
      }, []),
    }),
    fetchInstances = useCallback(async () => {
      setLoading(true);
      try {
        const resp = await AdminAppService.listAppInstances({
          appId: '',
          includeAppDetails: true,
          visibility: AppInstance_Visibility.VISIBILITY_UNSPECIFIED,
          allUsers: true,
          allWorkspaces: false,
          parent: '',
        });
        setInstances(resp.instances);
      } catch (error) {
        if (error instanceof Error) {
          toastError(`An error has occurred while loading instances`);
        }
      }
      setLoading(false);
    }, [addToast]),
    loadAliases = useCallback(async () => {
      setLoading(true);
      try {
        const { aliases: data } = await AdminAliasService.listAliases({ instanceId: '', parent: '' });
        setAliases(data);
      } catch (error) {
        if (error instanceof Error) {
          toastError(`An error has occurred while loading aliases`);
        }
      }
      setLoading(false);
    }, [addToast]),
    promoteAlias = useCallback(
      async (alias: Alias) => {
        setLoading(true);
        try {
          // PromoteAliasToPrimaryRequest has `aliasName`, but if the request has it. the response will have `400` error.
          await AdminAliasService.promoteAliasToPrimary({ id: alias.id } as PromoteAliasToPrimaryRequest);
          await loadAliases();
        } catch (error) {
          if (error instanceof Error) {
            toastError(`An error has occurred while promote an alias(${alias.alias}) to the primary`);
          }
        }
        setLoading(false);
      },
      [addToast, loadAliases]
    ),
    addAlias = useCallback(
      async (instanceId: string, primary: boolean, alias: string) => {
        setLoading(true);
        try {
          // alias has `createTime` and `updateTime`, but if the request has them. the response will have `400` error.
          await AdminAliasService.createAlias({
            alias: { instanceId, primary, alias } as Alias,
          });
          await loadAliases();
        } catch (error) {
          if (error instanceof Error) {
            toastError(`An error has occurred while creating an alias(${alias})`);
          }
        }
        setLoading(false);
      },
      [addToast, loadAliases]
    ),
    deleteAlias = useCallback(
      async (alias: Alias) => {
        setLoading(true);
        try {
          // DeleteAliasRequest has `aliasName`, but if the request has it. the response will have `400` error.
          await AdminAliasService.deleteAlias({ id: alias.id } as DeleteAliasRequest);
          await loadAliases();
        } catch (error) {
          if (error instanceof Error) {
            toastError(`An error has occurred while deleting an alias(${alias.alias})`);
          }
        }
        setLoading(false);
      },
      [addToast, loadAliases]
    ),
    assignAlias = useCallback(
      async (alias: Alias, instanceId = '', parent = '') => {
        setLoading(true);
        try {
          // AssignAliasRequest has `aliasName`, but if the request has it. the response will have `400` error.
          await AdminAliasService.assignAlias({ id: alias.id, instanceId, parent } as AssignAliasRequest);
          await loadAliases();
        } catch (error) {
          if (error instanceof Error) {
            toastError(
              instanceId
                ? `An error has occurred while assigning an alias(${alias.alias}) to an instance(${instanceId})`
                : `An error has occurred while unassigning an alias(${alias.alias})`
            );
          }
        }
        setLoading(false);
      },
      [addToast, loadAliases]
    ),
    unassignAlias = useCallback(
      (alias: Alias) => {
        assignAlias(alias);
      },
      [assignAlias]
    ),
    openConfigPanel = useCallback(
      async (alias?: Alias) => {
        refSelectedAlias.current = alias;
        openPanel();
        const panelProps: IAliasConfigPanelProps = {
          alias,
          instances,
          onClose: dismissPanel,
          onAssign: closePanel,
        };
        setAliasConfigPanelProps(panelProps);
      },
      [setAliasConfigPanelProps, openPanel, dismissPanel]
    ),
    closePanel = useCallback(async (instance?: AppInstance, primary?: boolean, alias?: string) => {
      dismissPanel();
      if (alias) {
        await addAlias(instance?.id || '', primary || false, alias);
      }
      const selectedAlias = refSelectedAlias.current;
      if (instance && selectedAlias) {
        await assignAlias(selectedAlias, instance.id);
        if (primary) {
          await promoteAlias(selectedAlias);
        }
      }
    }, []),
    deleteAliasConfirmDialog = useCallback((alias: Alias) => {
      refSelectedAlias.current = alias;
      toggleHideDeleteConfirmDialog();
    }, []),
    unassignAliasConfirmDialog = useCallback((alias: Alias) => {
      refSelectedAlias.current = alias;
      toggleHideUnassignConfirmDialog();
    }, []),
    promoteAliasConfirmDialog = useCallback((alias: Alias) => {
      refSelectedAlias.current = alias;
      toggleHidePromoteConfirmDialog();
    }, []),
    onChange = useCallback((_, newValue) => setSearchKey(newValue || ''), [setSearchKey]),
    onDropdownChange = useCallback((_, option) => setCategory(setFilter, option?.key || ''), [setCategory]),
    clearFilters = useCallback(() => setCategory(setFilter, Assignment.All), [setCategory]),
    activeFilters = useMemo(() => {
      const filterKey = getCategory(filterMap);
      const matchingOption = assignmentOptions.find((option) => option.key === filterKey);
      if (!matchingOption || filterKey === Assignment.All) return [];
      return [
        {
          key: matchingOption.key,
          label: matchingOption.text,
        },
      ];
    }, [filterMap]);
  useEffect(() => {
    const load = async () => {
      await loadAliases();
      await fetchInstances();
    };
    load();
  }, []);
  return (
    <>
      <FilterPanel isOpen={filterPanelOpen} onDismiss={dismissFilterPanel}>
        <FilterPanelInput>
          <Dropdown
            label="Status"
            placeholder="All"
            options={assignmentOptions}
            onChange={onDropdownChange}
            selectedKey={getCategory(filterMap) || Assignment.All}
          />
        </FilterPanelInput>
      </FilterPanel>
      <ListPage
        activeFilters={activeFilters}
        copy={{
          title: 'Manage Aliases',
          subtitle: '',
          noDataMessage: 'No aliases found',
          noFilterResultsMessage: 'No aliases match these filters',
          loadingMessage: 'Loading Aliases...',
          searchPlaceholder: 'Search by alias name',
        }}
        loading={loading}
        parentPage={RoutePaths.ADMIN_INSTANCES}
        primaryButtonProps={{
          text: 'Create alias',
          onClick: () => {
            openConfigPanel();
          },
        }}
        searchText={searchKey}
        showFilterButton
        showNoDataPage={aliases?.length === 0}
        showNoResults={refinedItems.length === 0}
        onChangeSearchText={onChange}
        onOpenFilterPanel={openFilterPanel}
        onRemoveFilter={clearFilters}
        onResetFilters={clearFilters}
      >
        <ManagedAliasList
          instances={instances}
          aliases={refinedItems}
          assignAlias={openConfigPanel}
          deleteAlias={deleteAliasConfirmDialog}
          promoteAlias={promoteAliasConfirmDialog}
          unassignAlias={unassignAliasConfirmDialog}
        />
        {loading && (
          <Loader styles={[loaderStylesSpinnerXLarge, { root: { pointerEvents: 'auto' } }]} label="loading" />
        )}
        <ConfirmDialog
          hidden={hideDeleteConfirmDialog}
          onDismiss={toggleHideDeleteConfirmDialog}
          title="Delete Alias"
          content={
            <div style={{ fontSize: FontSizes.small }}>
              Do you really want to delete the alias ({refSelectedAlias.current?.alias})?
            </div>
          }
          onConfirm={() => {
            if (refSelectedAlias.current) {
              deleteAlias(refSelectedAlias.current);
            }
            toggleHideDeleteConfirmDialog();
          }}
          confirmationButtonText="Delete"
        ></ConfirmDialog>
        <ConfirmDialog
          hidden={hideUnassignConfirmDialog}
          onDismiss={toggleHideUnassignConfirmDialog}
          title="Unassign Alias"
          content={
            <div style={{ fontSize: FontSizes.small }}>
              Do you really want to unassign the alias ({refSelectedAlias.current?.alias})?
            </div>
          }
          onConfirm={() => {
            if (refSelectedAlias.current) {
              unassignAlias(refSelectedAlias.current);
            }
            toggleHideUnassignConfirmDialog();
          }}
          confirmationButtonText="Unassign"
        ></ConfirmDialog>
        <ConfirmDialog
          hidden={hidePromoteConfirmDialog}
          onDismiss={toggleHidePromoteConfirmDialog}
          title="Set primary alias"
          content={
            <div style={{ fontSize: FontSizes.small }}>
              Do you really want to set ({refSelectedAlias.current?.alias}) as primary alias?
            </div>
          }
          onConfirm={() => {
            if (refSelectedAlias.current) {
              promoteAlias(refSelectedAlias.current);
            }
            toggleHidePromoteConfirmDialog();
          }}
          confirmationButtonText="Set"
        ></ConfirmDialog>
      </ListPage>
      {isOpen && (
        <AliasConfigPanel
          {...aliasConfigPanelProps}
          key={aliasConfigPanelProps?.alias?.id}
          instances={instances}
          isOpen={isOpen}
          onClose={dismissPanel}
          onAssign={closePanel}
        />
      )}
    </>
  );
}

export default AdminManageAliasPage;
