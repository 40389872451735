// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/orchestrator/v1/workflow_trigger.proto (package ai.h2o.orchestrator.v1, syntax proto3)
/* eslint-disable */

/**
 * Specification for scheduling trigger
 *
 * @generated from message ai.h2o.orchestrator.v1.ScheduleSpec
 */
export type ScheduleSpec = {
/**
 * Required. The time zone in which the cron expression is defined.
 *
 * @generated from field: string time_zone = 1;
 */
timeZone: string;
/**
 * Required. The cron expression that determines the schedule for triggering.
 *
 * @generated from field: string cron_expression = 2;
 */
cronExpression: string;
}
;
/**
 * WorkflowTrigger defines how to automatically initiate a workflow.
 * Currently, it supports triggering workflows through scheduling using a cron expression.
 *
 * @generated from message ai.h2o.orchestrator.v1.WorkflowTrigger
 */
export type WorkflowTrigger = {
/**
 * Resource name.
 *
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * Human-readable name.
 *
 * @generated from field: string display_name = 2;
 */
displayName?: string;
/**
 * Parameters which will be passed to workflow execution. Override parameters defined on workflow.
 *
 * @generated from field: map<string, string> parameters = 4;
 */
parameters?: { [key: string]: string };
/**
 * Current state of WorkflowTrigger.
 *
 * @generated from field: ai.h2o.orchestrator.v1.WorkflowTrigger.State state = 5;
 */
state?: WorkflowTrigger_State;
/**
 * Output only. Name of an entity that created the Trigger.
 *
 * @generated from field: string creator = 6;
 */
creator?: string;
/**
 * Output only. Human-readable name of creator.
 *
 * @generated from field: string creator_display_name = 7;
 */
creatorDisplayName?: string;
/**
 * Output only. Time when the Runnable was created.
 *
 * @generated from field: google.protobuf.Timestamp create_time = 8;
 */
createTime?: string;
/**
 * Output only. Time when trigger was last time fired.
 *
 * @generated from field: google.protobuf.Timestamp last_execution_time = 9;
 */
lastExecutionTime?: string;
}
 & (
{ 
/**
 * specification for trigger of scheduling type.
 *
 * @generated from field: ai.h2o.orchestrator.v1.ScheduleSpec schedule = 3;
 */
schedule?: ScheduleSpec;
 }
)
;
/**
 * WorkflowTrigger states.
 *
 * @generated from enum ai.h2o.orchestrator.v1.WorkflowTrigger.State
 */
export enum WorkflowTrigger_State {
/**
 * state is unspecified or unknown.
 *
 * @generated from enum value: STATE_UNSPECIFIED = 0;
 */
UNSPECIFIED = "STATE_UNSPECIFIED",

/**
 * trigger is active.
 *
 * @generated from enum value: STATE_ACTIVE = 1;
 */
ACTIVE = "STATE_ACTIVE",

/**
 * trigger is inactive
 *
 * @generated from enum value: STATE_INACTIVE = 2;
 */
INACTIVE = "STATE_INACTIVE",
}
