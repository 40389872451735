// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/h2o_engine_constraint_set_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { H2OEngineConstraintSet } from "./h2o_engine_constraint_set_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for getting the current H2OEngineConstraintSet.
 *
 * @generated from message ai.h2o.engine.v1.GetH2OEngineConstraintSetRequest
 */
export type GetH2OEngineConstraintSetRequest = {
/**
 * H2OEngineConstraintSet resource name.
 * Format: workspaces/{workspace}/h2oEngineConstraintSet
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for getting the current H2OEngineConstraintSet.
 *
 * @generated from message ai.h2o.engine.v1.GetH2OEngineConstraintSetResponse
 */
export type GetH2OEngineConstraintSetResponse = {
/**
 * H2OEngineConstraintSet resource.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngineConstraintSet h2o_engine_constraint_set = 1;
 */
h2oEngineConstraintSet?: H2OEngineConstraintSet;
}
;
/**
 * Returns the current H2OEngineConstraintSet.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineConstraintSetService.GetH2OEngineConstraintSet
 */
export const H2OEngineConstraintSetService_GetH2OEngineConstraintSet = new RPC<GetH2OEngineConstraintSetRequest,GetH2OEngineConstraintSetResponse>("GET", "/v1/{name=workspaces/*/h2oEngineConstraintSet}");
