// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/securestore/v1/secret_service.proto (package ai.h2o.securestore.v1, syntax proto3)
/* eslint-disable */

import type { Secret } from "./secret_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for `CreateSecret`.
 *
 * @generated from message ai.h2o.securestore.v1.CreateSecretRequest
 */
export type CreateSecretRequest = {
/**
 * Required. The resource name of the workspace to associate with the Secret.
 * Format: `workspaces/*`
 *
 * Authorization requires the following permission on the specified resource parent:
 * * actions/securestore/secrets/CREATE
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Required. The Secret resource to create.
 *
 * @generated from field: ai.h2o.securestore.v1.Secret secret = 2;
 */
secret: Secret;
/**
 * Optional. Specify the Secret ID, which will become a part of the Secret
 * resource name. If not specified, the server will generate one.
 *
 * It must:
 * - be unique within the parent workspace
 * - contain 1-63 characters
 * - contain only lowercase alphanumeric characters or hyphen ('-')
 * - start with an alphabetic character
 * - end with an alphanumeric character
 *
 * This matches a regular expression: `^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$`
 *
 * @generated from field: string secret_id = 3;
 */
secretId?: string;
}
;
/**
 * Response message for `CreateSecret`.
 *
 * @generated from message ai.h2o.securestore.v1.CreateSecretResponse
 */
export type CreateSecretResponse = {
/**
 * The created Secret.
 *
 * @generated from field: ai.h2o.securestore.v1.Secret secret = 1;
 */
secret?: Secret;
}
;
/**
 * Request message for `GetSecret`.
 *
 * @generated from message ai.h2o.securestore.v1.GetSecretRequest
 */
export type GetSecretRequest = {
/**
 * Required. The resource name of the Secret.
 * Format: `secrets/*`
 *
 * Authorization requires the following permission on the specified resource name:
 * * `actions/securestore/secrets/GET`
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for `GetSecret`.
 *
 * @generated from message ai.h2o.securestore.v1.GetSecretResponse
 */
export type GetSecretResponse = {
/**
 * The requested Secret.
 *
 * @generated from field: ai.h2o.securestore.v1.Secret secret = 1;
 */
secret?: Secret;
}
;
/**
 * Request message for `ListSecrets`.
 *
 * @generated from message ai.h2o.securestore.v1.ListSecretsRequest
 */
export type ListSecretsRequest = {
/**
 * Required. The resource name of the workspace associated with the Secrets.
 * Format: `workspaces/*`
 *
 * Authorization requires the following permission on the specified resource parent:
 * * `actions/securestore/secrets/LIST`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Optional. The maximum number of results to be returned in a single page.
 * If unspecified (or set to 0), the server decides the number of results to return.
 * If the number is greater than 1000, it is capped at 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Optional. Pagination token, returned in `ListSecretsResponse.next_page_token`.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
/**
 * Optional. If set to true, deleted Secrets are included in the list.
 *
 * @generated from field: bool show_deleted = 4;
 */
showDeleted?: boolean;
}
;
/**
 * Response message for `ListSecrets`.
 *
 * @generated from message ai.h2o.securestore.v1.ListSecretsResponse
 */
export type ListSecretsResponse = {
/**
 * Collection of Secrets.
 *
 * @generated from field: repeated ai.h2o.securestore.v1.Secret secrets = 1;
 */
secrets?: Secret[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
/**
 * The total number of Secrets.
 *
 * @generated from field: int32 total_size = 3;
 */
totalSize?: number;
}
;
/**
 * Request message for `DeleteSecret`.
 *
 * @generated from message ai.h2o.securestore.v1.DeleteSecretRequest
 */
export type DeleteSecretRequest = {
/**
 * Required. The resource name of the Secret to delete.
 * Format: `workspaces/*\/secrets/*`
 *
 * Authorization requires the following permission on the specified resource name:
 * * `actions/securestore/secrets/DELETE`
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for `DeleteSecret`.
 *
 * @generated from message ai.h2o.securestore.v1.DeleteSecretResponse
 */
export type DeleteSecretResponse = {
/**
 * The deleted Secret.
 *
 * @generated from field: ai.h2o.securestore.v1.Secret secret = 1;
 */
secret?: Secret;
}
;
/**
 * Request message for `UndeleteSecret`.
 *
 * @generated from message ai.h2o.securestore.v1.UndeleteSecretRequest
 */
export type UndeleteSecretRequest = {
/**
 * Required. The resource name of the Secret to undelete.
 * Format: `workspaces/*\/secrets/*`
 *
 * Authorization requires the following permission on the specified resource parent:
 * * actions/securestore/secrets/UNDELETE
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for `UndeleteSecret`.
 *
 * @generated from message ai.h2o.securestore.v1.UndeleteSecretResponse
 */
export type UndeleteSecretResponse = {
/**
 * The undeleted Secret.
 *
 * @generated from field: ai.h2o.securestore.v1.Secret secret = 1;
 */
secret?: Secret;
}
;
/**
 * Creates a new Secret containing no SecretVersions
 *
 * @generated from rpc ai.h2o.securestore.v1.SecretService.CreateSecret
 */
export const SecretService_CreateSecret = new RPC<CreateSecretRequest,CreateSecretResponse>("POST", "/v1/{parent=workspaces/*}/secrets", "secret");
/**
 * Gets metadata for a Secret.
 *
 * @generated from rpc ai.h2o.securestore.v1.SecretService.GetSecret
 */
export const SecretService_GetSecret = new RPC<GetSecretRequest,GetSecretResponse>("GET", "/v1/{name=workspaces/*/secrets/*}");
/**
 * Lists Secrets.
 *
 * @generated from rpc ai.h2o.securestore.v1.SecretService.ListSecrets
 */
export const SecretService_ListSecrets = new RPC<ListSecretsRequest,ListSecretsResponse>("GET", "/v1/{parent=workspaces/*}/secrets");
/**
 * Deletes a Secret and the child SecretVersions.
 * A deleted secret is purged after 30 days but can be restored using the undelete method.
 *
 * @generated from rpc ai.h2o.securestore.v1.SecretService.DeleteSecret
 */
export const SecretService_DeleteSecret = new RPC<DeleteSecretRequest,DeleteSecretResponse>("DELETE", "/v1/{name=workspaces/*/secrets/*}");
/**
 * Undeletes a deleted Secret and the child SecretVersions.
 *
 * @generated from rpc ai.h2o.securestore.v1.SecretService.UndeleteSecret
 */
export const SecretService_UndeleteSecret = new RPC<UndeleteSecretRequest,UndeleteSecretResponse>("POST", "/v1/{name=workspaces/*/secrets/*}:undelete");
