// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/dai_profile_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { DAIProfile } from "./dai_profile_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for creating a new DAIProfile.
 *
 * @generated from message ai.h2o.engine.v1.CreateDAIProfileRequest
 */
export type CreateDAIProfileRequest = {
/**
 * The DAIProfile resource to create.
 *
 * @generated from field: ai.h2o.engine.v1.DAIProfile dai_profile = 2;
 */
daiProfile: DAIProfile;
/**
 * Specify the DAIProfile ID, which will become a part of the DAIProfile
 * resource name.
 *
 * It must:
 * - contain 1-63 characters
 * - contain only lowercase alphanumeric characters or hyphen ('-')
 * - start with an alphabetic character
 * - end with an alphanumeric character
 *
 * This matches a regular expression: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
 *
 * @generated from field: string dai_profile_id = 3;
 */
daiProfileId: string;
}
;
/**
 * Response message for DAIProfileService.CreateDAIProfile.
 *
 * @generated from message ai.h2o.engine.v1.CreateDAIProfileResponse
 */
export type CreateDAIProfileResponse = {
/**
 * DAIProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIProfile dai_profile = 1;
 */
daiProfile?: DAIProfile;
}
;
/**
 * Request message for getting a single DAIProfile.
 *
 * @generated from message ai.h2o.engine.v1.GetDAIProfileRequest
 */
export type GetDAIProfileRequest = {
/**
 * DAIProfile resource name.
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DAIProfileService.GetDAIProfile.
 *
 * @generated from message ai.h2o.engine.v1.GetDAIProfileResponse
 */
export type GetDAIProfileResponse = {
/**
 * DAIProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIProfile dai_profile = 1;
 */
daiProfile?: DAIProfile;
}
;
/**
 * Request message for listing DAIProfiles.
 *
 * @generated from message ai.h2o.engine.v1.ListDAIProfilesRequest
 */
export type ListDAIProfilesRequest = {
/**
 * Maximum number of DAIProfiles to return in a response.
 * If unspecified (or set to 0), at most 50 DAIProfiles will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListDAIProfilesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for DAIProfileService.ListDAIProfiles.
 *
 * @generated from message ai.h2o.engine.v1.ListDAIProfilesResponse
 */
export type ListDAIProfilesResponse = {
/**
 * Collection of DAIProfiles.
 *
 * @generated from field: repeated ai.h2o.engine.v1.DAIProfile dai_profiles = 1;
 */
daiProfiles?: DAIProfile[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
/**
 * Total number of DAIProfiles matched by the List request.
 *
 * @generated from field: int32 total_size = 3;
 */
totalSize?: number;
}
;
/**
 * Request message for updating an existing DAIProfile.
 *
 * @generated from message ai.h2o.engine.v1.UpdateDAIProfileRequest
 */
export type UpdateDAIProfileRequest = {
/**
 * DAIProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIProfile dai_profile = 1;
 */
daiProfile: DAIProfile;
/**
 * The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are: {"display_name", "cpu", "gpu", "memory_bytes", "storage_bytes"}.
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 10;
 */
updateMask: string;
}
;
/**
 * Response for DAIProfileService.UpdateDAIProfile.
 *
 * @generated from message ai.h2o.engine.v1.UpdateDAIProfileResponse
 */
export type UpdateDAIProfileResponse = {
/**
 * DAIProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIProfile dai_profile = 1;
 */
daiProfile?: DAIProfile;
}
;
/**
 * Request message for deleting an existing DAIProfile.
 *
 * @generated from message ai.h2o.engine.v1.DeleteDAIProfileRequest
 */
export type DeleteDAIProfileRequest = {
/**
 * DAIProfile resource name.
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for DAIProfileService.DeleteDAIProfile.
 *
 * @generated from message ai.h2o.engine.v1.DeleteDAIProfileResponse
 */
export type DeleteDAIProfileResponse = {
}
;
/**
 * Request message for reordering DAIProfile.
 *
 * @generated from message ai.h2o.engine.v1.ReorderDAIProfileRequest
 */
export type ReorderDAIProfileRequest = {
/**
 * DAIProfile resource name.
 *
 * @generated from field: string name = 1;
 */
name: string;
/**
 * New order of the DAIProfile.
 * Must be >= 0.
 *
 * @generated from field: int32 new_order = 2;
 */
newOrder: number;
}
;
/**
 * Response message for reordering DAIProfile.
 *
 * @generated from message ai.h2o.engine.v1.ReorderDAIProfileResponse
 */
export type ReorderDAIProfileResponse = {
/**
 * All DAIProfiles in the new order.
 *
 * @generated from field: repeated ai.h2o.engine.v1.DAIProfile dai_profiles = 1;
 */
daiProfiles?: DAIProfile[];
}
;
/**
 * Creates a new DAIProfile.
 * (-- api-linter: core::0133::method-signature=disabled
 *     aip.dev/not-precedent: Not specifying "parent" because DAIProfile is a top-level resource. --)
 * (-- api-linter: core::0133::http-uri-parent=disabled
 *     aip.dev/not-precedent: Not specifying "parent" because DAIProfile is a top-level resource. --)
 *
 * @generated from rpc ai.h2o.engine.v1.DAIProfileService.CreateDAIProfile
 */
export const DAIProfileService_CreateDAIProfile = new RPC<CreateDAIProfileRequest,CreateDAIProfileResponse>("POST", "/v1/daiProfiles", "daiProfile");
/**
 * Returns a specific DAIProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIProfileService.GetDAIProfile
 */
export const DAIProfileService_GetDAIProfile = new RPC<GetDAIProfileRequest,GetDAIProfileResponse>("GET", "/v1/{name=daiProfiles/*}");
/**
 * Returns a collection of DAIProfiles.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIProfileService.ListDAIProfiles
 */
export const DAIProfileService_ListDAIProfiles = new RPC<ListDAIProfilesRequest,ListDAIProfilesResponse>("GET", "/v1/daiProfiles");
/**
 * Updates a DAIProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIProfileService.UpdateDAIProfile
 */
export const DAIProfileService_UpdateDAIProfile = new RPC<UpdateDAIProfileRequest,UpdateDAIProfileResponse>("PATCH", "/v1/{daiProfile.name=daiProfiles/*}", "daiProfile");
/**
 * Deletes a DAIProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIProfileService.DeleteDAIProfile
 */
export const DAIProfileService_DeleteDAIProfile = new RPC<DeleteDAIProfileRequest,DeleteDAIProfileResponse>("DELETE", "/v1/{name=daiProfiles/*}");
/**
 * Change order of a DAIProfile. Changing DAIProfile's order may result in changing order of other
 * DAIProfiles.
 * new_order must be >= 0.
 * When new_order is greater than the last possible order, server will use the last order instead.
 * When new_order is zero, server will use the last order instead.
 *
 * Example 1:
 * - DAIProfiles: {profileA.order=1, profileB.order=2, profileC.order=3}.
 * - ReorderDAIProfile(name=profileA,new_order=2)
 * - DAIProfiles: {profileB.order=1, profileA.order=2, profileC.order=3}
 *
 * Example 2 (when new_order greater than the last order, apply last order):
 * - DAIProfiles: {profileA.order=1, profileB.order=2, profileC.order=3}.
 * - ReorderDAIProfile(name=profileA,new_order=50)
 * - DAIProfiles: {profileB.order=1, profileC.order=2, profileA.order=3}
 *
 * Example 3 (when new_order is zero, apply last order):
 * - DAIProfiles: {profileA.order=1, profileB.order=2, profileC.order=3}.
 * - ReorderDAIProfile(name=profileA,new_order=0)
 * - DAIProfiles: {profileB.order=1, profileC.order=2, profileA.order=3}
 *
 * @generated from rpc ai.h2o.engine.v1.DAIProfileService.ReorderDAIProfile
 */
export const DAIProfileService_ReorderDAIProfile = new RPC<ReorderDAIProfileRequest,ReorderDAIProfileResponse>("POST", "/v1/{name=daiProfiles/*}:reorder");
