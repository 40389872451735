import { IComboBoxOption } from '@fluentui/react';
import { PaginatedSearchComboBox } from '@h2oai/ui-kit';

import { useAuthzService } from '../../../../authz/hooks';
import { MembersDropdownProps } from './types';

const PAGE_SIZE = 50;

export const MembersDropdown = (props: MembersDropdownProps) => {
  const { onUserChange, prepopulatedUser, disableOptions } = props;
  const authzService = useAuthzService();
  const fetchUsers = async (searchQuery: string, nextPageToken: string) => {
    const data = await authzService.getUsers({
      pageSize: PAGE_SIZE,
      pageToken: nextPageToken,
      filter: `"${searchQuery}"`,
    });

    const userItems: IComboBoxOption[] =
      data?.users?.map((user) => ({
        key: user.name || '',
        text: user.displayName || '',
        data: user,
      })) || [];

    return {
      items: userItems,
      nextPageToken: data?.nextPageToken || null,
    };
  };

  return (
    <PaginatedSearchComboBox
      fetchItems={fetchUsers}
      placeholder="Select a user"
      prepopulatedItem={
        prepopulatedUser
          ? {
              key: prepopulatedUser.name || '',
              text: prepopulatedUser.displayName || '',
              data: prepopulatedUser,
            }
          : undefined
      }
      disableOptions={disableOptions}
      onOptionChange={onUserChange}
    />
  );
};
