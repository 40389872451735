// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/dai_engine_constraint_set_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { DAIEngineConstraintSet } from "./dai_engine_constraint_set_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for getting the current DAIEngineConstraintSet.
 *
 * @generated from message ai.h2o.engine.v1.GetDAIEngineConstraintSetRequest
 */
export type GetDAIEngineConstraintSetRequest = {
/**
 * DAIEngineConstraintSet resource name.
 * Format: workspaces/{workspace}/daiEngineConstraintSet
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for getting the current DAIEngineConstraintSet.
 *
 * @generated from message ai.h2o.engine.v1.GetDAIEngineConstraintSetResponse
 */
export type GetDAIEngineConstraintSetResponse = {
/**
 * DAIEngineConstraintSet resource.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngineConstraintSet dai_engine_constraint_set = 1;
 */
daiEngineConstraintSet?: DAIEngineConstraintSet;
}
;
/**
 * Returns the current DAIEngineConstraintSet.
 *
 * @generated from rpc ai.h2o.engine.v1.DAIEngineConstraintSetService.GetDAIEngineConstraintSet
 */
export const DAIEngineConstraintSetService_GetDAIEngineConstraintSet = new RPC<GetDAIEngineConstraintSetRequest,GetDAIEngineConstraintSetResponse>("GET", "/v1/{name=workspaces/*/daiEngineConstraintSet}");
