import { PanelType, Separator, Stack, Text } from '@fluentui/react';
import { Button, IH2OTheme, Panel, TextWithCopy, buttonStylesGhost, buttonStylesSmall, useTheme } from '@h2oai/ui-kit';
import { ReactNode, useMemo } from 'react';

import { User } from '../../../authz/gen/ai/h2o/user/v1/user_pb';
import { renderViewFieldElement } from '../../../components/AdminSettings/components/SidePannel/utils';
import {
  adminSettingsViewPanelLabel,
  adminSettingsViewPanelValue,
} from '../../../components/AdminSettings/components/SidePannel/viewPannel.styles';
import { booleanToReadable, copyHideContentsStyles } from '../../../components/AdminSettings/Entity/utils';
import { getIdFromName } from '../../../components/AdminSettings/utils';
import { stylesPanel } from '../../../components/AIEnginesPage/components/AIEMPanel/AIEMPanel';
import { SecureStoreEntityType } from '../../../secure-store/entity/types';
import type { OAuthClient } from '../../../secure-store/gen/ai/h2o/securestore/v1/oauth_client_pb';
import { Secret_State } from '../../../secure-store/gen/ai/h2o/securestore/v1/secret_pb';
import { formatDateWithTime } from '../../../utils/utils';
import { useUsers } from '../../Orchestrator/UsersProvider';
import { ExtendedSecret, SecureStoreViewPanelProps } from '../types';
import { getNameFromPath, getSecretState } from './parts';

const OAuthClientEntityView = (oauthClient?: OAuthClient) => {
  if (!oauthClient) {
    return <></>;
  }

  return (
    <>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Display name</Text>
        <Text {...adminSettingsViewPanelValue}>{oauthClient.displayName}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>ID</Text>
        <Text {...adminSettingsViewPanelValue}>{getIdFromName(oauthClient.name || '')}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Issuer</Text>
        <Text {...adminSettingsViewPanelValue} title={oauthClient.issuer}>
          {renderViewFieldElement(oauthClient.issuer, <TextWithCopy text={oauthClient.issuer} />)}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>ClientID</Text>
        <Text {...adminSettingsViewPanelValue} title={oauthClient.clientId}>
          {renderViewFieldElement(oauthClient.clientId, <TextWithCopy text={oauthClient.clientId} />)}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Client Secret</Text>
        <Text {...adminSettingsViewPanelValue} title={oauthClient.clientSecret}>
          {renderViewFieldElement(oauthClient.clientSecret, <TextWithCopy text={oauthClient.clientSecret} />)}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Authorization URL</Text>
        <Text {...adminSettingsViewPanelValue} title={oauthClient.authorizationEndpoint}>
          {renderViewFieldElement(
            oauthClient.authorizationEndpoint,
            <TextWithCopy text={oauthClient.authorizationEndpoint} />
          )}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Token Endpoint</Text>
        <Text {...adminSettingsViewPanelValue} title={oauthClient.tokenEndpoint}>
          {renderViewFieldElement(oauthClient.tokenEndpoint, <TextWithCopy text={oauthClient.tokenEndpoint} />)}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Login Principal Claim</Text>
        <Text {...adminSettingsViewPanelValue} title={oauthClient.loginPrincipalClaim}>
          {renderViewFieldElement(
            oauthClient.loginPrincipalClaim,
            <TextWithCopy text={oauthClient.loginPrincipalClaim} />
          )}
        </Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Extra Scopes</Text>
        <Text {...adminSettingsViewPanelValue}>{oauthClient.extraScopes?.join(', ') || '-'}</Text>
      </Stack>
      <Stack horizontal>
        <Text {...adminSettingsViewPanelLabel}>Refresh Disabled</Text>
        <Text {...adminSettingsViewPanelValue}>{booleanToReadable(oauthClient.refreshDisabled)}</Text>
      </Stack>
    </>
  );
};

const getRow = (title: string, value: string | ReactNode) => (
  <Stack horizontal verticalAlign="center" key={title}>
    <Text {...adminSettingsViewPanelLabel}>{title}</Text>
    <Text {...adminSettingsViewPanelValue}>{value}</Text>
  </Stack>
);

const SecretServiceEntityView = (
  secret?: ExtendedSecret,
  usersNameMap?: { [key: string]: User },
  revealSecret?: (secretVersionName: string) => void | Promise<void>,
  theme?: IH2OTheme
) => {
  if (!secret || !usersNameMap || !theme) return null;

  const rowConfig = [
    {
      name: 'Name',
      value: <TextWithCopy text={secret.name} header={getNameFromPath(secret.name)} styles={copyHideContentsStyles} />,
    },
    { name: 'State', value: getSecretState(theme, secret.state as Secret_State, true) },
    { name: 'Creator', value: usersNameMap[secret.creator || ''].displayName },
    { name: 'Deleter', value: secret.deleter || 'N/A' },
    { name: 'Created at', value: formatDateWithTime(new Date(secret.createTime || '')) },
  ];

  return (
    <>
      {rowConfig.map(({ name, value }) => getRow(name, value))}
      <Text variant="large" style={{ margin: '28px 0 -8px' }}>
        Secret Versions
      </Text>
      {secret.data.map((secretVersion) => (
        <Stack key={secretVersion.name}>
          <Separator />
          {getRow(
            'Version name',
            <TextWithCopy text={secret.name} header={getNameFromPath(secret.name)} styles={copyHideContentsStyles} />
          )}
          <Stack horizontal key={secretVersion.name} verticalAlign="center" style={{ marginTop: 20 }}>
            <Text {...adminSettingsViewPanelLabel}>Secret value</Text>
            {secretVersion.value ? (
              <TextWithCopy text={secretVersion.value} />
            ) : (
              <Button
                text="Reveal secret"
                styles={[buttonStylesGhost, buttonStylesSmall]}
                onClick={() => revealSecret && revealSecret(secretVersion.name || '')}
              />
            )}
          </Stack>
        </Stack>
      ))}
    </>
  );
};

export const SecureStoreViewPanel = (props: SecureStoreViewPanelProps) => {
  const { item, type, onDismiss, panelTitle, isOpen } = props;
  const theme = useTheme();
  const { usersNameMap } = useUsers();

  const content = useMemo(() => {
    switch (type) {
      case SecureStoreEntityType.OAuthClient:
        return OAuthClientEntityView(item as OAuthClient);
      case SecureStoreEntityType.SecretService:
        return SecretServiceEntityView(item as ExtendedSecret, usersNameMap, props.revealSecret, theme);

      default:
        return null;
    }
  }, [type, item]);

  return (
    <Panel
      isLightDismiss
      customWidth="500px"
      headerText={panelTitle}
      isFooterAtBottom
      isOpen={isOpen ?? true}
      onDismiss={onDismiss}
      type={PanelType.custom}
      styles={stylesPanel}
      onRenderFooterContent={() => {
        return (
          <Stack horizontal tokens={{ childrenGap: 10 }} horizontalAlign="end">
            <Button text="Close" onClick={onDismiss} />
          </Stack>
        );
      }}
    >
      <Stack tokens={{ childrenGap: 20 }}>{content}</Stack>
    </Panel>
  );
};
