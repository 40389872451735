import { Stack } from '@fluentui/react';
import { FontWeights, ILoaderProps, Item, itemStylesCategory, useTheme } from '@h2oai/ui-kit';
import { CSSProperties } from 'react';

import Cell from './Cell';

interface BadgeCellContentsProps {
  title?: string;
  badgeLabel: string;
  badgeBackgroundColor?: string;
  badgeTextColor?: string;
  badgeIconName?: string;
  badgeLoading?: boolean;
  badgeLoaderProps?: ILoaderProps;
  dataTest?: string;
  titleStyles?: CSSProperties;
  children?: React.ReactNode;
}

export function BadgeCellContents(props: BadgeCellContentsProps) {
  const theme = useTheme();

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      {props.title && <span style={{ fontWeight: FontWeights.bold, ...(props.titleStyles || {}) }}>{props.title}</span>}
      <Item
        styles={[
          itemStylesCategory,
          {
            root: {
              background: props.badgeBackgroundColor || theme.semanticColors?.categoryBackground,
              color: props.badgeTextColor || theme.semanticColors?.categoryText,
            },
          },
        ]}
        labelField="label"
        iconNameField={props.badgeIconName ? 'icon' : undefined}
        iconStyleField="iconStyles"
        data-test={props.dataTest}
        data={{
          label: props.badgeLabel,
          icon: props?.badgeIconName,
          iconStyles: { color: props.badgeTextColor || theme.semanticColors?.categoryText },
        }}
        loading={props.badgeLoading}
        loaderProps={props.badgeLoaderProps}
      />
      {props.children}
    </Stack>
  );
}

export function BadgeCell(props: BadgeCellContentsProps) {
  return (
    <Cell>
      <BadgeCellContents {...props} />
    </Cell>
  );
}
