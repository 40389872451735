import { Separator, Stack } from '@fluentui/react';
import {
  IKeyValuePairEditorClassnames,
  IKeyValuePairEditorStyles,
  keyValuePairEditorStyles,
  useClassNames,
} from '@h2oai/ui-kit';
import { useCallback, useMemo } from 'react';

import { RoleBinding } from '../../../../authz/gen/ai/h2o/authorization/v1/role_binding_pb';
import { useUsers } from '../../../Orchestrator/UsersProvider';
import { MemberListItem } from './MemberListItem';
import { membersListStyles } from './styles';
import { ManageMembersProps } from './types';

export const ManageMembers = (props: ManageMembersProps) => {
  const { prepopulatedRoleBindings } = props;
  const { usersNameMap } = useUsers();
  const usersWithRoles = useMemo(() => {
    if (!prepopulatedRoleBindings || !usersNameMap) return [];

    const uniqueUsers = new Set(prepopulatedRoleBindings.map((item) => item.subject || ''));

    return [...uniqueUsers].map((userName) => ({ ...usersNameMap[userName] }));
  }, [prepopulatedRoleBindings, usersNameMap]);
  const usersInWorkspaceRolesList = useMemo(() => usersWithRoles.map(({ name }) => name || ''), [usersWithRoles]);

  const getUserRoleBindings = useCallback(
    (userName?: string): RoleBinding[] => {
      if (!prepopulatedRoleBindings || !usersNameMap || !userName) return [];

      return prepopulatedRoleBindings?.filter((roleBinding) => roleBinding.subject === userName);
    },
    [usersNameMap, prepopulatedRoleBindings]
  );
  const handleUpdateRoleBindings = useCallback(
    (roleBinding: RoleBinding[], isCreate: boolean) => {
      props.onMembersChange(roleBinding, isCreate);
    },
    [usersNameMap, prepopulatedRoleBindings]
  );

  const classNames = useClassNames<IKeyValuePairEditorStyles, IKeyValuePairEditorClassnames>(
    'MembersList',
    keyValuePairEditorStyles,
    membersListStyles
  );

  return (
    <Stack className={classNames.root}>
      <Stack horizontal horizontalAlign={'space-between'} className={classNames.header}>
        <div className={classNames.keyColumn}>
          <span>Member Name</span>
        </div>
        <div className={classNames.valueColumn}>
          <span>Permission</span>
        </div>
        <div className={classNames.buttonColumn}>
          <span>Actions</span>
        </div>
      </Stack>
      <Stack>
        <MemberListItem
          updateRoleBindings={handleUpdateRoleBindings}
          usersInWorkspaceRolesList={usersInWorkspaceRolesList}
        />

        {usersWithRoles.length > 0 && <Separator styles={{ root: { margin: '16px 0' } }}>Assigned roles</Separator>}

        {usersWithRoles.map((user) => {
          const userRoleBindings = getUserRoleBindings(user.name);

          return (
            <MemberListItem
              key={user.name}
              prepopulatedRoleBindings={userRoleBindings}
              updateRoleBindings={handleUpdateRoleBindings}
              usersInWorkspaceRolesList={usersInWorkspaceRolesList}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};
