// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/orchestrator/v1/workflow_execution.proto (package ai.h2o.orchestrator.v1, syntax proto3)
/* eslint-disable */

/**
 * Execution of workflow.
 *
 * @generated from message ai.h2o.orchestrator.v1.WorkflowExecution
 */
export type WorkflowExecution = {
/**
 * Resource name.
 *
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * workflow revision name
 *
 * @generated from field: string workflow_revision = 2;
 */
workflowRevision?: string;
/**
 * All steps which are executed in that workflow.
 *
 * @generated from field: repeated ai.h2o.orchestrator.v1.WorkflowExecution.WorkflowStepExecution step_executions = 3;
 */
stepExecutions?: WorkflowExecution_WorkflowStepExecution[];
/**
 * Name of an entity that initiated the workflow execution.
 *
 * @generated from field: string initiator = 4;
 */
initiator?: string;
/**
 * The current state of the WorkflowExecution.
 *
 * @generated from field: ai.h2o.orchestrator.v1.WorkflowExecution.State state = 5;
 */
state?: WorkflowExecution_State;
/**
 * Time when the WorkflowExecution started executing.
 *
 * @generated from field: google.protobuf.Timestamp start_time = 6;
 */
startTime?: string;
/**
 * Time when the WorkflowExecution completed.
 *
 * @generated from field: google.protobuf.Timestamp end_time = 7;
 */
endTime?: string;
/**
 * Output only. Human-readable name of initiator.
 *
 * @generated from field: string initiator_display_name = 8;
 */
initiatorDisplayName?: string;
}
;
/**
 * WorkflowExecution states.
 *
 * @generated from enum ai.h2o.orchestrator.v1.WorkflowExecution.State
 */
export enum WorkflowExecution_State {
/**
 * state is unspecified or unknown.
 *
 * @generated from enum value: STATE_UNSPECIFIED = 0;
 */
UNSPECIFIED = "STATE_UNSPECIFIED",

/**
 * WorkflowExecution is started.
 *
 * @generated from enum value: STATE_RUNNING = 2;
 */
RUNNING = "STATE_RUNNING",

/**
 * WorkflowExecution has completed with an error.
 *
 * @generated from enum value: STATE_FINISHED = 4;
 */
FINISHED = "STATE_FINISHED",

/**
 * WorkflowExecution has completed successfully.
 *
 * @generated from enum value: STATE_FAILED = 5;
 */
FAILED = "STATE_FAILED",

/**
 * WorkflowExecution has been cancelled.
 *
 * @generated from enum value: STATE_CANCELLED = 6;
 */
CANCELLED = "STATE_CANCELLED",
}
/**
 * A "WorkflowStepExecution" is an information what what was executed,
 * whether it's a piece of code or the triggering of another workflow.
 *
 * @generated from message ai.h2o.orchestrator.v1.WorkflowExecution.WorkflowStepExecution
 */
export type WorkflowExecution_WorkflowStepExecution = {
/**
 * Unique ID for this step execution. Share the id with "WorkflowStep"
 *
 * @generated from field: string unique_id = 1;
 */
uniqueId?: string;
/**
 * The current state of the WorkflowStepExecution.
 *
 * @generated from field: ai.h2o.orchestrator.v1.WorkflowExecution.WorkflowStepExecution.State state = 4;
 */
state?: WorkflowExecution_WorkflowStepExecution_State;
/**
 * Time when the WorkflowStepExecution started executing.
 *
 * @generated from field: google.protobuf.Timestamp start_time = 5;
 */
startTime?: string;
/**
 * Time when the WorkflowStepExecution completed.
 *
 * @generated from field: google.protobuf.Timestamp end_time = 6;
 */
endTime?: string;
}
 & (
{ 
/**
 * Point to runnable and it's execution started by this step.
 *
 * @generated from field: ai.h2o.orchestrator.v1.WorkflowExecution.WorkflowStepExecution.RunnableExecution runnable_execution = 2;
 */
runnableExecution?: WorkflowExecution_WorkflowStepExecution_RunnableExecution;
 }
 | 
{ 
/**
 * Point to workflow execution which was triggered by this step.
 *
 * @generated from field: ai.h2o.orchestrator.v1.WorkflowExecution.WorkflowStepExecution.RelatedWorkflowExecution workflow_execution = 3;
 */
workflowExecution?: WorkflowExecution_WorkflowStepExecution_RelatedWorkflowExecution;
 }
)
;
/**
 * WorkflowStepExecution states.
 *
 * @generated from enum ai.h2o.orchestrator.v1.WorkflowExecution.WorkflowStepExecution.State
 */
export enum WorkflowExecution_WorkflowStepExecution_State {
/**
 * state is unspecified or unknown.
 *
 * @generated from enum value: STATE_UNSPECIFIED = 0;
 */
UNSPECIFIED = "STATE_UNSPECIFIED",

/**
 * WorkflowStepExecution is waiting for startup.
 *
 * @generated from enum value: STATE_PENDING = 1;
 */
PENDING = "STATE_PENDING",

/**
 * WorkflowStepExecution is being executed.
 *
 * @generated from enum value: STATE_RUNNING = 2;
 */
RUNNING = "STATE_RUNNING",

/**
 * WorkflowStepExecution is waiting for upstream workflow step executions to finish.
 *
 * @generated from enum value: STATE_BLOCKING = 3;
 */
BLOCKING = "STATE_BLOCKING",

/**
 * WorkflowStepExecution has completed successfully.
 *
 * @generated from enum value: STATE_FINISHED = 4;
 */
FINISHED = "STATE_FINISHED",

/**
 * WorkflowStepExecution has completed with an error.
 *
 * @generated from enum value: STATE_FAILED = 5;
 */
FAILED = "STATE_FAILED",

/**
 * Upstream steps failed.
 *
 * @generated from enum value: STATE_UPSTREAM_FAILED = 6;
 */
UPSTREAM_FAILED = "STATE_UPSTREAM_FAILED",

/**
 * WorkflowStepExecution has been cancelled.
 *
 * @generated from enum value: STATE_CANCELLED = 7;
 */
CANCELLED = "STATE_CANCELLED",
}
/**
 * Information about execution started via Runnable.
 *
 * @generated from message ai.h2o.orchestrator.v1.WorkflowExecution.WorkflowStepExecution.RunnableExecution
 */
export type WorkflowExecution_WorkflowStepExecution_RunnableExecution = {
/**
 * Revision of runnable resource name.
 * Format: workspaces/{workspace}/runnables/{runnable}/revisions/{revision}
 *
 * @generated from field: string runnable = 1;
 */
runnable?: string;
/**
 * Standard output of the execution. It may be populated when the execution is running.
 * Only after the task has completed it is guaranteed to not change further.
 *
 * @generated from field: string stdout = 2;
 */
stdout?: string;
/**
 * When the execution has completed with an error
 * this field will be populated with the error name.
 * For example: "NameError".
 *
 * @generated from field: string error = 3;
 */
error?: string;
/**
 * When the execution has completed with an error
 * this field will be populated with the error value.
 * For example: "name 'x' is not defined".
 *
 * @generated from field: string error_value = 4;
 */
errorValue?: string;
/**
 * When the KernelTask has completed with an error
 * this field will be populated with the traceback.
 *
 * @generated from field: string traceback = 5;
 */
traceback?: string;
}
;
/**
 * Information about execution started related Workflow
 *
 * @generated from message ai.h2o.orchestrator.v1.WorkflowExecution.WorkflowStepExecution.RelatedWorkflowExecution
 */
export type WorkflowExecution_WorkflowStepExecution_RelatedWorkflowExecution = {
/**
 * workflow revision name
 *
 * @generated from field: string workflow_revision = 1;
 */
workflowRevision?: string;
/**
 * WorkflowExecution resource name.
 * Format: workspaces/{workspace}/workflows/{workflows}/executions/{execution}
 *
 * @generated from field: string workflow_execution = 2;
 */
workflowExecution?: string;
}
;
