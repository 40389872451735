// Minimal read-only API for working with the Users.

// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/user/v1/user_api.proto (package ai.h2o.user.v1, syntax proto3)
/* eslint-disable */

import type { User } from "./user_pb";
import { RPC } from "../../../../runtime";

/**
 * @generated from message ai.h2o.user.v1.GetUserRequest
 */
export type GetUserRequest = {
/**
 * Name of the requested User. For example: "users/<UUID>".
 *
 * @generated from field: string name = 1;
 */
name?: string;
}
;
/**
 * @generated from message ai.h2o.user.v1.GetUserResponse
 */
export type GetUserResponse = {
/**
 * @generated from field: ai.h2o.user.v1.User user = 1;
 */
user?: User;
}
;
/**
 * @generated from message ai.h2o.user.v1.ListUsersRequest
 */
export type ListUsersRequest = {
/**
 * @generated from field: int32 page_size = 1;
 */
pageSize?: number;
/**
 * @generated from field: string page_token = 2;
 */
pageToken?: string;
/**
 * When used, server will only return users that match the filter.
 *
 * Due to the limitations of the backends, the filter is limited to
 * single string literal that matches against the fields based on the
 * backend. Usually it's the email address, first and last name, and
 * login_principal.
 *
 * @generated from field: string filter = 4;
 */
filter?: string;
}
;
/**
 * @generated from message ai.h2o.user.v1.ListUsersResponse
 */
export type ListUsersResponse = {
/**
 * @generated from field: repeated ai.h2o.user.v1.User users = 1;
 */
users?: User[];
/**
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * @generated from message ai.h2o.user.v1.ListUsersByEmailRequest
 */
export type ListUsersByEmailRequest = {
/**
 * Maximum number of items server should return in the response. When set to 0
 * server will decide how many items to return.
 *
 * @generated from field: int32 page_size = 1;
 */
pageSize?: number;
/**
 * @generated from field: string page_token = 2;
 */
pageToken?: string;
/**
 * Email address of the User that is about to be returned.
 *
 * @generated from field: string email = 4;
 */
email?: string;
}
;
/**
 * @generated from message ai.h2o.user.v1.ListUsersByEmailResponse
 */
export type ListUsersByEmailResponse = {
/**
 * @generated from field: repeated ai.h2o.user.v1.User users = 1;
 */
users?: User[];
/**
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * @generated from rpc ai.h2o.user.v1.UserService.GetUser
 */
export const UserService_GetUser = new RPC<GetUserRequest,GetUserResponse>("GET", "/v1/{name=users/*}");
/**
 * @generated from rpc ai.h2o.user.v1.UserService.ListUsers
 */
export const UserService_ListUsers = new RPC<ListUsersRequest,ListUsersResponse>("GET", "/v1/users");
/**
 * Returns list of Users that has registered the email address specified
 * in the request.
 *
 * This is the dedicated custom method for the setups when the arbitrary
 * listing/filtering of the users have to be disable in order not to leak PII
 * but it's ok when to get the user info when the party knows the email like
 * for sharing the resources with the User whose email address I know.
 *
 * It's the List method as the emails are not necessary unique across
 * the users. It's up the client to decide how to handle cases when two users
 * has the same email registered.
 *
 * @generated from rpc ai.h2o.user.v1.UserService.ListUsersByEmail
 */
export const UserService_ListUsersByEmail = new RPC<ListUsersByEmailRequest,ListUsersByEmailResponse>("GET", "/v1/users:listByEmail");
