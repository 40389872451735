import { MessageBarType, SpinnerSize, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Loader, useToast } from '@h2oai/ui-kit';
import { Fragment, ReactNode, useCallback, useEffect, useReducer, useState } from 'react';

import { useWorkspaces } from '../../../../pages/Orchestrator/WorkspaceProvider';
import { handleErrMsg } from '../../../../utils/utils';
import { EditablePanelFooter } from '../../../EditablePanelFooter/EditablePanelFooter';
import { EntityActionType, EntityFieldType } from '../../Entity/constants';
import { MessageText, useEntity } from '../../Entity/hooks';
import type { EntityField, HasName } from '../../Entity/types';
import {
  BooleanEntityModelField,
  FormRow,
  LatestAndAliasesEntityModelField,
  NumberEntityModelField,
  ReadOnlyStringArrayEntityModelField,
  SelectEnumEntityModelField,
  StringArrayEntityModelField,
  TextEntityModelField,
} from '../BasicEntityModelComponents/BasicEntityModelComponents';
import type { EntityFieldInputProps } from '../BasicEntityModelComponents/types';
import ConstraintSetModelField from '../ConstraintSetModelField/ConstraintSetModelField';
import { DurationModelField } from '../DurationModelField';
import { EntityDisplayAndId } from '../EntityDisplayAndId/EntityDisplayAndId';
import { KeyValuePairEntityModelField } from '../KeyValuePairModelField';
import { NameIdEntityModelField } from '../NameIdEntityModelField/NameIdEntityModelField';
import { SemverEntityModelField } from '../SemverEntityModelField/SemverEntityModelField';
import { YamlEntityModelField } from '../YamlEntityModelField';
import type { IEntityModelFormProps, ValidationActions, ValidationReducerFunction, ValidationState } from './types';

export enum ValidationAction {
  REQUIRED_FIELDS = 'requiredFields',
  VALID_ID = 'validId',
  IS_NEW = 'isNew',
}

const validationReducer: ValidationReducerFunction = (
  state: ValidationState,
  action: ValidationActions
): ValidationState => {
  const newState = { ...state };
  newState[action.type] = action.value;
  return newState;
};

export const AddEditModelForm = <EntityModel extends HasName, EntityType extends string>(
  props: IEntityModelFormProps<EntityModel, EntityType>
) => {
  const { entity, model: originalModel, onSave, onDismiss, isCreate = false, entitiesMap } = props;
  const [validationState, validationDispatch] = useReducer<ValidationReducerFunction>(validationReducer, {
    requiredFields: !isCreate,
    validId: !isCreate,
    isNew: isCreate,
  });
  const { activeWorkspace } = useWorkspaces();
  const entityDataConnector = useEntity<EntityType>({
    entitiesMap,
    requestPath: props.requestPath,
  });
  const { addToast } = useToast();
  const { fields, type: entityType } = entity;
  const requiredFields = fields
    .filter((field) => field.required)
    .map((field) => field.referenceName || (field.name as keyof EntityModel));
  const [model, setModel] = useState<EntityModel | undefined>(originalModel ? { ...originalModel } : undefined);
  const [loading, { setFalse: showContent }] = useBoolean(true);
  const [valid, setValid] = useState<boolean>();
  const modelName = model?.name;

  // TODO: re-enable masking when API bugs are sorted out:
  // const getMaskedModel = (newModel: EntityModel, oldModel: EntityModel): Partial<EntityModel> => {
  //   const mask: Partial<EntityModel> = {};
  //   for (const key in newModel) {
  //     if (newModel[key] !== oldModel[key]) {
  //       mask[key] = newModel[key];
  //     }
  //   }
  //   return mask;
  // };

  const onChange = (fieldName: keyof EntityModel, value: any) => {
    if (!model) return;
    const partial: Partial<EntityModel> = {};
    partial[fieldName] = value;
    const newModel = { ...model, ...partial };

    dispatchValidations(newModel);
    setModel({ ...model, ...partial });
  };
  const dispatchValidations = (newModel?: EntityModel) => {
    if (!newModel) return;

    validationDispatch({
      type: ValidationAction.REQUIRED_FIELDS,
      value: requiredFields.every((field) => newModel[field]),
    });
    validationDispatch({
      type: ValidationAction.IS_NEW,
      value: isCreate || JSON.stringify(originalModel) !== JSON.stringify(newModel),
    });
  };

  const displayAndIdFilter = ({ type }: EntityField<EntityModel>) =>
    [EntityFieldType.DisplayOnDisplayAndId, EntityFieldType.IdOnDisplayAndId].includes(type);
  const displayAndIdFields: { display: EntityField<EntityModel>; id: EntityField<EntityModel> } = {} as {
    display: EntityField<EntityModel>;
    id: EntityField<EntityModel>;
  };
  fields.filter(displayAndIdFilter).forEach((field) => {
    if (field.type === EntityFieldType.DisplayOnDisplayAndId) displayAndIdFields.display = field;
    if (field.type === EntityFieldType.IdOnDisplayAndId) displayAndIdFields.id = field;
  });

  const nonIdOrDisplayFields = model
    ? fields
        .filter(({ type }) => !displayAndIdFilter({ type } as EntityField<EntityModel>))
        .map((field) => {
          const { type, name } = field;
          const fieldInputProps: EntityFieldInputProps<EntityModel, EntityType> = {
            field,
            model,
            onChange,
            entityType,
            isCreate,
            entitiesMap,
            requestPath: props.requestPath,
          };
          let modelField: ReactNode | undefined;
          switch (type) {
            case EntityFieldType.SelectEnum:
              modelField = <SelectEnumEntityModelField {...fieldInputProps} />;
              break;
            case EntityFieldType.Boolean:
              modelField = <BooleanEntityModelField {...fieldInputProps} />;
              break;
            case EntityFieldType.Text:
              modelField = <TextEntityModelField {...fieldInputProps} />;
              break;
            case EntityFieldType.Number:
              modelField = <NumberEntityModelField {...fieldInputProps} />;
              break;
            case EntityFieldType.Bytes:
              modelField = <NumberEntityModelField {...fieldInputProps} convertToGibibytes={true} />;
              break;
            case EntityFieldType.ReadOnlyStringArray:
              modelField = <ReadOnlyStringArrayEntityModelField {...fieldInputProps} />;
              break;
            case EntityFieldType.StringArray:
              modelField = <StringArrayEntityModelField {...fieldInputProps} />;
              break;
            case EntityFieldType.KeyValuePair:
              modelField = <KeyValuePairEntityModelField {...fieldInputProps} />;
              break;
            case EntityFieldType.Semver:
              modelField = (
                <SemverEntityModelField
                  {...fieldInputProps}
                  onChange={undefined}
                  onChangeMultiple={(partialModel: Partial<EntityModel>) => {
                    setModel((oldModel) => {
                      const model = oldModel ? { ...oldModel, ...partialModel } : undefined;
                      dispatchValidations(model);
                      return model;
                    });
                  }}
                  validate={(validId) => validationDispatch({ type: ValidationAction.VALID_ID, value: validId })}
                />
              );
              break;
            case EntityFieldType.NameId:
              modelField = (
                <NameIdEntityModelField
                  {...fieldInputProps}
                  onChange={undefined}
                  onChangeMultiple={(partialModel: Partial<EntityModel>) => {
                    setModel((oldModel) => {
                      const model = oldModel ? { ...oldModel, ...partialModel } : undefined;
                      dispatchValidations(model);
                      return model;
                    });
                  }}
                  validate={(validId) => validationDispatch({ type: ValidationAction.VALID_ID, value: validId })}
                />
              );
              break;
            case EntityFieldType.LatestAndAliases:
              modelField = <LatestAndAliasesEntityModelField {...fieldInputProps} />;
              break;
            case EntityFieldType.ConstraintNumeric:
            case EntityFieldType.ConstraintDuration:
              modelField = <ConstraintSetModelField {...fieldInputProps} />;
              break;
            case EntityFieldType.Duration:
              modelField = <DurationModelField {...fieldInputProps} />;
              break;
            case EntityFieldType.Yaml:
              modelField = <YamlEntityModelField {...fieldInputProps} />;
              break;
            case EntityFieldType.Hidden:
              modelField = undefined;
              break;
            default:
              modelField = <FormRow>{`${String(name)} is yet to be implemented`}</FormRow>;
          }
          return <Fragment key={`${String(name)}-form-row`}>{modelField}</Fragment>;
        })
    : [];

  const onCreateOrUpdate = useCallback(async () => {
    if (!model) return;
    const action = isCreate ? entity.actions.Create : entity.actions.Update;
    if (!action) return;

    const { requestNameKey, requestPayloadKey } = action || {
      requestNameKey: 'bad-action-key',
      requestPayloadKey: 'bad-action-payload',
    };

    try {
      const request = {
        ...(activeWorkspace ? { parent: `${activeWorkspace.name}` } : {}),
        ...props.requestParams,
        [requestPayloadKey!]: model,
        [requestNameKey!]: model.name,
      };

      const messageText: MessageText = {
        subject: model[entity.displayNameKey] as unknown as string, // TODO: check if this coercion is safe
        action: isCreate ? 'created ' : 'updated',
      };

      if (isCreate) {
        await entityDataConnector.create(entity, request, messageText);
      } else {
        // TODO: re-enable masking when API bugs are sorted out:
        // const newModel = getMaskedModel(model, originalModel);
        // const updateMask = Object.keys(newModel).join(',');
        // we have to add the name always in order to satisfy the connector generated by protoc-gen-grpc-gateway-es,
        // even though the name field gets stripped out before the request is made:
        // newModel[requestNameKey!] = originalModel[requestNameKey!];
        const newReq = {
          [requestPayloadKey!]: model,
          [requestNameKey!]: model.name,
        };

        await entityDataConnector.update(entity, newReq, messageText);
      }
      onSave && onSave();
    } catch (error: any) {
      addToast({
        message: `An error occurred while attempting to ${isCreate ? 'create' : 'update'} the ${
          entity.displayName
        }: ${handleErrMsg(error.message)}`,
        messageBarType: MessageBarType.error,
      });
    }
  }, [model]);

  useEffect(() => {
    const fetchModel = async () => {
      const freshModel = await entityDataConnector.get(entity, { name: modelName || entity.name }, true);
      setModel((freshModel || originalModel) as EntityModel);
      showContent();
    };
    if (!isCreate) fetchModel();
    else showContent();
  }, []);

  useEffect(() => {
    setValid(validationState.requiredFields && validationState.validId && validationState.isNew);
  }, [validationState]);

  return (
    <Stack horizontal>
      {loading || !model ? (
        <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { paddingLeft: 200, paddingTop: 200 } }}>
          <Loader size={SpinnerSize.large} />
        </Stack>
      ) : (
        <>
          <Stack style={{ marginBottom: 10, maxWidth: '100%' }}>
            {displayAndIdFields.display && displayAndIdFields.id && (
              <EntityDisplayAndId
                requestPath={props.requestPath}
                entitiesMap={entitiesMap}
                model={model}
                onDisplayNameChange={(value: string) => onChange(displayAndIdFields.display.name, value)}
                onIdChange={(value: string) => onChange(displayAndIdFields.id.name, value)}
                validate={(isValid: boolean) => {
                  validationDispatch({ type: ValidationAction.VALID_ID, value: isValid });
                }}
                entityType={entityType}
                actionType={EntityActionType.Create}
                editableId={isCreate}
              />
            )}
            {nonIdOrDisplayFields}
          </Stack>
          <EditablePanelFooter
            onCancel={onDismiss!}
            onSave={onCreateOrUpdate}
            closeButtonText={'Cancel'}
            saveButtonText={isCreate ? `Add ${entity.displayName}` : `Save`}
            saveButtonDisabled={!valid}
          />
        </>
      )}
    </Stack>
  );
};
