import { IContextualMenuItem } from '@fluentui/react';
import {
  BasicList,
  Button,
  IEmptyMessageProps,
  IH2OTheme,
  IconName,
  WidgetItem,
  WidgetPanel,
  buttonStylesPrimary,
  buttonStylesSecondary,
  buttonStylesWidthXSmall,
  useTheme,
} from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';

import { AppInstance, AppInstance_Status, AppInstance_Visibility } from '../../../ai.h2o.cloud.appstore';
import { useInstance } from '../../../utils/hooks';
import {
  InstancePauseResumeMap,
  InstancePauseResumeOpEnum,
  getInstanceButtonTestId,
  instanceButtonTestIdSuffixes,
} from '../../../utils/utils';
import { RoutePaths } from '../../Routes';

interface AppInstanceItem {
  id: string;
  title: string;
  description: string;
  iconLocation: string;
  status?: string;
  statusColor?: string;
}

const getAppInstanceItem = (
  { id, suspendable, status, appDetails }: AppInstance,
  theme: IH2OTheme
): AppInstanceItem => {
  const { title, iconLocation, description } = appDetails || {};
  const statusTextAndColorMap = new Map([
    [0, { status: 'Not Available', statusColor: theme.palette?.gray300 }],
    [1, { status: 'Running', statusColor: theme.palette?.green100 }],
    [2, { status: 'Paused', statusColor: theme.palette?.red100 }],
  ]);
  const statusTextAndColor =
    suspendable && [AppInstance_Status.DEPLOYED, AppInstance_Status.SUSPENDED].includes(status)
      ? status === AppInstance_Status.DEPLOYED
        ? statusTextAndColorMap.get(1)
        : statusTextAndColorMap.get(2)
      : statusTextAndColorMap.get(0);
  return {
    id,
    title: title || ``,
    iconLocation: iconLocation ? `/v1/asset/${iconLocation}` : '/logo512.png',
    description: description || ``,
    status: statusTextAndColor?.status,
    statusColor: statusTextAndColor?.statusColor,
  };
};

export interface IAppInstanceListWidgetProps {
  title: string;
}

export const AppInstanceListWidget = ({ title }: IAppInstanceListWidgetProps) => {
  const theme = useTheme();
  const [data, setData] = useState<AppInstance[]>([]);
  const [loadingMessage, setLoadingMessage] = useState('Loading');
  const { getInstancesList, setInstanceSuspension, setInstancesSuspension } = useInstance();
  const loadData = useCallback(async () => {
    try {
      const instances = await getInstancesList({
        appId: '',
        includeAppDetails: true,
        visibility: AppInstance_Visibility.VISIBILITY_UNSPECIFIED,
        allUsers: false,
        allWorkspaces: false,
        parent: '',
      });

      setData(instances);
    } catch (error) {
      console.error('Failed to load instances:', error);
    } finally {
      setLoadingMessage('');
    }
  }, [getInstancesList]);
  const setSuspension = useCallback(
    (appInstance: AppInstance, opEnum: InstancePauseResumeOpEnum) => async () => {
      const { id } = appInstance;
      const op = InstancePauseResumeMap.get(opEnum);
      if (!op) return;
      const { upperDescription } = op;
      setLoadingMessage(`${upperDescription} app instance with id ${id}`);
      try {
        await setInstanceSuspension(appInstance, op);
        await loadData();
      } finally {
        setLoadingMessage('');
      }
    },
    [loadData, setInstanceSuspension]
  );
  const setSuspensionAll = useCallback(
    async (opEnum: InstancePauseResumeOpEnum) => {
      const op = InstancePauseResumeMap.get(opEnum);
      if (!op) return;
      const { upperDescription } = op;
      setLoadingMessage(`${upperDescription} all app instances`);
      try {
        await setInstancesSuspension(data, op);
        await loadData();
      } finally {
        setLoadingMessage('');
      }
    },
    [loadData, setInstancesSuspension, data]
  );
  useEffect(() => {
    loadData();
  }, [loadData]);
  const emptyMessageProps: IEmptyMessageProps | undefined =
    !data || !data.length ? { message: `You don't have any instances` } : undefined;
  const contextMenuItems: IContextualMenuItem[] = [
    {
      key: 'resume',
      text: 'Resume all instances',
      onClick: () => {
        setSuspensionAll(InstancePauseResumeOpEnum.Resume);
      },
    },
    {
      key: 'pause',
      text: 'Pause all instances',
      onClick: () => {
        setSuspensionAll(InstancePauseResumeOpEnum.Pause);
      },
    },
  ];
  return (
    <WidgetPanel
      title={`${title} (${data.length})`}
      linkText="View all"
      linkHref={RoutePaths.INSTANCES}
      loadingMessage={loadingMessage}
      emptyMessageProps={emptyMessageProps}
      contextMenuItems={contextMenuItems}
    >
      <BasicList
        data={data.slice(0, 5)}
        itemRenderer={(d: AppInstance, _i, last) => {
          const instance = {
            ...d,
            appDetails: d.appDetails || { title: 'Unknown App' },
          } as AppInstance;

          const item = getAppInstanceItem(instance, theme);
          const appDetailsHref = instance.appDetails.id ? `/apps/${instance.appDetails.id}` : undefined;
          return (
            <WidgetItem<AppInstanceItem>
              idField="id"
              titleField="title"
              imagePathField="iconLocation"
              statusField="status"
              statusColorField="statusColor"
              imageHref={appDetailsHref}
              titleHref={appDetailsHref}
              last={last}
              data={item}
              actions={
                <div style={{ display: 'flex', gap: 8 }}>
                  <Button
                    styles={buttonStylesPrimary}
                    text="Visit"
                    data-test={getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.visit)}
                    iconName={IconName.ArrowUpRight}
                    href={instance.location}
                    disabled={!instance.location || item.status !== 'Running'}
                  />
                  {item.status !== 'Paused' ? (
                    <Button
                      styles={[buttonStylesSecondary, buttonStylesWidthXSmall]}
                      iconName={IconName.Pause}
                      text="Pause"
                      data-test={getInstanceButtonTestId(instance, instanceButtonTestIdSuffixes.pause)}
                      onClick={setSuspension(instance, InstancePauseResumeOpEnum.Pause)}
                    />
                  ) : (
                    <Button
                      styles={[buttonStylesPrimary, buttonStylesWidthXSmall]}
                      iconName={IconName.MSNVideos}
                      text="Resume"
                      data-test={getInstanceButtonTestId(d, instanceButtonTestIdSuffixes.resume)}
                      onClick={setSuspension(instance, InstancePauseResumeOpEnum.Resume)}
                    />
                  )}
                </div>
              }
            />
          );
        }}
      />
    </WidgetPanel>
  );
};
