// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/notebook/v1/base_types.proto (package ai.h2o.notebook.v1, syntax proto3)
/* eslint-disable */

/**
 * Policy for downloading an image in K8s.
 *
 * @generated from enum ai.h2o.notebook.v1.ImagePullPolicy
 */
export enum ImagePullPolicy {
/**
 * Unspecified image pull policy.
 *
 * @generated from enum value: IMAGE_PULL_POLICY_UNSPECIFIED = 0;
 */
UNSPECIFIED = "IMAGE_PULL_POLICY_UNSPECIFIED",

/**
 * Every time the container is launched, the container image registry is queried
 * to resolve the name to an image digest.
 *
 * @generated from enum value: IMAGE_PULL_POLICY_ALWAYS = 1;
 */
ALWAYS = "IMAGE_PULL_POLICY_ALWAYS",

/**
 * Do not try to fetch the image. If the image is already present locally,
 * the container will start. If the image is not present, container startup will fail.
 *
 * @generated from enum value: IMAGE_PULL_POLICY_NEVER = 2;
 */
NEVER = "IMAGE_PULL_POLICY_NEVER",

/**
 * The image is pulled only if it is not already present locally.
 *
 * @generated from enum value: IMAGE_PULL_POLICY_IF_NOT_PRESENT = 3;
 */
IF_NOT_PRESENT = "IMAGE_PULL_POLICY_IF_NOT_PRESENT",
}
