import { ArtifactComposition } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/artifact_composition_pb';
import { ListArtifactCompositionsRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/composition_service_pb';
import { DeploymentComposition } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_composition_pb';
import {
  Deployment,
  SingleDeployment,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_pb';
import { CreateDeploymentRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_service_pb';
import { KubernetesConfigurationShortcut } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/kubernetes_configuration_pb';
import {
  KubernetesResourceRequirement,
  KubernetesResourceSpec,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/kubernetes_resource_spec_pb';
import { Monitor } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/monitor_pb';
import {
  AuthenticationPassphrase,
  PassphraseHashType,
  Security,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/security_pb';
import { CompositionService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/composition_service_connect';
import { DeploymentService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_service_connect';
import { Artifact } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/artifact_pb';
import { ListEntityArtifactsRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/artifact_service_pb';
import { DeploymentEnvironment } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/deployment_environment_pb';
import { ListDeploymentEnvironmentsRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/deployment_environment_service_pb';
import { Operator } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/query_pb';
import { RegisteredModel } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_pb';
import { ListRegisteredModelsRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_service_pb';
import { ListModelVersionsForModelRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_version_service_pb';
import { ArtifactService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/artifact_service_connect';
import { DeploymentEnvironmentService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/deployment_environment_service_connect';
import { RegisteredModelService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_service_connect';
import { RegisteredModelVersionService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_version_service_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { IStyle, MessageBarType, Toggle } from '@fluentui/react';
import {
  Button,
  Dropdown,
  IH2OTheme,
  Search,
  TextField,
  WidgetItem,
  buttonStylesPrimary,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Header/Header';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
// TODO: Move to global context.
import { WidgetIconButton } from '../Orchestrator/Runnables';
import { ENDPOINTS, ROUTES } from './constants';
import { DeploymentOptions, RegisteredModelItem } from './Models';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

export type DropdownOption = { key: string | number; text: string };

interface IDeploymentDetailStyles {
  form: IStyle;
  placeholderContainer: IStyle;
  placeholderContent: IStyle;
}

const deploymentDetailStyles = (theme: IH2OTheme): Partial<IDeploymentDetailStyles> => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 20,
    margin: '0px 40px',
  },
  placeholderContainer: {
    margin: '16px 0',
    height: 100, // Widget height.
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    outlineWidth: 1,
    outlineStyle: 'dashed',
    outlineColor: theme.semanticColors?.inputBorder,
  },
  placeholderContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 452, // Callout width.
  },
});

const dropdownOptions: DropdownOption[] = [
  { key: 'single_model', text: 'Single model' },
  { key: 'ab_test', text: 'A/B Test' },
  { key: 'champion_challenger', text: 'Champion/Challenger' },
];

const DeploymentDetail = () => {
  const theme = useTheme(),
    classNames = useClassNames<IDeploymentDetailStyles, ClassNamesFromIStyles<IDeploymentDetailStyles>>(
      'deploymentDetailStyles',
      deploymentDetailStyles(theme)
    ),
    history = useHistory(),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID } = useProjects(),
    calloutRef = React.useRef<HTMLDivElement | null>(null),
    [artifactTypeOptions, setArtifactTypeOptions] = React.useState<DropdownOption[]>([]),
    [artifactRuntimeOptions, setArtifactRuntimeOptions] = React.useState<DropdownOption[]>([]),
    [selectedArtifactTypeKey, setSelectedArtifactTypeKey] = React.useState<string>(''),
    [selectedRuntimeKey, setSelectedRuntimeKey] = React.useState<string>(''),
    [isMonitoringEnabled, setIsMonitoringEnabled] = React.useState<boolean>(false),
    [deploymentOptions, setDeploymentOptions] = React.useState<DeploymentOptions | undefined>(undefined),
    [deploymentEnvironments, setDeploymentEnvironments] = React.useState<DeploymentEnvironment[]>(),
    [deploymentName, setDeploymentName] = React.useState<string>(''),
    [deploymentDescription, setDeploymentDescription] = React.useState<string>(''),
    // TODO: Support loading more models.
    [, setIsLoadingMoreModels] = React.useState(false),
    [, setModelNextPageToken] = React.useState<string>(),
    [isLoadingModelSearch, setIsLoadingModelSearch] = React.useState(false),
    [registeredModelItems, setRegisteredModelItems] = React.useState<RegisteredModelItem[]>(),
    [selectedModels, setSelectedModels] = React.useState<RegisteredModel[]>([]),
    // TODO: Handle loading state.
    [, setLoading] = React.useState(true),
    loadStateRef = React.useRef({
      fetchRegisteredModels: false,
      createSingleDeployment: false,
      getRegisteredModelVersion: false,
      fetchDeploymentEnvironments: false,
      fetchArtifactCompositions: false,
      fetchEntityArtifacts: false,
    }),
    [artifactCompositions, setArtifactCompositions] = React.useState<ArtifactComposition[]>(),
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    artifactClient = createPromiseClient(ArtifactService, storageTransport),
    deploymentTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.deployment}/`,
    }),
    deploymentClient = createPromiseClient(DeploymentService, deploymentTransport),
    artifactCompositionClient = createPromiseClient(CompositionService, deploymentTransport),
    deploymentEnvironmentClient = createPromiseClient(DeploymentEnvironmentService, storageTransport),
    registeredModelVersionClient = createPromiseClient(RegisteredModelVersionService, storageTransport),
    registeredModelClient = createPromiseClient(RegisteredModelService, storageTransport),
    evaluateLoading = () => {
      if (
        !loadStateRef.current.fetchRegisteredModels &&
        !loadStateRef.current.createSingleDeployment &&
        !loadStateRef.current.getRegisteredModelVersion &&
        !loadStateRef.current.fetchDeploymentEnvironments &&
        !loadStateRef.current.fetchArtifactCompositions &&
        !loadStateRef.current.fetchEntityArtifacts
      ) {
        setLoading(false);
      }
    },
    fetchRegisteredModels = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        if (!ACTIVE_PROJECT_ID) return;
        loadStateRef.current.fetchRegisteredModels = true;
        if (pageToken) setIsLoadingMoreModels(true);
        else if (filter || filter === `""`) setIsLoadingModelSearch(true);
        else setLoading(true);
        try {
          const listRegisteredModelsBody = new ListRegisteredModelsRequest(
            filter
              ? {
                  projectId: ACTIVE_PROJECT_ID,
                  paging: {
                    pageSize: 20,
                    pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
                  },
                  filter: {
                    query: {
                      clause: [
                        {
                          propertyConstraint: [
                            {
                              property: {
                                propertyType: {
                                  // TODO: Make filter case insensitive.
                                  value: 'name',
                                  case: 'field',
                                },
                              },
                              operator: Operator.CONTAINS,
                              value: {
                                value: {
                                  case: 'stringValue',
                                  value: filter,
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                }
              : {
                  projectId: ACTIVE_PROJECT_ID,
                  paging: {
                    pageSize: 20,
                    pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
                  },
                }
          );
          const response = await registeredModelClient.listRegisteredModels(listRegisteredModelsBody);
          const registeredModelItems: RegisteredModel[] | undefined = response?.registeredModels;
          if (response && !registeredModelItems) console.error('No registered models found in the response.');
          setModelNextPageToken(
            response?.paging?.nextPageToken ? new TextDecoder().decode(response.paging.nextPageToken) : undefined
          );
          const newItems: RegisteredModelItem[] | undefined = registeredModelItems?.map(
            (item) =>
              ({
                ...item,
                createdTimeLocal:
                  item.createdTime?.seconds !== undefined
                    ? new Date(Number(item.createdTime.seconds) * 1000).toLocaleString()
                    : '',
                // TODO: Display user name instead of the id.
                createdByName: item.createdBy,
              } as RegisteredModelItem)
          );
          setRegisteredModelItems((items) => (pageToken ? [...(items || []), ...(newItems || [])] : newItems));
        } catch (err) {
          const message = `Failed to fetch registered models: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setRegisteredModelItems(undefined);
        }
        loadStateRef.current.fetchRegisteredModels = false;
        evaluateLoading();
        setIsLoadingMoreModels(false);

        setIsLoadingModelSearch(false);
      },
      [addToast, ACTIVE_PROJECT_ID]
    ),
    getModelVersionsByModel = React.useCallback(
      async (modelId: string) => {
        loadStateRef.current.getRegisteredModelVersion = true;
        setLoading(true);
        try {
          const getModelVersionsByModelRequest = new ListModelVersionsForModelRequest({
            registeredModelId: modelId,
          });
          const response = await registeredModelVersionClient.listModelVersionsForModel(getModelVersionsByModelRequest);
          const modelVersions = response?.modelVersions;
          if (modelVersions) {
            return modelVersions;
          } else {
            console.error('No model versions found in the response.');
            return undefined;
          }
        } catch (err) {
          const message = `Failed to fetch model versions: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.getRegisteredModelVersion = false;
          evaluateLoading();
        }
      },
      [addToast]
    ),
    getDeploymentEnvironments = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID) return;
      loadStateRef.current.fetchDeploymentEnvironments = true;
      setLoading(true);
      try {
        const listDeploymentEnvironmentsRequest = new ListDeploymentEnvironmentsRequest({
          projectId: ACTIVE_PROJECT_ID,
        });
        const response = await deploymentEnvironmentClient.listDeploymentEnvironments(
          listDeploymentEnvironmentsRequest
        );
        const deploymentEnvironments: DeploymentEnvironment[] | undefined = response?.deploymentEnvironment;
        setDeploymentEnvironments(deploymentEnvironments);
        if (response && !deploymentEnvironments) console.error('No deployment environments found in the response.');
      } catch (err) {
        const message = `Failed to fetch deployment environments: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setDeploymentEnvironments(undefined);
      } finally {
        loadStateRef.current.fetchDeploymentEnvironments = false;
        evaluateLoading();
      }
    }, [ACTIVE_PROJECT_ID, addToast]),
    getEntityArtifacts = React.useCallback(
      async (entityId: string) => {
        loadStateRef.current.fetchEntityArtifacts = true;
        setLoading(true);
        try {
          const listEntityArtifactsRequest = new ListEntityArtifactsRequest({
            // Experiment id.
            entityId,
          });
          const response = await artifactClient.listEntityArtifacts(listEntityArtifactsRequest);
          const artifacts: Artifact[] | undefined = response?.artifact;
          if (response && !artifacts) console.error('No artifacts found in the response.');
          return artifacts;
        } catch (err) {
          const message = `Failed to fetch artifacts: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.fetchEntityArtifacts = false;
          evaluateLoading();
        }
      },
      [addToast]
    ),
    getArtifactCompositions = React.useCallback(async () => {
      loadStateRef.current.fetchArtifactCompositions = true;
      setLoading(true);
      try {
        const listArtifactCompositionsRequest = new ListArtifactCompositionsRequest({});
        const response = await artifactCompositionClient.listArtifactCompositions(listArtifactCompositionsRequest);
        const artifactCompositions: ArtifactComposition[] | undefined = response?.artifactComposition;
        setArtifactCompositions(artifactCompositions);
        if (response && !artifactCompositions) console.error('No artifact compositions found in the response.');
      } catch (err) {
        const message = `Failed to fetch artifact compositions: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setArtifactCompositions(undefined);
      } finally {
        loadStateRef.current.fetchArtifactCompositions = false;
        evaluateLoading();
      }
    }, [addToast]),
    createDeployment = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID || !deploymentOptions) return;
      loadStateRef.current.createSingleDeployment = true;
      setLoading(true);
      // TODO: Rename options to deploymentOptions.
      const options = deploymentOptions;
      try {
        const deployCreateDeploymentRequest = new CreateDeploymentRequest({
          deployment: new Deployment({
            projectId: ACTIVE_PROJECT_ID,
            deploymentEnvironmentId: options.deploymentEnvironmentId,
            deployment: {
              case: 'singleDeployment',
              value: new SingleDeployment({
                deploymentComposition: new DeploymentComposition({
                  experimentId: options.experimentId,
                  deployableArtifactTypeName: options.deployableArtifactTypeName,
                  artifactProcessorName: options.artifactProcessorName,
                  runtimeName: options.runtimeName,
                }),
                environmentSpec: {
                  case: 'kubernetesResourceSpec',
                  value: new KubernetesResourceSpec({
                    replicas: options.kubernetesOptions.replicas,
                    kubernetesResourceRequirement: new KubernetesResourceRequirement({
                      requests: options.requests,
                      limits: options.limits,
                    }),
                  }),
                },
                environmentShortcut: {
                  case: 'kubernetesConfigurationShortcut',
                  value: new KubernetesConfigurationShortcut({
                    kubernetesAffinityShortcutName: options.advanced.affinity,
                    kubernetesTolerationShortcutName: options.advanced.toleration,
                  }),
                },
              }),
            },
            security: options.security,
            displayName: options.displayName,
            description: options.description,
            monitor: new Monitor({
              enable: options.monitoringEnabled,
              storeScoringTransactionEnable: options.driftMonitoringEnabled,
            }),
          }),
        });

        const response = await deploymentClient.createDeployment(deployCreateDeploymentRequest);

        if (response && response.deployment) {
          const deploymentId = response.deployment.id;
          const message = `Deployment created successfully. Deployment ID: ${deploymentId}`;
          addToast({
            messageBarType: MessageBarType.success,
            message,
          });
          history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}`);
        } else {
          console.error('Failed to create deployment: No deployment information in response.');
        }
      } catch (err) {
        const message = `Failed to create deployment: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.createSingleDeployment = false;
        evaluateLoading();
      }
    }, [ACTIVE_PROJECT_ID, addToast, deploymentEnvironments, deploymentOptions]),
    onSelectModelUpdateOptions = React.useCallback(async () => {
      if (!selectedModels.length) return;
      // TODO: Support multiple models.
      const modelVersions = await getModelVersionsByModel(selectedModels[0].id),
        // TODO: Change when multiple model versions are supported.
        experimentId = modelVersions?.[0]?.experimentId,
        entityArtifacts = await getEntityArtifacts(experimentId || ''),
        // TODO: Change when multiple artifacts are supported.
        filteredArtifactCompositions = artifactCompositions?.filter(
          (a) => a?.deployableArtifactType?.artifactType === entityArtifacts?.[0]?.type
        );
      if (!experimentId || !filteredArtifactCompositions) {
        const message = 'No experiment found for the model.';
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        return;
      }

      const artifactComposition = filteredArtifactCompositions[0],
        options: DeploymentOptions = {
          displayName: deploymentName,
          description: deploymentDescription,
          experimentId,
          entityArtifacts: entityArtifacts || [],
          artifactCompositions: artifactCompositions || [],
          selectedArtifactComposition: artifactComposition,
          deploymentEnvironmentId: deploymentEnvironments?.[0].id || '244f6087-5cb2-4fe6-94bd-37916474e404', // TODO: Handle with dropdown.
          monitoringEnabled: isMonitoringEnabled,
          // TODO: Add drift monitoring configuration in the UI.
          driftMonitoringEnabled: false,
          artifactProcessorName: artifactComposition.artifactProcessor?.name || '',
          deployableArtifactTypeName: artifactComposition.deployableArtifactType?.name || '',
          runtimeName: artifactComposition.runtime?.name || '',
          // TODO: Add advanced options into the UI.
          advanced: {
            toleration: '',
            affinity: '',
          },
          requests: {},
          limits: {},
          kubernetesOptions: {
            replicas: 1,
          },
          security: new Security({
            authentication: {
              case: 'passphrase',
              value: new AuthenticationPassphrase({
                // TODO: Check correct use of security option.
                passphraseHashType: PassphraseHashType.PLAINTEXT,
                hash: 'plain_text',
              }),
            },
          }),
        };

      // Updates dropdowns on model select.
      setSelectedArtifactTypeKey(artifactComposition.deployableArtifactType?.name || '');
      setSelectedRuntimeKey(artifactComposition.runtime?.name || '');

      setDeploymentOptions(options);
    }, [
      addToast,
      artifactCompositions,
      deploymentEnvironments,
      getEntityArtifacts,
      getModelVersionsByModel,
      isMonitoringEnabled,
      selectedArtifactTypeKey,
      selectedModels,
      selectedRuntimeKey,
      deploymentName,
      deploymentDescription,
    ]);

  React.useEffect(() => {
    const artifactTypeOptions: DropdownOption[] = (artifactCompositions || []).map((composition) => ({
      key: composition.deployableArtifactType?.name || '',
      text: composition.deployableArtifactType?.displayName || '',
    }));

    const artifactRuntimeOptions: DropdownOption[] = (artifactCompositions || []).map((composition) => ({
      key: composition.runtime?.name || '',
      text: composition.runtime?.displayName || '',
    }));
    setArtifactTypeOptions(artifactTypeOptions);
    setArtifactRuntimeOptions(artifactRuntimeOptions);
  }, [artifactCompositions]);

  React.useEffect(() => {
    // TODO: Update directly from model selection callout.
    onSelectModelUpdateOptions();
  }, [selectedModels]);

  React.useEffect(() => void getDeploymentEnvironments(), [getDeploymentEnvironments]);

  React.useEffect(() => void getArtifactCompositions(), [getArtifactCompositions]);

  React.useEffect(() => void fetchRegisteredModels(), [fetchRegisteredModels]);

  return (
    <PageWrapper>
      <Header
        // TODO: Or "Model detail" if editing existing model.
        customPageTitle="Create new deployment"
      />
      <div className={classNames.form}>
        <h3>Create a new deployment</h3>
        <TextField
          label="Deployment name"
          required
          onChange={(_ev, value) => {
            setDeploymentName(value || '');
          }}
          value={deploymentName}
        />
        <TextField
          label="Deployment description"
          onChange={(_ev, value) => {
            setDeploymentDescription(value || '');
          }}
          value={deploymentDescription}
        />
        <Dropdown
          label="Deployment type"
          selectedKey="single_model"
          // TODO: Support other deployment types.
          // onChange={onDropdownChange}
          options={dropdownOptions}
          disabled={true}
        />
        {selectedModels.length ? (
          <WidgetItem
            styles={{
              root: {
                display: 'flex',
                alignItems: 'center',
                borderRadius: 8,
                margin: '16px 0',
                height: 100,
                outlineWidth: 1,
                outlineStyle: 'solid',
                outlineColor: theme.semanticColors?.inputBorder,
                // TODO: Display error styles when model does not exist.
                // outline: activeNodeSelectedItem ? undefined : '2px dashed red',
              },
              // body: {
              //   // color: activeNodeSelectedItem ? undefined : 'red',
              // },
            }}
            actions={
              <div>
                {/* TODO: Support multi model selection. */}
                {selectedModels[0] && (
                  <WidgetIconButton
                    iconName="OpenInNewTab"
                    onClick={() => {
                      window.open(
                        `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.MODELS}/${selectedModels[0].id}`,
                        '_blank'
                      );
                    }}
                    title="Open in new tab"
                  />
                )}
                {
                  <WidgetIconButton
                    iconName="Delete"
                    onClick={() => {
                      setSelectedModels([]);
                      setSelectedArtifactTypeKey('');
                      setSelectedRuntimeKey('');
                    }}
                    iconColor="white"
                    title="Delete"
                    backgroundColor="red"
                    hasBorder={false}
                  />
                }
              </div>
            }
            data={selectedModels[0] || { title: `Selected item does not exist.`, description: '', iconName: '' }}
            idField={'id'}
            titleField={'displayName'}
          />
        ) : (
          <div
            className={classNames.placeholderContainer}
            style={
              {
                // TODO: Add validation support.
                // color: showValidation ? 'red' : undefined,
                // outline: showValidation ? '2px dashed red' : undefined,
              }
            }
          >
            <div className={classNames.placeholderContent}>
              <p>No model is selected.</p>
              <Search
                emptyMessage="No results found"
                hasSearchResult
                onClickItem={(item) => {
                  setSelectedModels([item as RegisteredModel]);
                  // HACK: Dismiss callout.
                  calloutRef.current?.parentElement?.click();
                }}
                onRenderSearchResultItemActions={() => <></>}
                onSearchTextChange={(text) => void fetchRegisteredModels(undefined, text)}
                placeholder={`Search by model name`}
                searchResultItems={registeredModelItems}
                minCalloutWidth={452}
                calloutProps={{ dismissOnTargetClick: true, ref: calloutRef }}
                searchResultItemFields={{
                  titleField: 'displayName',
                  descriptionField: 'description',
                  iconNameField: 'iconName',
                }}
                styles={{
                  root: {
                    width: '100%',
                  },
                }}
                // TODO: Add loader.
                loadingMessage={isLoadingModelSearch ? 'Loading models...' : ''}
              />
            </div>
          </div>
        )}
        <h4>Deployment parameters</h4>
        <Dropdown
          label="Artifact type"
          required
          options={artifactTypeOptions}
          selectedKey={selectedArtifactTypeKey}
          onChange={(_ev, option) => {
            setSelectedArtifactTypeKey(option?.key as string);
          }}
          disabled={!artifactTypeOptions.length || !selectedModels.length}
        />
        <Dropdown
          label="Runtime"
          required
          options={artifactRuntimeOptions}
          selectedKey={selectedRuntimeKey}
          onChange={(_ev, option) => {
            setSelectedRuntimeKey(option?.key as string);
          }}
          disabled={!artifactRuntimeOptions.length || !selectedModels.length}
        />
        <Toggle
          label="Enable monitoring"
          onText="Yes"
          offText="No"
          checked={isMonitoringEnabled}
          onChange={(_ev, checked) => {
            setIsMonitoringEnabled(checked || false);
          }}
        />
        <div style={{ height: 25 }}></div>
        <Button
          // TODO: Add onCreateDeploymentClick function with form validation.
          onClick={createDeployment}
          // TODO: Handle disabled based on form validation.
          disabled={!selectedModels.length}
          styles={buttonStylesPrimary}
        >
          Create deployment
        </Button>
      </div>
    </PageWrapper>
  );
};

export default DeploymentDetail;
