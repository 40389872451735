export const isMajorUpgrade = (oldVersion = '', newVersion = ''): boolean => {
  if (!oldVersion || !newVersion) return false;

  const parseVersion = (version: string): number[] | null => {
    // Remove any pre-release identifier or build metadata (like "-cg" or "-alpha")
    const versionWithoutSuffix = version.split('-')[0];

    const parts = versionWithoutSuffix.split('.').map(Number);
    return parts.some(isNaN) ? null : parts; // Return null if any part is not a number
  };

  // Parse both versions
  const oldParts = parseVersion(oldVersion);
  const newParts = parseVersion(newVersion);

  // If parsing failed, return false to show warning by default
  if (!oldParts || !newParts) {
    return false;
  }

  // Check if the major version is the same
  if (oldParts[0] !== newParts[0]) {
    return true; // Major version change is not a minor bump
  }

  // If major versions are the same, check the minor version
  if (oldParts[1] !== newParts[1]) {
    return true; // Minor version change (non-minor bump)
  }

  return false; // No change or patch only (minor bump)
};
