// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/h2o_engine_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { H2OEngine } from "./h2o_engine_pb";
import type { HttpBody } from "../../../../google/api/httpbody_pb";
import type { BigIntString } from "../../../../runtime";
import { RPC } from "../../../../runtime";

/**
 * Request message for creating a new H2OEngine.
 *
 * @generated from message ai.h2o.engine.v1.CreateH2OEngineRequest
 */
export type CreateH2OEngineRequest = {
/**
 * workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/h2oEngines/CREATE
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * The H2OEngine resource to create.
 * NON_EMPTY_DEFAULT fields that are not set in this request will be set to a default value.
 * Default value is determined by the workspace in which the engine is created. Each workspace has a defined
 * constraint set, which contains the default value for each field.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngine h2o_engine = 2;
 */
h2oEngine: H2OEngine;
/**
 * Specify the H2OEngine ID, which will become a part of the H2OEngine
 * resource name.
 *
 * It must:
 * - contain 1-63 characters
 * - contain only lowercase alphanumeric characters or hyphen ('-')
 * - start with an alphabetic character
 * - end with an alphanumeric character
 *
 * This matches a regular expression: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
 *
 * @generated from field: string h2o_engine_id = 3;
 */
h2oEngineId: string;
/**
 * When set, request is only validated but no changes are made.
 *
 * @generated from field: bool validate_only = 10;
 */
validateOnly?: boolean;
}
;
/**
 * Response message for H2OEngineService.CreateH2OEngine.
 *
 * @generated from message ai.h2o.engine.v1.CreateH2OEngineResponse
 */
export type CreateH2OEngineResponse = {
/**
 * H2OEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngine h2o_engine = 1;
 */
h2oEngine?: H2OEngine;
}
;
/**
 * Request message for getting a single H2OEngine.
 *
 * @generated from message ai.h2o.engine.v1.GetH2OEngineRequest
 */
export type GetH2OEngineRequest = {
/**
 * H2OEngine resource name.
 * Format: workspaces/{workspace}/h2oEngines/{h2o_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/h2oEngines/GET
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for H2OEngineService.GetH2OEngine.
 *
 * @generated from message ai.h2o.engine.v1.GetH2OEngineResponse
 */
export type GetH2OEngineResponse = {
/**
 * H2OEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngine h2o_engine = 1;
 */
h2oEngine?: H2OEngine;
}
;
/**
 * Request message for listing H2OEngines in a workspace.
 *
 * @generated from message ai.h2o.engine.v1.ListH2OEnginesRequest
 */
export type ListH2OEnginesRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/h2oEngines/LIST
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of H2OEngines to return in a response.
 * If unspecified (or set to 0), at most 50 H2OEngines will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListH2OEnginesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
/**
 * Used to specify the sorting order.
 *
 * When unset, H2OEngines are ordered by their time of creation in
 * descending order. This is equivalent to "create_time desc".
 *
 * When specified, the value must be a comma separated list of supported
 * fields. The supported fields are:
 *  - name
 *  - version
 *  - cpu
 *  - gpu
 *  - memoryBytes
 *  - creator
 *  - createTime
 *  - updateTime
 *  - deleteTime
 *  - displayName
 *  - maxIdleDuration
 *  - maxRunningDuration
 *  - uid
 *  - nodeCount
 *
 * The default sorting order is ascending. For example: "name" and "name asc"
 * are equivalent values.
 *
 * To specify descending order for a field, append a " desc" suffix. For
 * example: "name desc".
 *
 * Redundant space characters are insignificant. For example these values are
 * all equal:
 * - "  name, cpu     desc"
 * - "name, cpu desc"
 * - "name   , cpu desc   "
 *
 * Undefined (empty) time is interpreted as a zero time (0s since epoch, i.e.
 * 1970-01-01T00:00:00Z).
 *
 * Undefined (empty) duration is interpreted as a zero duration (0 seconds).
 *
 * @generated from field: string order_by = 4;
 */
orderBy?: string;
/**
 * Used to filter H2OEngines. When unset, no filtering is applied.
 *
 * Filtering implements specification https://google.aip.dev/160.
 *
 * Supported filter fields:
 * - name
 * - h2o_engine_id
 * - version (supporting version semantic comparison and version aliases)
 * - state
 * - cpu
 * - gpu
 * - memory_bytes
 * - creator
 * - creator_display_name
 * - create_time
 * - update_time
 * - delete_time
 * - reconciling
 * - uid
 * - display_name
 * - max_idle_duration
 * - max_running_duration
 * - current_idle_duration
 * - current_running_duration
 * - node_count
 *
 * Examples:
 * - `cpu > 5`
 * - `gpu < 3`
 * - `cpu >= 5 AND gpu <= 3`
 * - `cpu != 5 OR gpu == 3`
 * - `NOT (cpu > 5 OR gpu < 3)`
 * - `-(cpu > 5 OR gpu < 3)`
 * - `NOT (cpu > 5 OR (gpu < 3))`
 * - `(cpu > 5 OR gpu < 3) AND memory_bytes != 1000`
 * - `(cpu > 5 OR gpu < 3) AND (NOT ((((memory_bytes = 1000)))))`
 * - `state = STATE_RUNNING AND create_time > "2012-04-21T11:30:00-04:00"`
 * - `max_running_duration > "1s" AND max_running_duration != "5s"`
 * - `version < "latest" AND version >= "3.38.0.1"`
 * - `reconciling = true OR reconciling = false` (supporting bool literals)
 * - `h2o_engine_id = "*e*" OR display_name = "*e*"` (supporting prefix/suffix wildcard `*` for string fields equality comparison)
 *
 * @generated from field: string filter = 5;
 */
filter?: string;
}
;
/**
 * Response message for H2OEngineService.ListH2OEngines.
 *
 * @generated from message ai.h2o.engine.v1.ListH2OEnginesResponse
 */
export type ListH2OEnginesResponse = {
/**
 * Collection of H2OEngines.
 *
 * @generated from field: repeated ai.h2o.engine.v1.H2OEngine h2o_engines = 1;
 */
h2oEngines?: H2OEngine[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
/**
 * Total number of H2OEngines matched by the List request.
 *
 * @generated from field: int32 total_size = 3;
 */
totalSize?: number;
}
;
/**
 * Request message for terminating a running H2OEngine.
 *
 * @generated from message ai.h2o.engine.v1.TerminateH2OEngineRequest
 */
export type TerminateH2OEngineRequest = {
/**
 * H2OEngine resource name.
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/h2oEngines/TERMINATE
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response for Terminate method.
 *
 * @generated from message ai.h2o.engine.v1.TerminateH2OEngineResponse
 */
export type TerminateH2OEngineResponse = {
/**
 * H2OEngine resource.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngine h2o_engine = 1;
 */
h2oEngine?: H2OEngine;
}
;
/**
 * Request message for deleting an existing H2OEngine.
 *
 * @generated from message ai.h2o.engine.v1.DeleteH2OEngineRequest
 */
export type DeleteH2OEngineRequest = {
/**
 * H2OEngine resource name.
 * Format: workspaces/{workspace}/h2oEngines/{h2o_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/h2oEngines/DELETE
 *
 * @generated from field: string name = 1;
 */
name: string;
/**
 * When set and the H2OEngine is not found, the request will succeed
 * but no changes are made.
 *
 * @generated from field: bool allow_missing = 2;
 */
allowMissing?: boolean;
/**
 * When set, request is validated but no changes are made.
 *
 * @generated from field: bool validate_only = 10;
 */
validateOnly?: boolean;
}
;
/**
 * Response message for H2OEngineService.DeleteH2OEngine.
 *
 * @generated from message ai.h2o.engine.v1.DeleteH2OEngineResponse
 */
export type DeleteH2OEngineResponse = {
/**
 * H2OEngine resource being deleted. May be empty in case delete was instant.
 *
 * @generated from field: optional ai.h2o.engine.v1.H2OEngine h2o_engine = 1;
 */
h2oEngine?: H2OEngine;
}
;
/**
 * Request message for downloading H2OEngines logs.
 *
 * @generated from message ai.h2o.engine.v1.H2OEngineServiceDownloadLogsRequest
 */
export type H2OEngineServiceDownloadLogsRequest = {
/**
 * H2OEngine resource name.
 * Format: workspaces/{workspace}/h2oEngines/{h2o_engine}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/h2oEngines/DOWNLOAD_LOGS
 *
 * @generated from field: string h2o_engine = 1;
 */
h2oEngine: string;
}
;
/**
 * Response message for downloaded logs.
 *
 * @generated from message ai.h2o.engine.v1.H2OEngineServiceDownloadLogsResponse
 */
export type H2OEngineServiceDownloadLogsResponse = {
/**
 * Represents text logs file as an arbitrary HTTP body.
 *
 * @generated from field: google.api.HttpBody logs = 1;
 */
logs?: HttpBody;
}
;
/**
 * Request message for getting recommendation for H2OEngine sizing based on a raw dataset.
 *
 * @generated from message ai.h2o.engine.v1.CalculateH2OEngineSizeRawDatasetRequest
 */
export type CalculateH2OEngineSizeRawDatasetRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * @generated from field: string workspace = 1;
 */
workspace: string;
/**
 * Estimated size of the dataset in Bytes.
 *
 * @generated from field: int64 dataset_size_bytes = 2;
 */
datasetSizeBytes: BigIntString;
}
;
/**
 * Request message for getting recommendation for H2OEngine sizing based on a compressed dataset.
 *
 * @generated from message ai.h2o.engine.v1.CalculateH2OEngineSizeCompressedDatasetRequest
 */
export type CalculateH2OEngineSizeCompressedDatasetRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * @generated from field: string workspace = 1;
 */
workspace: string;
/**
 * Estimated number of rows in the dataset.
 *
 * @generated from field: int64 rows_count = 2;
 */
rowsCount: BigIntString;
/**
 * Estimated number of columns in the dataset.
 *
 * @generated from field: int64 columns_count = 3;
 */
columnsCount: BigIntString;
}
;
/**
 * Message representing recommended H2OEngine size within a given workspace constraints.
 *
 * @generated from message ai.h2o.engine.v1.H2OEngineSize
 */
export type H2OEngineSize = {
/**
 * The number of nodes recommended for the engine.
 *
 * @generated from field: int64 node_count = 1;
 */
nodeCount?: BigIntString;
/**
 * The amount of memory in bytes per node recommended for the engine.
 *
 * @generated from field: int64 memory_bytes = 2;
 */
memoryBytes?: BigIntString;
}
;
/**
 * Response message for H2OEngine sizing request from a raw dataset size.
 *
 * @generated from message ai.h2o.engine.v1.CalculateH2OEngineSizeRawDatasetResponse
 */
export type CalculateH2OEngineSizeRawDatasetResponse = {
/**
 * Recommended H2OEngine size.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngineSize h2o_engine_size = 1;
 */
h2oEngineSize?: H2OEngineSize;
}
;
/**
 * Response message for H2OEngine sizing request from a compressed dataset dimensions.
 *
 * @generated from message ai.h2o.engine.v1.CalculateH2OEngineSizeCompressedDatasetResponse
 */
export type CalculateH2OEngineSizeCompressedDatasetResponse = {
/**
 * Recommended H2OEngine size.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngineSize h2o_engine_size = 1;
 */
h2oEngineSize?: H2OEngineSize;
}
;
/**
 * Creates and starts a new H2OEngine within a specified workspace.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineService.CreateH2OEngine
 */
export const H2OEngineService_CreateH2OEngine = new RPC<CreateH2OEngineRequest,CreateH2OEngineResponse>("POST", "/v1/{parent=workspaces/*}/h2oEngines", "h2oEngine");
/**
 * Returns a specific H2OEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineService.GetH2OEngine
 */
export const H2OEngineService_GetH2OEngine = new RPC<GetH2OEngineRequest,GetH2OEngineResponse>("GET", "/v1/{name=workspaces/*/h2oEngines/*}");
/**
 * Returns a collection of H2OEngines within a workspace.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineService.ListH2OEngines
 */
export const H2OEngineService_ListH2OEngines = new RPC<ListH2OEnginesRequest,ListH2OEnginesResponse>("GET", "/v1/{parent=workspaces/*}/h2oEngines");
/**
 * Terminate H2OEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineService.TerminateH2OEngine
 */
export const H2OEngineService_TerminateH2OEngine = new RPC<TerminateH2OEngineRequest,TerminateH2OEngineResponse>("POST", "/v1/{name=workspaces/*/h2oEngines/*}:terminate");
/**
 * Deletes a H2OEngine.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineService.DeleteH2OEngine
 */
export const H2OEngineService_DeleteH2OEngine = new RPC<DeleteH2OEngineRequest,DeleteH2OEngineResponse>("DELETE", "/v1/{name=workspaces/*/h2oEngines/*}");
/**
 * Download H2OEngine logs.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineService.DownloadLogs
 */
export const H2OEngineService_DownloadLogs = new RPC<H2OEngineServiceDownloadLogsRequest,H2OEngineServiceDownloadLogsResponse>("POST", "/v1/{h2oEngine=workspaces/*/h2oEngines/*}:downloadLogs");
/**
 * Returns a recommended number of nodes and memory within a given workspace constraints for an H2OEngine based on the raw size of the dataset.
 * (-- api-linter: core::0136::http-uri-suffix=disabled
 *     aip.dev/not-precedent: Not declaring URI suffix for this static service custom method without scope. --)
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineService.CalculateH2OEngineSizeRawDataset
 */
export const H2OEngineService_CalculateH2OEngineSizeRawDataset = new RPC<CalculateH2OEngineSizeRawDatasetRequest,CalculateH2OEngineSizeRawDatasetResponse>("GET", "/v1/{workspace=workspaces/*}:calculateH2OEngineSizeRawDataset");
/**
 * Returns a recommended number of nodes and memory within a given workspace constraints for an H2OEngine based on the dimensions of the compressed dataset.
 * (-- api-linter: core::0136::http-uri-suffix=disabled
 *     aip.dev/not-precedent: Not declaring URI suffix for this static service custom method without scope. --)
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineService.CalculateH2OEngineSizeCompressedDataset
 */
export const H2OEngineService_CalculateH2OEngineSizeCompressedDataset = new RPC<CalculateH2OEngineSizeCompressedDatasetRequest,CalculateH2OEngineSizeCompressedDatasetResponse>("GET", "/v1/{workspace=workspaces/*}:calculateH2OEngineSizeCompressedDataset");
