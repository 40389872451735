// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/securestore/v1/secret_version_service.proto (package ai.h2o.securestore.v1, syntax proto3)
/* eslint-disable */

import type { SecretVersion } from "./secret_version_pb";
import type { BytesString } from "../../../../runtime";
import { RPC } from "../../../../runtime";

/**
 * Request message for `CreateSecretVersion`.
 *
 * @generated from message ai.h2o.securestore.v1.CreateSecretVersionRequest
 */
export type CreateSecretVersionRequest = {
/**
 * Required. The resource name of the Secret to associate with the SecretVersion.
 * Format: `workspaces/*\/secrets/*`
 *
 * Authorization requires the following permission on the specified resource parent:
 * * actions/securestore/versions/CREATE
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Required. The SecretVersion resource to create.
 *
 * @generated from field: ai.h2o.securestore.v1.SecretVersion secret_version = 2;
 */
secretVersion: SecretVersion;
}
;
/**
 * Response message for `CreateSecretVersion`.
 *
 * @generated from message ai.h2o.securestore.v1.CreateSecretVersionResponse
 */
export type CreateSecretVersionResponse = {
/**
 * The created SecretVersion.
 *
 * @generated from field: ai.h2o.securestore.v1.SecretVersion secret_version = 1;
 */
secretVersion?: SecretVersion;
}
;
/**
 * Request message for `GetSecretVersion`.
 *
 * @generated from message ai.h2o.securestore.v1.GetSecretVersionRequest
 */
export type GetSecretVersionRequest = {
/**
 * Required. The resource name of the SecretVersion.
 * Format: `secrets/*\/versions/*`
 *
 * `secrets/*\/versions/latest` is an alias for the most recently created SecretVersion.
 *
 * Authorization requires the following permission on the specified resource name:
 * * `actions/securestore/versions/GET`
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for `GetSecretVersion`.
 *
 * @generated from message ai.h2o.securestore.v1.GetSecretVersionResponse
 */
export type GetSecretVersionResponse = {
/**
 * The requested SecretVersion.
 *
 * @generated from field: ai.h2o.securestore.v1.SecretVersion secret_version = 1;
 */
secretVersion?: SecretVersion;
}
;
/**
 * Request message for `ListSecretVersions`.
 *
 * @generated from message ai.h2o.securestore.v1.ListSecretVersionsRequest
 */
export type ListSecretVersionsRequest = {
/**
 * Required. The resource name of the Secret associated with the SecretVersions to list.
 * Format: `workspaces/*\/secrets/*`
 *
 * Authorization requires the following permission on the specified resource parent:
 * * `actions/securestore/versions/LIST`
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Optional. The maximum number of results to be returned in a single page.
 * If unspecified (or set to 0), the server decides the number of results to return.
 * If the number is greater than 1000, it is capped at 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Optional. Pagination token, returned in `ListSecretVersions.next_page_token`.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for `ListSecretVersions`.
 *
 * @generated from message ai.h2o.securestore.v1.ListSecretVersionsResponse
 */
export type ListSecretVersionsResponse = {
/**
 * Collection of SecretVersions.
 *
 * @generated from field: repeated ai.h2o.securestore.v1.SecretVersion secret_versions = 1;
 */
secretVersions?: SecretVersion[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
/**
 * The total number of SecretsVersions.
 *
 * @generated from field: int32 total_size = 3;
 */
totalSize?: number;
}
;
/**
 * Request message for `RevealSecretVersion`.
 *
 * @generated from message ai.h2o.securestore.v1.RevealSecretVersionValueRequest
 */
export type RevealSecretVersionValueRequest = {
/**
 * Required. The resource name of the Secret to associate with the SecretVersion.
 * Format: `workspaces/*\/secrets/*\/versions/*`
 *
 * `workspaces/*\/secrets/*\/versions/latest` is an alias to the most recently created SecretVersion.
 *
 * Authorization requires the following permission on the specified resource parent:
 * * actions/securestore/versions/REVEALVALUE
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for `RevealSecretVersion`.
 *
 * @generated from message ai.h2o.securestore.v1.RevealSecretVersionValueResponse
 */
export type RevealSecretVersionValueResponse = {
/**
 * The resource name of the SecretVersion.
 * Format: `workspaces/*\/secrets/*\/versions/*`
 *
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * The secret payload of the SecretVersion.
 *
 * @generated from field: bytes value = 2;
 */
value?: BytesString;
}
;
/**
 * Creates a new SecretVersion containing secret data.
 *
 * @generated from rpc ai.h2o.securestore.v1.SecretVersionService.CreateSecretVersion
 */
export const SecretVersionService_CreateSecretVersion = new RPC<CreateSecretVersionRequest,CreateSecretVersionResponse>("POST", "/v1/{parent=workspaces/*/secrets/*}/versions", "secretVersion");
/**
 * Gets metadata for a SecretVersion.
 * "workspaces/*\/secrets/*\/versions/latest" is an alias for the most recently created SecretVersion.
 *
 * @generated from rpc ai.h2o.securestore.v1.SecretVersionService.GetSecretVersion
 */
export const SecretVersionService_GetSecretVersion = new RPC<GetSecretVersionRequest,GetSecretVersionResponse>("GET", "/v1/{name=workspaces/*/secrets/*/versions/*}");
/**
 * Lists SecretVersions. This call does not return secret data.
 *
 * @generated from rpc ai.h2o.securestore.v1.SecretVersionService.ListSecretVersions
 */
export const SecretVersionService_ListSecretVersions = new RPC<ListSecretVersionsRequest,ListSecretVersionsResponse>("GET", "/v1/{parent=workspaces/*/secrets/*}/versions");
/**
 * Reveals the value of a SecretVersion. This call returns the secret data.
 * "workspaces/*\/secrets/*\/versions/latest" is an alias for the most recently created SecretVersion.
 *
 * @generated from rpc ai.h2o.securestore.v1.SecretVersionService.RevealSecretVersionValue
 */
export const SecretVersionService_RevealSecretVersionValue = new RPC<RevealSecretVersionValueRequest,RevealSecretVersionValueResponse>("GET", "/v1/{name=workspaces/*/secrets/*/versions/*}:revealValue");
