// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/orchestrator/v1/preferences_service.proto (package ai.h2o.orchestrator.v1, syntax proto3)
/* eslint-disable */

import type { Preferences } from "./preferences_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for GetPreferences
 *
 * @generated from message ai.h2o.orchestrator.v1.GetPreferencesRequest
 */
export type GetPreferencesRequest = {
/**
 * Required. Preferences resource name.
 * Format: users/{user}/preferences
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Request message for UpdatePreferences
 *
 * @generated from message ai.h2o.orchestrator.v1.UpdatePreferencesRequest
 */
export type UpdatePreferencesRequest = {
/**
 * Required. Preferences to update.
 *
 * @generated from field: ai.h2o.orchestrator.v1.Preferences preferences = 1;
 */
preferences: Preferences;
/**
 * Required. The list of fields to update.
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask: string;
}
;
/**
 * Get a preferences
 *
 * @generated from rpc ai.h2o.orchestrator.v1.PreferencesService.GetPreferences
 */
export const PreferencesService_GetPreferences = new RPC<GetPreferencesRequest,Preferences>("GET", "/v1/{name=users/*/preferences}");
/**
 * Update a preferences
 *
 * @generated from rpc ai.h2o.orchestrator.v1.PreferencesService.UpdatePreferences
 */
export const PreferencesService_UpdatePreferences = new RPC<UpdatePreferencesRequest,Preferences>("PATCH", "/v1/{preferences.name=users/*/preferences}", "preferences");
