import { Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { ListCell, useAppNavigationContext } from '@h2oai/ui-kit';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuthzService } from '../../authz/hooks';
import { FailedToLoadView } from '../../components/FailedToLoadView/FailedToLoadView';
import Header from '../../components/Header/Header';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import WidgetList from '../../components/WidgetList/WidgetList';
import { OwnerRoleKey } from '../Orchestrator/RoleProvider';
import { useUsers } from '../Orchestrator/UsersProvider';
import { useWorkspaces } from '../Orchestrator/WorkspaceProvider';
import { AddEditWorkspacePanel } from './components/AddEditWorkspacePanel/AddEditWorkspacePanel';
import { WorkspaceItemActions, WorkspaceSharedWithCell } from './components/Parts/Parts';
import { workspacesTableColumns } from './constants';
import { UserInWorkspace, WorkspaceWithUsers } from './types';
import { useManageWorkspaces } from './useManageWorkspaces';

export const ManageWorkspaces = () => {
  const { usersNameMap } = useUsers();
  const authzService = useAuthzService();
  const { workspacesWithUsers } = useManageWorkspaces();
  const { fetchWorkspaces, onLoadMore, ACTIVE_WORKSPACE_NAME, loading, isLoadingMore, isLoadingSearch } =
    useWorkspaces();
  const { enableWorkspacesDropdown, disableWorkspacesDropdown } = useAppNavigationContext();
  const [showRightPanel, { setTrue: openPanel, setFalse: closePanel }] = useBoolean(false);
  const [filteredWorkspaces, setFilteredWorkspaces] = useState(workspacesWithUsers);
  const [workspaceForEdit, setWorkspaceForEdit] = useState<WorkspaceWithUsers>();
  const [searchKey, setSearchKey] = useState('');
  const updatedColumns = useMemo(() => {
    return workspacesTableColumns.map((columnItem) => {
      const column = { ...columnItem };

      if (column.key === 'creator') {
        column.onRender = ({ users = [] }: WorkspaceWithUsers) => {
          const workspaceOwners: UserInWorkspace[] = users?.filter((user) => {
            return (
              user.role.includes(OwnerRoleKey.WORKSPACE_OWNER) ||
              user.role.includes(OwnerRoleKey.PERSONAL_WORKSPACE_OWNER)
            );
          });

          return workspaceOwners.length > 1 ? (
            <WorkspaceSharedWithCell
              users={workspaceOwners}
              header={`Owner${workspaceOwners?.length > 1 ? 's' : ''}`}
            />
          ) : (
            <ListCell headerText="Owner" text={workspaceOwners[0] ? workspaceOwners[0].displayName : 'No data'} />
          );
        };
      }
      if (column.key === 'sharedWith') {
        column.onRender = ({ users = [] }: WorkspaceWithUsers) => {
          const headerText = users?.length ? `Shared with (${users?.length})` : 'Shared with';

          return users.length > 1 ? (
            <WorkspaceSharedWithCell users={users} header={headerText} />
          ) : (
            <ListCell headerText="Shared with" text={users[0] ? users[0].displayName : 'No data'} />
          );
        };
      }
      if (column.key === 'actions') {
        column.onRender = (workspace: WorkspaceWithUsers) => (
          <WorkspaceItemActions
            workspace={workspace}
            setWorkspaceForEdit={() => {
              setWorkspaceForEdit(workspace);
            }}
          />
        );
        column.data = {
          listCellProps: {
            emptyMessage: 'No Description',
            styles: {
              root: {
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'end',
                ':has(.h2o-Item-root)': {
                  // TODO: Use theme color.
                  backgroundImage: 'linear-gradient(to right, white , var(--h2o-green100, #D0F5D1))',
                  borderRadius: 2,
                },
              },
            },
          },
        };
      }

      return column;
    });
  }, [workspacesTableColumns, usersNameMap]);

  const onChangeSearchInput = useCallback((value) => setSearchKey(value || ''), [setSearchKey]);
  const onWorkspaceSave = useCallback(() => {
    closePanel();
  }, [authzService]);

  useEffect(() => {
    if (!workspacesWithUsers) return;
    if (!searchKey) {
      setFilteredWorkspaces(workspacesWithUsers);
    } else {
      setFilteredWorkspaces(
        (workspacesWithUsers || [])
          .filter(({ displayName }) => {
            return displayName?.toLowerCase().includes(searchKey);
          })
          .map((item) => ({ ...item, isActive: item.name === ACTIVE_WORKSPACE_NAME }))
      );
    }
  }, [searchKey, workspacesWithUsers]);

  useEffect(() => {
    if (workspaceForEdit) {
      openPanel();
    } else {
      closePanel();
    }
  }, [workspaceForEdit]);

  useEffect(() => {
    if (!showRightPanel) {
      setWorkspaceForEdit(undefined);
    }
  }, [showRightPanel]);

  useEffect(() => {
    enableWorkspacesDropdown();
    return disableWorkspacesDropdown;
  }, []);

  const widgetListProps = {
    columns: updatedColumns,
    items: filteredWorkspaces,
    loading: !!loading,
    delayLoader: false,
    isLoadingMore,
    onLoadMore,
    isLoadingSearch,
    searchProps: {
      placeholder: 'Search workspaces',
      onSearchChange: onChangeSearchInput,
    },
    actionProps: {
      actionIcon: 'Add',
      actionTitle: 'Create workspace',
      onActionClick: openPanel,
    },
    NoItemsContent: NoItemView({
      title: 'Workspaces',
      description: 'There are no workspaces available to select from. Create the first one to begin.',
      actionTitle: 'Create workspace',
      onActionClick: openPanel,
      actionIcon: 'Add',
    }),
    ErrorContent: FailedToLoadView({
      title: 'Failed to load workspaces',
      description: 'Please try again later. If the problem persists, contact our support.',
      actionTitle: 'Retry',
      onActionClick: fetchWorkspaces,
      actionIcon: 'Refresh',
    }),
  };

  return (
    <Stack style={{ maxWidth: 2000, width: '100%', margin: '24px auto 0' }}>
      <Header customPageTitle="Manage Workspaces" />
      <WidgetList {...widgetListProps} />

      <AddEditWorkspacePanel
        isOpen={showRightPanel}
        onDismiss={closePanel}
        onWorkspaceSave={onWorkspaceSave}
        workspaceForEdit={workspaceForEdit}
      />
    </Stack>
  );
};
